// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllNotification(
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/timeline?page=${page}&limit=${limit || ''}` : `${baseURL}/timeline?limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewEmitNotification(
    title: string,
    subtitle: string,
    description: string,
    idExamination: any,
    file: string,
    type: string,
    token: string,
) {
    interface DataInterfaces {
        title: string,
        subtitle: string,
        description: string,
        idExamination?: Array<any>
        file?: string,
    }
    try {
        const allIdsExamination = await (idExamination || [])?.map((v: any) => v.id);
        let data: DataInterfaces = {
            title,
            subtitle,
            description,
        };
        if (allIdsExamination.length) {
            data = {
                ...data,
                idExamination: allIdsExamination,
            };
        }
        if (file) {
            data = {
                ...data,
                file,
            };
        }
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/notifications?type=${type}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditEmitNotification(
    idNotification: number,
    title: string,
    subtitle: string,
    description: string,
    idExamination: any,
    file: string,
    type: string,
    token: string,
) {
    interface DataInterfaces {
        title: string,
        subtitle: string,
        description: string,
        idExamination?: Array<any>
        file?: string,
    }
    try {
        const allIdsExamination = await idExamination.map((v: any) => v.id);
        let data: DataInterfaces = {
            title,
            subtitle,
            description,
        };
        if (allIdsExamination.length) {
            data = {
                ...data,
                idExamination: allIdsExamination,
            };
        }
        if (file) {
            data = {
                ...data,
                file,
            };
        }
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/timeline?idNotification=${idNotification}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneNotification(
    idNotification?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/timeline?idNotification=${idNotification}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    CreateNewEmitNotification,
    GetAllNotification,
    DeleteOneNotification,
    EditEmitNotification,
};
