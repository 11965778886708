// js
import colors from '../../styles/colors';

const initialInputs = {
    numberCard: '',
    nameClient: '',
    dateCard: '',
    codCard: '',
    cpfClient: '',
    brandCard: '',
    maskNumberCard: '',
};

const validationCards = [
    {
        id: 1,
        type: 'visa',
        regex: /^4[0-9]{12}(?:[0-9]{3})/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 2,
        type: 'mastercard',
        regex: /^5[1-5][0-9]{14}/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 3,
        type: 'amex',
        regex: /^3[47][0-9]{13}/,
        mask: '9999 999999 99999',
    },
    {
        id: 4,
        type: 'diners',
        regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        mask: '9999 999999 9999',
    },
    {
        id: 5,
        type: 'discover',
        regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 6,
        type: 'jcb',
        regex: /^(?:2131|1800|35\d{3})\d{11}/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 7,
        type: 'elo',
        regex: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 8,
        type: 'hipercard',
        regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        mask: '9999 9999 9999 9999',
    },
];

const stylesInputNumberCard = {
    color: colors.basic.gray,
    paddingLeft: 15,
    minHeight: 35,
    backgroundColor: colors.theme.backgroundInputsPayment,
    border: 'none',
    borderRadius: 10,
    fontWeight: 500,
};

export default {
    initialInputs,
    validationCards,
    stylesInputNumberCard,
};
