const result = [
    {
        name: 'Estatísticas de vendas dos primeiros meses de 2021',
        description: 'Relação da quantidade de itens vendidos/reelbolsados/comprados em determinado período.',
        id: 1,
        type: 'line',
        legend: [
            {
                title: 'Itens Reembolsados',
            },
            {
                title: 'Itens Vendidos',
            },
            {
                title: 'Itens Comprados',
            },
        ],
        result: [
            [
                { x: 'Janeiro', y: 20 },
                { x: 'Fevereiro', y: 15 },
                { x: 'Março', y: 20 },
                { x: 'Abril', y: 15 },
                { x: 'Maio', y: 20 },
            ],
            [
                { x: 'Janeiro', y: 10 },
                { x: 'Fevereiro', y: 5 },
                { x: 'Março', y: 10 },
                { x: 'Abril', y: 5 },
                { x: 'Maio', y: 10 },
            ],
            [
                { x: 'Janeiro', y: 2 },
                { x: 'Fevereiro', y: 8 },
                { x: 'Março', y: 10 },
                { x: 'Abril', y: 8 },
                { x: 'Maio', y: 2 },
            ],
        ],
    },
    {
        name: 'Estatísticas de vendas dos primeiros meses de 2022',
        description: 'Relação da quantidade de itens vendidos/reelbolsados/comprados em determinado período.',
        id: 2,
        type: 'column',
        legend: [
            {
                title: 'Itens Reembolsados',
            },
            {
                title: 'Itens Vendidos',
            },
            {
                title: 'Itens Comprados',
            },
        ],
        result: [
            [
                { x: 'Janeiro', y: 20 },
                { x: 'Fevereiro', y: 15 },
                { x: 'Março', y: 20 },
                { x: 'Abril', y: 15 },
                { x: 'Maio', y: 20 },
            ],
            [
                { x: 'Janeiro', y: 10 },
                { x: 'Fevereiro', y: 5 },
                { x: 'Março', y: 10 },
                { x: 'Abril', y: 5 },
                { x: 'Maio', y: 10 },
            ],
            [
                { x: 'Janeiro', y: 2 },
                { x: 'Fevereiro', y: 8 },
                { x: 'Março', y: 10 },
                { x: 'Abril', y: 8 },
                { x: 'Maio', y: 2 },
            ],
        ],
    },
];

export default result;
