// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllTextSupport(
    searchTxt?: string,
    page?: number,
    limit?: number,
    idThemeRedaction?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        let renderBaseURL = `${baseURL}/supportingTextsRedaction?limit=10`;
        if (searchTxt) {
            renderBaseURL = `${renderBaseURL}&searchTxt=${searchTxt}`;
        }
        if (page) {
            renderBaseURL = `${renderBaseURL}&page=${page}`;
        }
        if (idThemeRedaction) {
            renderBaseURL = `${renderBaseURL}&idThemeRedaction=${idThemeRedaction}`;
        }
        const response = await fetch(renderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewTextSupport(
    description?: string,
    idManager?: number,
    idTheme?: number,
    token?: string,
) {
    try {
        const data = {
            description,
            idManager,
            idTheme,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/supportingTextsRedaction`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneTextSupport(
    description?: string,
    idManager?: number,
    idTheme?: number,
    idSupportingTextsRedaction?: number,
    token?: string,
) {
    try {
        const data = {
            description,
            idManager,
            idTheme,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/supportingTextsRedaction?idSupportingTextsRedaction=${idSupportingTextsRedaction}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneTextSupport(
    idThemeRedaction?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/supportingTextsRedaction?idSupportingTextsRedaction=${idThemeRedaction}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetAllTextSupport,
    CreateNewTextSupport,
    EditOneTextSupport,
    DeleteOneTextSupport,
};
