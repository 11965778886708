/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { RiMenuAddFill } from 'react-icons/ri';
import { Pagination } from '@material-ui/lab';
import { MenuItem, Select } from '@material-ui/core';
import FlatList from 'flatlist-react';
import 'react-toastify/dist/ReactToastify.css';

// components (navigation)
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import ModalManagePage from './components/ModalManage/ModalManagePage';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconTimelineBlue from '../../assets/images/iconTimelineBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './emitNotificationStyles';

// js
import colors from '../../styles/colors';
import notifications from '../../services/notifications';
import examination from '../../services/examination';

// interfaces
interface DataFileModuleInterface {
    file: any,
    eventFile: any,
    eventName: string,
}

interface AmountInterface {
    name: string,
    id: number,
}

const emitNotificationPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;
    const initialFileMovie: DataFileModuleInterface = {
        eventFile: new Blob(),
        eventName: 'Importar Vídeo ou Imagem',
        file: null,
    };

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [archive, setArchive] = useState<DataFileModuleInterface | string>(initialFileMovie);
    const [selectInput, setSelectInput] = useState('');
    const [allExamination, setAllExamination] = useState<AmountInterface[]>([]);
    const [subjectSelected, setSubjectSelected] = useState<AmountInterface[]>([]);
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setTitle(infoUser.title);
            setSubtitle(infoUser.subtitle);
            setDescription(infoUser.description);
            setArchive(infoUser.file);
            setSubjectSelected(infoUser.id_examination);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
            setTitle('');
            setSubtitle('');
            setDescription('');
            setArchive(initialFileMovie);
            setSubjectSelected([]);
        }
    };

    async function RenderQuantityPagination() {
        const allTimelineNotPage = await notifications.GetAllNotification(0, 0, GetToken);
        const formatPagination = String(allTimelineNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    const ActionEffect = async () => {
        const GetExamination = await examination.GetAllExamination('', 0, 0, GetToken);
        setAllExamination(GetExamination.examination.map((v: any) => ({
            name: v.name,
            id: v.id,
        })));
    };

    async function ActionInitial() {
        const allTimeline = await notifications.GetAllNotification(1, limit, GetToken);
        setDataFinal(allTimeline.result);
        RenderQuantityPagination();
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteTimeline') {
                const validation = await notifications.DeleteOneNotification(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Timeline deletada com sucesso!');
                }
            }
            if (valueSelected === 'EditTimeline') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Descrição:</TextThView>
                    {item?.description}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {item?.created_at}
                </TextTd>
                <TextTd>
                    <TextThView>Atualizado em:</TextThView>
                    {item?.updated_at}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditTimeline">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteTimeline">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir timeline</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            setTitle('');
            setSubtitle('');
            setDescription('');
            setArchive(initialFileMovie);
            setSubjectSelected([]);
            ActionInitial();
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allTimeline = await notifications.GetAllNotification(pageSelected, limit, GetToken);
        setDataFinal(allTimeline.result);
        setPageActual(pageSelected);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
        ActionEffect();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconTimelineBlue} text="GERENCIAR TIMELINE" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                {/* <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título da timeline..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch> */}
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Nova Timeline</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>DESCRIÇÃO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>ATUALIZADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                title,
                setTitle,
                subtitle,
                setSubtitle,
                description,
                setDescription,
                archive,
                setArchive,
                subjectSelected,
                setSubjectSelected,
                selectInput,
                setSelectInput,
                allExamination,
            )}
        </Container>
    );
};

export default emitNotificationPage;
