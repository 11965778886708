/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal, Fade } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    IconChecked,
    ContainerSelect,
    ContainerOption,
    ContainerMainCategories,
    ContainerCategoriesSelected,
    TxtCategoriesSelected,
    IconArrowCategories,
    ContainerRadius,
    ContainerCicleChecked,
    IconArrowCategoriesGoBack,
    TextFinish,
    ContainerAllInfoModal,
    ContainerCombo,
    TitleCombo,
    ContainerResponderTitle,
    AdjustCombo,
    TrashDelete,
} from './ModalManageStyles';
import bookSuggested from '../../../../services/bookSuggested';
import filtersRequest from '../../../../services/filters';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import arrowTip from '../../../../assets/images/arrowTip.png';
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import checkCircleBlueSea from '../../../../assets/images/checkCircleBlueSea.png';
import imageDeleteUser from '../../../../assets/images/imageDeleteUser.png';

interface AmountInterface {
    name: string,
    id: number,
}

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    title: string,
    setTitle: any,
    queries: Array<{ idFilters: Array<number>, idSubjects: Array<number>, id: string }>,
    setQueries: any,
    qtdId: any,
    setQtdId: any,
    selectExamination: string,
    setSelectExamination: any,
    selectEngineering: string,
    setSelectEngineering: any,
    allCategories: any,
    allFilters: any,
    setAllFilters: any,
    amountSelected: AmountInterface[],
    setAmountSelected: any,
    subjectSelected: AmountInterface[],
    setSubjectSelected: any,
    allExamination: any,
    allEngineering: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';

    // useState
    const [amountSelectedFalse, setAmountSelectedFalse] = useState<AmountInterface[]>([]);
    const [subjectSelectedFalse, setSubjectSelectedFalse] = useState<AmountInterface[]>([]);
    const [indexCategories, setIndexCategories] = useState(1);
    const [idCategories, setIdCategories] = useState(0);
    const [loading, setLoading] = useState(false);

    // methods
    function RenderNumberSelected(idCategory: any) {
        const getOnlyIds = amountSelected?.map((v: any) => v.id);
        const [getOnlyAllFilters] = allCategories?.filter((v: any) => v.id === idCategory);
        const filtersOnlyIds = getOnlyAllFilters.allFilters.filter((v: any) => getOnlyIds?.includes(v));
        return filtersOnlyIds.length;
    }

    function Select(subtext: string, value: string, setValue: any) {
        function RenderOptionsQuestion() {
            if (subtext === 'Selecione um concurso') {
                return allExamination.map((item: any) => (
                    <ContainerOption value={item.id}>{item.name}</ContainerOption>
                ));
            }

            if (subtext === 'Selecione uma formação') {
                return allEngineering.map((item: any) => (
                    <ContainerOption value={item.id}>{item.name}</ContainerOption>
                ));
            }

            return null;
        }

        function ChangeSelect(txt: any) {
            const valueSelected = txt.target.value;
            if (subtext === 'Selecione um concurso') {
                if (valueSelected !== 'Selecione um concurso') {
                    setValue(valueSelected);
                } else {
                    setValue('');
                }
            }
            if (subtext === 'Selecione uma formação') {
                if (valueSelected !== 'Selecione uma formação') {
                    setValue(valueSelected);
                } else {
                    setValue('');
                }
            }
        }

        return (
            <ContainerSelect onChange={ChangeSelect} defaultValue={value || subtext}>
                <ContainerOption>{subtext}</ContainerOption>
                {RenderOptionsQuestion()}
            </ContainerSelect>
        );
    }

    const handleClick = (v: any, valueSubject?: any) => {
        if (amountSelectedFalse?.filter((item) => item.id === v.id).length) {
            const removeItem = amountSelectedFalse?.filter((item) => item.id !== v.id);
            setAmountSelectedFalse(removeItem);
            if (valueSubject) {
                const allIdsSubject = valueSubject.map((item: any) => item.id);
                const removeItemSubject = subjectSelectedFalse.filter((item) => !allIdsSubject.includes(item.id));
                setSubjectSelectedFalse(removeItemSubject);
            }
        } else {
            setAmountSelectedFalse([...amountSelectedFalse || [], { name: v.name, id: v.id }]);
        }
    };

    const handleClickSubject = (v: any, valueFilter: any) => {
        if (subjectSelectedFalse.filter((item) => item.id === v.id).length) {
            const removeItem = subjectSelectedFalse.filter((item) => item.id !== v.id);
            setSubjectSelectedFalse(removeItem);
            if (!removeItem.length) {
                handleClick(valueFilter);
            }
        } else {
            setSubjectSelectedFalse([...subjectSelectedFalse || [], { name: v.name, id: v.id }]);
        }
    };

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        if (text === 'EXAME' || text === 'FORMAÇÃO') {
            return Select(subtext, value, setValue);
        }

        if (text === 'CATEGORIAS') {
            return (
                <ContainerMainCategories>
                    {indexCategories !== 1 ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <IconArrowCategoriesGoBack
                                src={arrowTip}
                                onClick={() => {
                                    setIndexCategories(1);
                                    setAmountSelectedFalse([]);
                                    setSubjectSelectedFalse([]);
                                    // setNameCategory('');
                                }}
                            />
                            <TextFinish
                                onClick={() => {
                                    setIndexCategories(1);
                                    setAmountSelected(amountSelectedFalse);
                                    setSubjectSelected(subjectSelectedFalse);
                                    setAmountSelectedFalse([]);
                                    setSubjectSelectedFalse([]);
                                }}
                            >
                                Concluído
                            </TextFinish>
                        </div>
                    ) : null}
                    {value.map((valueCategories: any) => (
                        <>
                            {indexCategories === 1 ? (
                                <Fade in={indexCategories === 1}>
                                    <ContainerCategoriesSelected
                                        onClick={async () => {
                                            const getAllFilters = await filtersRequest.GetSelectedFilters(valueCategories.id, localStorage.getItem('@Engequest:token') || '');
                                            setAllFilters(getAllFilters.result);
                                            setIndexCategories(2);
                                            setIdCategories(valueCategories.id);
                                            setAmountSelectedFalse(amountSelected);
                                            setSubjectSelectedFalse(subjectSelected);
                                            // setNameCategory(valueCategories.name);
                                        }}
                                    >
                                        <TxtCategoriesSelected>{valueCategories.name}</TxtCategoriesSelected>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ContainerRadius backgroundColor={RenderNumberSelected(valueCategories.id)}>
                                                <text style={{ color: 'white', fontSize: 12 }}>{RenderNumberSelected(valueCategories.id)}</text>
                                            </ContainerRadius>
                                            <IconArrowCategories src={arrowTip} />
                                        </div>
                                    </ContainerCategoriesSelected>
                                </Fade>
                            ) : null}
                            {indexCategories !== 1 && valueCategories.id === idCategories && allFilters.length ? (
                                <>
                                    {allFilters.map((valueFilters: any) => (
                                        <>
                                            <Fade in={indexCategories !== 1}>
                                                <ContainerCategoriesSelected
                                                    onClick={() => {
                                                        if (valueFilters.subjects.length) {
                                                            // setIndexCategories(3);
                                                            setSubjectSelectedFalse([...subjectSelectedFalse || [], ...valueFilters.subjects]);
                                                            handleClick(valueFilters, valueFilters.subjects);
                                                        } else {
                                                            handleClick(valueFilters);
                                                        }
                                                    }}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length ? (
                                                            <IconChecked src={checkCircleBlueSea} />
                                                        ) : (
                                                            <ContainerCicleChecked />
                                                        )}
                                                        <TxtCategoriesSelected>{valueFilters.name}</TxtCategoriesSelected>
                                                    </div>
                                                </ContainerCategoriesSelected>
                                            </Fade>
                                            {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length && valueFilters.subjects.length ? (
                                                <>
                                                    {valueFilters.subjects.map((valueSubjects: any) => (
                                                        <Fade in={indexCategories !== 1}>
                                                            <ContainerCategoriesSelected
                                                                onClick={() => {
                                                                    handleClickSubject(valueSubjects, valueFilters);
                                                                }}
                                                                style={{ marginLeft: 30 }}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    {subjectSelectedFalse.filter((item) => item.id === valueSubjects.id).length ? (
                                                                        <IconChecked src={checkCircleBlueSea} />
                                                                    ) : (
                                                                        <ContainerCicleChecked />
                                                                    )}
                                                                    <TxtCategoriesSelected>{valueSubjects.name}</TxtCategoriesSelected>
                                                                </div>
                                                            </ContainerCategoriesSelected>
                                                        </Fade>
                                                    ))}
                                                </>
                                            ) : null}
                                        </>
                                    ))}
                                </>
                            ) : null}
                        </>
                    ))}
                </ContainerMainCategories>
            );
        }

        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            setValue(txt.target.value);
        };

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type="text"
                onChange={onChangeText}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue?: any) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput adjustLabel={text === 'CATEGORIAS'}>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    async function SaveTest() {
        setLoading(true);
        if (!userEdit?.id) {
            await bookSuggested.CreateNewBookSuggested(
                title,
                queries,
                Number(selectExamination),
                Number(selectEngineering),
                GetToken,
            )
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Caderno criado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        } else {
            await bookSuggested.EditOneBookSuggested(
                title,
                queries,
                Number(selectExamination),
                Number(selectEngineering),
                userEdit?.id,
                GetToken,
            )
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Caderno editado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
        setQueries([]);
    }

    const AddCategories = () => {
        if (amountSelected.length || subjectSelected.length) {
            const oneCombo = {
                id: qtdId,
                idFilters: amountSelected?.map((v) => v.id) || [],
                idSubjects: subjectSelected?.map((v) => v.id) || [],
            };
            setQtdId(qtdId + 1);
            setQueries([...queries, oneCombo]);
            setAmountSelected([]);
            setSubjectSelected([]);
        } else {
            alert('Selecione um filtro e clique em concluído para adicionar um combo.');
        }
    };

    // main
    return (
        <>
            {loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Novo Caderno</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <ContainerAllInfoModal>
                                <Body>
                                    {DivInput('TÍTULO', 'Título', title, setTitle)}
                                    {DivInput('EXAME', 'Selecione um concurso', selectExamination, setSelectExamination)}
                                    {DivInput('FORMAÇÃO', 'Selecione uma formação', selectEngineering, setSelectEngineering)}
                                    {DivInput('CATEGORIAS', 'Categorias', allCategories)}
                                    <ButtonSave onClick={AddCategories}>Adicionar Combo</ButtonSave>
                                </Body>
                                {queries?.length > 0 ? (
                                    <Body>
                                        {queries.map((v) => {
                                            const DeleteCombo = () => {
                                                const newCombo = queries.filter((el) => el.id !== v.id);
                                                setQueries(newCombo);
                                            };
                                            return (
                                                <AdjustCombo>
                                                    <ContainerCombo>
                                                        <TitleCombo>{`Combo ${v.id}`}</TitleCombo>
                                                        <ContainerResponderTitle>{v.idFilters?.length}</ContainerResponderTitle>
                                                    </ContainerCombo>
                                                    <TrashDelete onClick={DeleteCombo} src={imageDeleteUser} />
                                                </AdjustCombo>
                                            );
                                        })}
                                    </Body>
                                ) : null}
                            </ContainerAllInfoModal>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={SaveTest}>{!userEdit?.id ? 'Criar Caderno' : 'Salvar Caderno'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default modalManagePage;
