// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllSimulates(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/simulates?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/simulates?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewSimulate(
    title: string,
    dateInitial: number,
    step: '1' | '2',
    // dateFinal: number,
    // idFilters: any,
    // idSubjects: any,
    timer: number,
    idExamination: string,
    idEngineering: string,
    pdf: any,
    pdfGabarito: any,
    token: string,
) {
    try {
        let data: any = {
            title,
            dateInitial,
            step,
            // dateFinal,
            // idFilters,
            // idSubjects,
            timer,
            idExamination,
            idEngineering,
        };
        if (typeof pdf === 'string') {
            data = {
                ...data,
                pdf,
            };
        }
        if (typeof pdfGabarito === 'string') {
            data = {
                ...data,
                pdfFeedback: pdfGabarito,
            };
        }
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/simulates`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneSimulate(
    title: string,
    dateInitial: number,
    step: '1' | '2',
    // dateFinal: number,
    // idFilters: any,
    // idSubjects: any,
    timer: number,
    idExamination: string,
    idEngineering: string,
    pdf: any,
    pdfGabarito: any,
    idSimulate: string,
    token: string,
) {
    try {
        let data: any = {
            title,
            dateInitial,
            step,
            // dateFinal,
            // idFilters,
            // idSubjects,
            timer,
            idExamination,
            idEngineering,
        };
        if (typeof pdf === 'string') {
            data = {
                ...data,
                pdf,
            };
        }
        if (typeof pdfGabarito === 'string') {
            data = {
                ...data,
                pdfFeedback: pdfGabarito,
            };
        }
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/simulates?idSimulate=${idSimulate}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneSimulate(
    idSimulate?: string,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/simulates?idSimulate=${idSimulate}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    EditOneSimulate,
    GetAllSimulates,
    CreateNewSimulate,
    DeleteOneSimulate,
};
