// libraries
import React from 'react';
import { Modal } from '@material-ui/core';

// js
import {
    ModalContainer,
    Container,
    AdjustButtons,
    ButtonAction,
    ButtonActionCancel,
} from './modalDeleteQuestionsStyles';

const ModalDeleteAllQuestionsPage = (
    open: boolean,
    handleClose: any,
    onDelete: any,
) => (
    <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
    >
        <ModalContainer>
            <Container>
                <text style={{ color: 'black' }}>Você deseja deletar o último cadastro em massa efetuado?</text>
                <AdjustButtons>
                    <ButtonActionCancel onClick={handleClose}>CANCELAR</ButtonActionCancel>
                    <ButtonAction onClick={onDelete}>DELETAR</ButtonAction>
                </AdjustButtons>
            </Container>
        </ModalContainer>
    </Modal>
);

export default ModalDeleteAllQuestionsPage;
