// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllTournament(
    searchTxt: string,
    page: number,
    limit: number,
    token: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/dailyChallenges?titleForSearching=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/dailyChallenges?titleForSearching=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return null;
    }
}

async function CreateNewTournament(
    title: string,
    dateInitial: string,
    dateFinal: string | null,
    queries: any,
    idExamination: number,
    idEngineering: number,
    token: string,
) {
    try {
        let data: any = {
            title,
            dateStart: dateInitial,
            dateEnd: dateFinal,
            queries,
            idExamination,
        };
        if (idEngineering) {
            data = {
                ...data,
                idEngineering,
            };
        }
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/dailyChallenges`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return null;
    }
}

async function EditOneTournament(
    title: string,
    dateInitial: string,
    dateFinal: string,
    queries: any,
    idExamination: number,
    idEngineering: number,
    idTournament: number,
    token: string,
) {
    try {
        const data = {
            title,
            dateStart: dateInitial,
            dateEnd: dateFinal,
            queries,
            idExamination,
            idEngineering,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/dailyChallenges?idDailyChallenge=${idTournament}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return null;
    }
}

async function DeleteOneTournament(
    idDailyChallenge: number,
    token: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/dailyChallenges?idDailyChallenge=${idDailyChallenge}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return null;
    }
}

export default {
    GetAllTournament,
    CreateNewTournament,
    EditOneTournament,
    DeleteOneTournament,
};
