// libraries
import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// pages
import LoginPage from '../pages/Login/LoginPage';
import UsersPage from '../pages/users/UsersPage';
import issueManagePage from '../pages/issueManage/issueManagePage';
import contributorsAreaPage from '../pages/contributorsArea/contributorsAreaPage';
import plansPage from '../pages/Plans/plansPage';
import examinationPage from '../pages/examination/examinationPage';
import engineeringPage from '../pages/engineering/engineeringPage';
import testsPage from '../pages/tests/testsPage';
import simulatesPage from '../pages/simulates/simulatesPage';
import bookSuggestedPage from '../pages/bookSuggested/bookSuggestedPage';
import categoriesPage from '../pages/categories/categoriesPage';
import filtersPage from '../pages/filters/filtersPage';
import subjectPage from '../pages/subject/subjectPage';
import statisticsPage from '../pages/statistics/statisticsPage';
import emitNotificationPage from '../pages/emitNotification/emitNotificationPage';
import emitPushNotificationPage from '../pages/emitPushNotification/emitPushNotificationPage';
import signaturesPage from '../pages/signatures/signaturesPage';
import paymentPage from '../pages/payment/paymentPage';
import bookPage from '../pages/book/bookPage';
import faqPage from '../pages/Faq/faqPage';
import ReportsPage from '../pages/Reports/ReportsPage';
import tournamentPage from '../pages/tournament/tournamentPage';
import commentsPage from '../pages/comments/commentsPage';
import essayPage from '../pages/essay/essayPage';
import textSupportPage from '../pages/textSupport/textSupportPage';

const Routes: React.FC = () => {
    const history = useHistory();
    const token = localStorage.getItem('@Engequest:token');
    const validation = !token && !isMobile;
    if (validation) {
        history.push('/');
    }

    const RenderRoutes = () => {
        if (!token && isMobile) {
            return (
                <Switch>
                    <Route path="/signatures/typePlan=:type/idUser=:idUser/token=:token" component={signaturesPage} />
                    <Route path="/payment/typePlan=:type/idUser=:idUser/token=:token" component={paymentPage} />
                </Switch>
            );
        }

        return (
            <Switch>
                <Route path="/" exact component={LoginPage} />
                <Route path="/users" component={UsersPage} />
                <Route path="/contributors" component={contributorsAreaPage} />
                <Route path="/questions" component={issueManagePage} />
                <Route path="/plans" component={plansPage} />
                <Route path="/examination" component={examinationPage} />
                <Route path="/engineering" component={engineeringPage} />
                <Route path="/tests" component={testsPage} />
                <Route path="/simulates" component={simulatesPage} />
                <Route path="/bookSuggested" component={bookSuggestedPage} />
                <Route path="/categories" component={categoriesPage} />
                <Route path="/filters" component={filtersPage} />
                <Route path="/subject" component={subjectPage} />
                <Route path="/statistics" component={statisticsPage} />
                <Route path="/emitNotification" component={emitNotificationPage} />
                <Route path="/emitPushNotification" component={emitPushNotificationPage} />
                <Route path="/books" component={bookPage} />
                <Route path="/faq" component={faqPage} />
                <Route path="/reports" component={ReportsPage} />
                <Route path="/tournament" component={tournamentPage} />
                <Route path="/comments" component={commentsPage} />
                <Route path="/essay" component={essayPage} />
                <Route path="/textSupport" component={textSupportPage} />
            </Switch>
        );
    };

    return RenderRoutes();
};

export default Routes;
