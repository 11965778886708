// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetSelectedFilters(idCategory: number, token: string) {
    try {
        const headers = {
            'x-access-token': token,
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const validationPath = idCategory ? `${baseURL}/filters?idCategory=${idCategory}` : `${baseURL}/filters`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function GetSelectedFiltersSearch(searchTxt: string, token: string) {
    try {
        const headers = {
            'x-access-token': token,
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/filters/autocomplete?searchTxt=${searchTxt}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function GetAllFilters(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
    idCategory?: number,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        let RenderBaseURL = page ? `${baseURL}/filters?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/filters?searchTxt=${searchTxt}&limit=${limit || ''}`;
        if (idCategory) {
            RenderBaseURL = `${RenderBaseURL}&idCategory=${idCategory}`;
        }
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewFilters(
    name?: string,
    idsCategories?: Array<string>,
    idsFormations?: Array<any>,
    token?: string,
) {
    try {
        const data = {
            name,
            idsCategories,
            idsEngineering: idsFormations,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/filters`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneFilters(
    name?: string,
    idFilter?: number,
    idCategory?: string,
    idsFormations?: Array<any>,
    token?: string,
) {
    try {
        const data = {
            name,
            idCategory,
            idsEngineering: idsFormations,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/filters?idFilter=${idFilter}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneFilters(
    idFilter?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/filters?idFilter=${idFilter}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetSelectedFilters,
    GetSelectedFiltersSearch,
    CreateNewFilters,
    GetAllFilters,
    EditOneFilters,
    DeleteOneFilters,
};
