/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { lightFormat } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { IoMdPersonAdd } from 'react-icons/io';

// components (navigation)
import ModalPage from './Modal/ModalPage';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import iconBlue from '../../assets/images/iconBlue.png';
import userDelete from '../../assets/images/userDelete.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import imageDisable from '../../assets/images/imageDisable.png';
import imageToEdit from '../../assets/images/imageToEdit.png';
import iconColaboradorBlue from '../../assets/images/iconColaboradorBlue.png';

/* TODO será usado em uma versão futura */
// import userAdd from '../../assets/images/userAdd.png';
// import iconGear from '../../assets/images/iconGear.png';

// js
import {
    Header,
    DivLine,
    ImgUser,
    LineTop,
    Container,
    InputShare,
    ButoonToAdd,
    TextDefault,
    ContainerShare,
    ContainerAcess,
    IconUserTeacher,
    ImageIconSelect,
    ContainerUserAll,
    TextNumberActive,
    TextTeacherActive,
    ContainerInputUser,
    ContainerButtonAdd,
    ContainerInputSearch,
    TextSelect,
    ImgDefault,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // TextSelector,
    // ContainerRadio,
    // LineRadio,
    // ImageGear,
    // ThreePoints,
} from './contributorsAreaStyles';
import managers from '../../services/managers';
import sendSearch from '../../assets/images/sendSearch.png';
import functions from '../../utils/functions';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';

// contexts
import colors from '../../styles/colors';

const UsersPage: React.FC = () => {
    // constants
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // useState
    const [dataFinal, setDataFinal] = useState<any[]>();
    const [open, setOpen] = useState(false);
    const [typeManager, setTypeManager] = useState(['active', 'inactive']);
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [allManagersActive, setAllManagersActive] = useState('');
    const [allManagersInactive, setAllManagersInactive] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [birthDate, setBirthDate] = useState<any>('');
    const [telephone, setTelephone] = useState('');
    const [idManager, setIdManager] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        setIdManager('');
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allManagers = await managers.GetAllManagers(search, typeManager?.length === 2 ? '' : typeManager?.[0] || '', pageSelected, limit, GetToken);
        setDataFinal(allManagers.result);
        setPageActual(pageSelected);
    }

    async function RenderQuantityPagination(searchSelected: string, typeUserSelected: string) {
        const allManagersNotPage = await managers.GetAllManagers(searchSelected || '', typeUserSelected || '', 0, 0, GetToken);
        const formatPagination = String(allManagersNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const allManagers = await managers.GetAllManagers('', '', 1, limit, GetToken);
        const allManagersNotPage = await managers.GetAllManagers('', '', 0, 0, GetToken);
        setAllManagersActive(allManagersNotPage?.result?.filter((v: any) => !v.inactivated_at).length);
        setAllManagersInactive(allManagersNotPage?.result?.filter((v: any) => v.inactivated_at !== null).length);
        setDataFinal(allManagers.result);
        RenderQuantityPagination(search, '');
    }

    async function ActionSearch() {
        setLoading(true);
        const validationType = typeManager?.length === 2 ? '' : typeManager?.[0] || '';
        const allManagers = await managers.GetAllManagers(search, validationType, 1, limit, GetToken);
        setDataFinal(allManagers.result);
        RenderQuantityPagination(search, validationType);
        setLoading(false);
    }

    const handleClose = () => {
        setOpen(false);
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setIdentifier('');
        setBirthDate('');
        setTelephone('');
        ActionInitial();
    };

    useEffect(() => {
        if (name && email && identifier && birthDate && telephone && idManager) {
            setOpen(true);
        }
    }, [name, email, identifier, birthDate, telephone, idManager]);

    function RenderListItems(item: any) {
        const EditManager = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteManager') {
                await managers.EditDisabledOrDeleteManager('deleted', item.id, GetToken);
                ActionInitial();
                toast.success('Colaborador deletado com sucesso!');
            }
            if (valueSelected === 'InactiveManager') {
                await managers.EditDisabledOrDeleteManager('inactivated', item.id, GetToken);
                ActionInitial();
                toast.success('Colaborador desativado com sucesso!');
            }
            if (valueSelected === 'EditManager') {
                const formatDate = lightFormat(Number(item?.birth_date), 'dd/MM/yyyy');
                setIdManager(item.id);
                setName(item?.name);
                setEmail(item?.email);
                setIdentifier(functions.MaskIdentifier(item?.cpf));
                setBirthDate(formatDate);
                setTelephone(functions.MaskPhone(item?.telephone));
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    {item?.photo ? (
                        <ImgUser src={item?.photo} />
                    ) : (
                        <ImgDefault />
                    )}
                </TextTd>
                <TextTd>
                    <TextThView>Nome:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>E-mail:</TextThView>
                    {item?.email}
                </TextTd>
                <TextTd>
                    <TextThView>Telefone:</TextThView>
                    {item?.telephone}
                </TextTd>
                <TextTd>
                    <TextThView>Identidade:</TextThView>
                    {item?.cpf}
                </TextTd>
                <TextTd>
                    <TextThView>Data de nascimento:</TextThView>
                    {lightFormat(Number(item?.birth_date), 'dd/MM/yyyy')}
                </TextTd>
                <TextTd>
                    <TextThView>Status:</TextThView>
                    {item?.inactivated_at ? 'inativo' : 'ativo'}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={EditManager}>
                        <MenuItem value="InactiveManager">
                            <ImageIconSelect src={imageDisable} />
                            <TextSelect>Desativar colaborador</TextSelect>
                        </MenuItem>
                        <MenuItem value="EditManager">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar colaborador</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteManager">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir colaborador</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    function renderUser(img: string, title: string, subtitle: string, index: number) {
        const TradeInfo = async () => {
            if (index === 1) {
                if (typeManager.includes('active')) {
                    setTypeManager(['inactive']);
                    const allManagers = await managers.GetAllManagers(search, 'inactive', 1, limit, GetToken);
                    setDataFinal(allManagers.result);
                    RenderQuantityPagination(search, 'inactive');
                } else {
                    setTypeManager(['active', ...typeManager]);
                    const validationTypeUser = ['active', ...typeManager]?.length === 2 ? '' : ['active', ...typeManager]?.[0] || '';
                    const allManagers = await managers.GetAllManagers(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allManagers.result);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            } else if (index === 2) {
                if (typeManager.includes('inactive')) {
                    setTypeManager(['active']);
                    const allManagers = await managers.GetAllManagers(search, 'active', 1, limit, GetToken);
                    setDataFinal(allManagers.result);
                    RenderQuantityPagination(search, 'active');
                } else {
                    setTypeManager([...typeManager, 'inactive']);
                    const validationTypeUser = [...typeManager, 'inactive']?.length === 2 ? '' : [...typeManager, 'inactive']?.[0] || '';
                    const allManagers = await managers.GetAllManagers(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allManagers.result);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            }
        };

        return (
            <ContainerUserAll>
                <ContainerInputUser onClick={TradeInfo}>
                    <IconUserTeacher src={img} />
                    <TextTeacherActive>{title}</TextTeacherActive>
                    <TextNumberActive>
                        <TextDefault>{subtitle}</TextDefault>
                    </TextNumberActive>
                </ContainerInputUser>
                {typeManager.includes(index === 1 ? 'active' : 'inactive') ? <DivLine /> : null}
            </ContainerUserAll>
        );
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconColaboradorBlue} text="COLABORADORES" />
                <LineTop />
                <ContainerAcess>
                    {renderUser(iconBlue, 'Colaboradores Ativos', allManagersActive || '0', 1)}
                    {renderUser(userDelete, 'Inativos', allManagersInactive || '0', 2)}
                </ContainerAcess>
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todos</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por professores, cargo, departamento..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <IoMdPersonAdd color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Novo Colaborador</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
                {/* TODO será usado em uma versão futura */}
                {/* <ImageGear src={iconGear} /> */}
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh />
                            <TextTh>NOME</TextTh>
                            <TextTh>EMAIL</TextTh>
                            <TextTh>TELEPHONE</TextTh>
                            <TextTh>IDENTIDADE</TextTh>
                            <TextTh>DATA DE NASCIMENTO</TextTh>
                            <TextTh>STATUS</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {/* Modals */}
            {ModalPage(
                open,
                handleClose,
                name,
                setName,
                email,
                setEmail,
                password,
                setPassword,
                confirmPassword,
                setConfirmPassword,
                identifier,
                setIdentifier,
                birthDate,
                setBirthDate,
                telephone,
                setTelephone,
                idManager,
            )}
        </Container>
    );
};
export default UsersPage;
