// libraries
import React, {
    useEffect,
    useState,
    useContext,
} from 'react';
import { Checkbox } from '@material-ui/core';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { IoMdMail } from 'react-icons/io';

// js
import {
    Home,
    Header,
    Action,
    FormArea,
    Container,
    ButtonEnter,
    TextCheckBox,
    ForgotPassword,
    ContainerEmail,
    ContainerImage,
    ContainerInputs,
    ContainerPassword,
    InputIconPassword,
} from './LoginStyles';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// contexts
import { AuthContext } from '../../context/AuthContext';

// Images
import engequest from '../../assets/images/engequest.png';
import colors from '../../styles/colors';

// interfaces
interface FormUserInterface {
    email: string,
    password: string,
}

const LoginPage: React.FC = () => {
    // consts
    const getEmailUser = localStorage.getItem('emailUser') || '';
    const marginIcon = { marginRight: 10 };
    const initialFormUser = {
        email: getEmailUser,
        password: '',
    };

    // contexts
    const {
        signIn,
        recoveryPassword,
        user,
        logout,
    } = useContext(AuthContext);

    // states
    const [modeAuth, setModeAuth] = useState<'login' | 'recovery'>('login');
    const [formUser, setFormUser] = useState<FormUserInterface>(initialFormUser);
    const [verifyButton, setVerifyButton] = useState(false);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [redirect, setRedirect] = useState(false);
    const [checked, setChecked] = useState(getEmailUser !== '');
    const [verificationSecureText, setVerificationSecureText] = useState(true);

    // effects
    useEffect(() => {
        const validatePassword = formUser.password.length >= 6;
        const validateEmail = re.test(String(formUser.email).toLowerCase());
        const validateButton = modeAuth === 'login' ? validatePassword && validateEmail : validateEmail;
        setVerifyButton(validateButton);
        if (localStorage.getItem('@Engequest:token') && user.token) {
            logout();
        }
    }, [formUser]);

    // methods
    function onChangeForm(e: React.ChangeEvent<
        HTMLInputElement |
        HTMLSelectElement |
        HTMLTextAreaElement
    >) {
        const keyChanged = e.target.id;
        const valueChanged = e.target.value;
        const newFormUser = {
            ...formUser,
            [keyChanged]: valueChanged,
        };
        setFormUser(newFormUser);
    }

    async function onSendForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (modeAuth === 'recovery') {
            recoveryPassword({ email: formUser.email });
        }
        if (modeAuth === 'login') {
            const validation = await signIn({ email: formUser.email, password: formUser.password });
            if (checked && validation) {
                localStorage.setItem('emailUser', formUser.email);
            }
            if (!checked && validation) {
                localStorage.removeItem('emailUser');
            }
            setRedirect(validation);
        }
        setFormUser(initialFormUser);
    }

    function onClickRecoveryMode() {
        const nextMode = modeAuth === 'recovery' ? 'login' : 'recovery';
        setModeAuth(nextMode);
        setFormUser(initialFormUser);
    }

    const ViewPassword = () => {
        setVerificationSecureText(!verificationSecureText);
    };

    const RenderIconSecure = () => {
        if (verificationSecureText) {
            return <AiFillEyeInvisible color={colors.basic.blue} size={28} />;
        }

        return <AiFillEye color={colors.basic.blue} size={28} />;
    };

    const ChangeCheckbox = () => {
        setChecked(!checked);
    };

    // main
    return (
        <Home>
            <ToNavigation pathRoute="/users" redirect={redirect} />
            <Container>
                <Header>
                    <ContainerImage src={engequest} />
                </Header>
                <FormArea onSubmit={onSendForm}>
                    <ContainerInputs>
                        <ContainerEmail
                            required
                            id="email"
                            type="email"
                            placeholder="Nome de usuário ou e-mail"
                            value={formUser.email}
                            onChange={onChangeForm}
                        />
                        <IoMdMail color={colors.basic.blue} size={28} style={marginIcon} />
                    </ContainerInputs>
                    {modeAuth === 'recovery' ? null : (
                        <ContainerInputs>
                            <ContainerPassword
                                id="password"
                                type={verificationSecureText ? 'password' : 'text'}
                                placeholder="Sua senha"
                                value={formUser.password}
                                onChange={onChangeForm}
                            />
                            <InputIconPassword onClick={ViewPassword}>
                                {RenderIconSecure()}
                            </InputIconPassword>
                        </ContainerInputs>
                    )}
                    <Action>
                        <ButtonEnter disabled={!verifyButton} backgroundColor={verifyButton}>{modeAuth === 'recovery' ? 'ENVIAR' : 'ENTRAR'}</ButtonEnter>
                    </Action>
                    <Action>
                        {modeAuth === 'recovery' ? null : (
                            <>
                                <Checkbox
                                    checked={checked}
                                    onChange={ChangeCheckbox}
                                    style={{ color: colors.basic.darkblue }}
                                />
                                <TextCheckBox>Lembrar-me</TextCheckBox>
                            </>
                        )}
                        <ForgotPassword onClick={onClickRecoveryMode}>
                            {modeAuth === 'recovery' ? 'Fazer login' : 'Esqueci minha senha'}
                        </ForgotPassword>
                    </Action>
                </FormArea>
            </Container>
        </Home>
    );
};
export default LoginPage;
