// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function UploadFile(
    file?: any,
) {
    try {
        const body = new FormData();
        body.append('file', file);
        const method = 'POST';
        const configs = {
            method,
            body,
        };
        const response = await fetch(`${baseURL}/uploads`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    UploadFile,
};
