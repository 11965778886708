/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useContext } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    ContainerManage,
    InputTextArea,
} from './ModalFaqStyles';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import faq from '../../../../services/faq';

// contexts
import { AuthContext } from '../../../../context/AuthContext';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    title: string,
    setTitle: any,
    description: string,
    setDescription: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const [loading, setLoading] = useState(false);

    // contexts
    const { user } = useContext(AuthContext);

    // methods
    function RenderSelectOrInput(
        text: string,
        subtext: string,
        value: any,
        setValue: any,
    ) {
        if (text === 'DESCRIÇÃO') {
            const onChangeDescription = (
                txt: React.ChangeEvent<HTMLTextAreaElement>,
            ) => {
                setValue(txt.target.value);
            };
            return (
                <InputTextArea
                    rows={5}
                    placeholder={subtext}
                    value={value}
                    onChange={onChangeDescription}
                />
            );
        }

        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            setValue(txt.target.value);
        };

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type="text"
                onChange={onChangeText}
            />
        );
    }

    function DivInput(
        text: string,
        subtext: string,
        value: any,
        setValue: any,
    ) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    async function CreateOrEditSubject() {
        setLoading(true);
        if (!userEdit?.id) {
            await faq
                .CreateNewFaq(title, description, user.id, GetToken)
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('FAQ criado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        } else {
            await faq
                .EditOneFaq(
                    title,
                    description,
                    userEdit?.id_manager,
                    user.id,
                    GetToken,
                )
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('FAQ editado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    }

    // main
    return (
        <>
            {loading ? (
                <LoadingComponent
                    open={loading}
                    setOpen={setLoading}
                    label="Carregando..."
                />
            ) : (
                <Modal open={open} onClose={handleClose} closeAfterTransition>
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>
                                            Adicionar Novo Assunto
                                        </TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                {DivInput('TÍTULO', 'Título', title, setTitle)}
                            </Body>
                            <ContainerManage>
                                {DivInput(
                                    'DESCRIÇÃO',
                                    'Descrição',
                                    description,
                                    setDescription,
                                )}
                            </ContainerManage>
                            <Footer>
                                <ButtonExit onClick={handleClose}>
                                    Cancelar
                                </ButtonExit>
                                <ButtonSave onClick={CreateOrEditSubject}>
                                    {!userEdit?.id ? 'Criar FAQ' : 'Salvar FAQ'}
                                </ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>
    );
};

export default modalManagePage;
