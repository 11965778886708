/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React from 'react';
import { useParams } from 'react-router-dom';

// js
import colors from '../../styles/colors';
import {
    Container,
    Home,
    ContainerBody,
    ContainerSignatures,
    Title,
    ContainerAdjustPrices,
    ButtonAction,
    IconDescription,
    ContainerDiscount,
    TextDiscount,
    ContainerIconSignature,
    IconSignature,
} from './signaturesStyles';
import signaturesConstants from './signaturesConstants';
import functions from '../../utils/functions';
import paymentApi from '../../services/payment';

// images
import goBackGreen from '../../assets/images/goBackGreen.png';
import checkedGreen from '../../assets/images/checkedGreen.png';

// interfaces
interface getParamsForgotPasswordInterfaces {
    type?: string,
    idUser?: string,
    token?: string,
}

const signaturesPage: React.FC = () => {
    // constants
    const getParams: getParamsForgotPasswordInterfaces = useParams();

    const typePlan = getParams?.type !== 'null' ? getParams?.type : '';

    return (
        <Container>
            <Home>
                <ContainerBody>
                    {signaturesConstants.allSignatures.map((v) => {
                        const GoToNavigationPayment = async () => {
                            if (typePlan && typePlan === v.type) {
                                const GetInfoCancelPlan = await paymentApi.CancelPlan(
                                    getParams?.idUser || '',
                                    getParams?.token || '',
                                );
                                if (GetInfoCancelPlan?.status === 200) {
                                    // eslint-disable-next-line no-alert
                                    alert(GetInfoCancelPlan?.message || 'Plano cancelado!');
                                    window.location.replace('/signatures');
                                } else {
                                    // eslint-disable-next-line no-alert
                                    alert(GetInfoCancelPlan?.message || 'Ocorreu um erro ao processar o pagamento!');
                                }
                            } else {
                                if (v.id === 1) {
                                    window.location.replace(`/payment/typePlan=premium/idUser=${getParams.idUser}/token=${getParams.token}`);
                                }
                                if (v.id === 2) {
                                    window.location.replace(`/payment/typePlan=plus/idUser=${getParams.idUser}/token=${getParams.token}`);
                                }
                                if (v.id === 3) {
                                    window.location.replace(`/payment/typePlan=unlimited/idUser=${getParams.idUser}/token=${getParams.token}`);
                                }
                            }
                        };
                        const ValidationBackgroundColorButton = () => {
                            if (typePlan && typePlan === v.type) {
                                return colors.basic.redUltra;
                            }
                            if (typePlan && typePlan !== v.type) {
                                return colors.basic.gray;
                            }
                            return colors.basic.blueDefault;
                        };
                        const ValidationDisabledButton = () => {
                            if (typePlan && typePlan === v.type) {
                                return false;
                            }
                            if (typePlan && typePlan !== v.type) {
                                return true;
                            }
                            return false;
                        };
                        return (
                            <ContainerSignatures key={v.id}>
                                <ContainerIconSignature>
                                    <IconSignature src={v.icon} />
                                </ContainerIconSignature>
                                <Title fontWeight="500" fontSize="20px" marginBottom="10px">{v.title}</Title>
                                <Title marginBottom="10px" color={colors.basic.gray}>
                                    De
                                    <Title color={colors.basic.gray} fontWeight="700" textDecoration="line-through" marginLeft="5px">{`R$ ${functions.toPrice(v.priceOld, 2)}`}</Title>
                                    <Title color={colors.basic.gray} marginLeft="5px">Por</Title>
                                </Title>
                                <ContainerAdjustPrices marginBottom="10px" justifyContent="space-between" widthContainer="100%">
                                    <ContainerDiscount>
                                        <TextDiscount>40% OFF</TextDiscount>
                                    </ContainerDiscount>
                                    <ContainerAdjustPrices>
                                        <ContainerAdjustPrices flexDirection="column">
                                            <Title marginBottom="10px" color={colors.basic.gray}>R$</Title>
                                            <Title color={colors.basic.gray}>12x</Title>
                                        </ContainerAdjustPrices>
                                        <Title fontWeight="700" fontSize="46px" marginLeft="10px">
                                            {functions.toPrice(v.priceInstallments, 2)}
                                        </Title>
                                    </ContainerAdjustPrices>
                                    <ContainerAdjustPrices widthContainer="10%" />
                                </ContainerAdjustPrices>
                                <Title color={colors.basic.gray}>{`ou R$ ${functions.toPrice(v.priceNow, 2)} à vista`}</Title>
                                <ButtonAction
                                    onClick={GoToNavigationPayment}
                                    disabledButton={ValidationDisabledButton()}
                                    backgroundColor={ValidationBackgroundColorButton()}
                                    disabled={ValidationDisabledButton()}
                                >
                                    <Title color={colors.basic.white} fontWeight="700">{typePlan === v.type ? 'CANCELE AGORA' : 'ASSINE AGORA'}</Title>
                                </ButtonAction>
                                {v.descriptionDefault ? (
                                    <ContainerAdjustPrices widthContainer="90%" marginBottom="10px">
                                        <IconDescription src={goBackGreen} width="30px" height="30px" marginLeft="6px" />
                                        <Title color={colors.basic.gray} fontWeight="700" marginLeft="10px">{v.descriptionDefault}</Title>
                                    </ContainerAdjustPrices>
                                ) : null}
                                {v.descriptions.map((value, index) => (
                                    <ContainerAdjustPrices key={String(index)} widthContainer="90%" marginBottom="10px">
                                        <IconDescription src={checkedGreen} />
                                        <Title color={colors.basic.gray} fontWeight="500">{value}</Title>
                                    </ContainerAdjustPrices>
                                ))}
                            </ContainerSignatures>
                        );
                    })}
                </ContainerBody>
            </Home>
        </Container>
    );
};

export default signaturesPage;
