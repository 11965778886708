// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllTests(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/tests?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/tests?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewTest(
    title: string,
    year: string,
    idFilters: any,
    idSubjects: any,
    timer: number,
    idExamination: string,
    idEngineering: string,
    token: string,
) {
    try {
        const data = {
            title,
            year: Number(year),
            idFilters,
            idSubjects,
            timer,
            idExamination: Number(idExamination),
            idEngineering: Number(idEngineering),
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/tests`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneTest(
    title: string,
    year: string,
    idFilters: any,
    idSubjects: any,
    timer: number,
    idExamination: string,
    idEngineering: string,
    idTest: string,
    token: string,
) {
    try {
        const data = {
            title,
            year: Number(year),
            idFilters,
            idSubjects,
            timer,
            idExamination: Number(idExamination),
            idEngineering: Number(idEngineering),
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/tests?idTest=${idTest}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneTest(
    idTest?: string,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/tests?idTest=${idTest}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    EditOneTest,
    GetAllTests,
    CreateNewTest,
    DeleteOneTest,
};
