// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';

export const ModalContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
`;

export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-height: calc(110vh - 220px);
    width: 50%;
    max-width: 1200px;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const ButtonAction = styled.div`
    background-color: ${colors.basic.redUltra};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: ${colors.basic.white};
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 10px;
    width: 100px;
    cursor: pointer;
    padding: 5px;
`;

export const ButtonActionCancel = styled.div`
    background-color: ${colors.basic.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: ${colors.basic.white};
    cursor: pointer;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 10px;
    width: 100px;
    padding: 5px;
`;

export const AdjustButtons = styled.div`
    display: flex;
`;
