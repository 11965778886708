/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import {
    Loader,
    LoaderContainer,
} from './ModalManageStyles';

const Spinner = () => (
    <LoaderContainer>
        <Loader>
            <div />
        </Loader>
        <p style={{ color: 'black', paddingTop: '10px' }}>Carregando Upload...</p>
    </LoaderContainer>);

export default Spinner;
