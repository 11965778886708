/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { addHours, lightFormat } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconSimulatesBlue from '../../assets/images/iconSimulatesBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './simulatesStyles';
import categories from '../../services/categories';
import simulates from '../../services/simulates';
import engineering from '../../services/engineering';
import examination from '../../services/examination';
import colors from '../../styles/colors';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

interface AmountInterface {
    name: string,
    id: number,
}
interface DataFileModuleInterface {
    file: any,
    eventFile: any,
    eventName: string,
}

const simulatesPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;
    const initialFileMovie: DataFileModuleInterface = {
        eventFile: new Blob(),
        eventName: 'Importar PDF',
        file: null,
    };

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [title, setTitle] = useState('');
    const [dateInitial, setDateInitial] = useState('');
    const [dateFinal, setDateFinal] = useState('');
    const [timer, setTimer] = useState('0/0');
    const [selectExamination, setSelectExamination] = useState('');
    const [selectEngineering, setSelectEngineering] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    const [allExamination, setAllExamination] = useState([]);
    const [allEngineering, setAllEngineering] = useState([]);
    const [allFilters, setAllFilters] = useState([]);
    const [amountSelected, setAmountSelected] = useState<AmountInterface[]>([]);
    const [subjectSelected, setSubjectSelected] = useState<AmountInterface[]>([]);
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);
    const [archive, setArchive] = useState<DataFileModuleInterface | string>(initialFileMovie);
    const [gabaritoArchive, setGabaritoArchive] = useState<DataFileModuleInterface | string>(initialFileMovie);
    const [selectPhase, setSelectPhase] = useState<'1' | '2' | ''>('');
    const [allSelectExamination, setAllSelectExamination] = useState<Array<{ id: string, name: string }>>([]);
    const [allSelectEngineering, setAllSelectEngineering] = useState<Array<{ id: string, name: string }>>([]);

    const GetAllCategories = async () => {
        const GetCategories = await categories.GetAllCategories('', 0, 0, GetToken);
        setAllCategories(GetCategories.result);
        const GetExamination = await examination.GetAllExamination('', 0, 0, GetToken);
        setAllExamination(GetExamination.examination);
        const GetEngineering = await engineering.GetAllEngineering('', 0, 0, GetToken);
        setAllEngineering(GetEngineering.engineering);
    };

    const handleOpen = async (infoUser: any) => {
        setOpen(true);
        if (infoUser?.title) {
            const formatDateInitial = lightFormat(addHours(new Date(Number(infoUser.date_initial || 1625270400000)), 9), 'yyyy-MM-dd');
            const formatDateFinal = lightFormat(addHours(new Date(Number(infoUser.date_final || 1625270400000)), 9), 'yyyy-MM-dd');
            const hourTimers = Number(infoUser?.timer) >= 3600000 ? Number(infoUser?.timer) / 3600000 : 0;
            const defineHourTimers = hourTimers.toString().substring(0, 1);
            const minTimers = infoUser?.timer - (Number(defineHourTimers) * 3600000);
            const defineMinTimers = (minTimers / 60000).toFixed(0);
            const examinationList = allExamination.filter((v: any) => infoUser.id_examination.includes(v.id)).map((e: any) => ({ id: e.id, name: e.name }));
            const engineeringList = allEngineering.filter((v: any) => infoUser.id_engineering.includes(v.id)).map((e: any) => ({ id: e.id, name: e.name }));

            setUserEdit(infoUser);
            setTitle(infoUser.title);
            setTimer(`${defineHourTimers}/${defineMinTimers}`);
            setDateInitial(formatDateInitial);
            setDateFinal(formatDateFinal);
            setSelectPhase(infoUser.step);
            setAllSelectExamination(examinationList);
            setAllSelectEngineering(engineeringList);
            setAmountSelected(infoUser.id_filters);
            setSubjectSelected(infoUser.id_subjects);
            setArchive(infoUser?.pdf || initialFileMovie);
            setGabaritoArchive(infoUser?.pdf_feedback || initialFileMovie);
        } else {
            setUserEdit(null);
            setTitle('');
            setTimer('0/0');
            setDateInitial('');
            setDateFinal('');
            setSelectPhase('');
            setAllSelectExamination([]);
            setAllSelectEngineering([]);
            setSelectExamination('');
            setSelectEngineering('');
            setAmountSelected([]);
            setSubjectSelected([]);
            setArchive(initialFileMovie);
            setGabaritoArchive(initialFileMovie);
        }
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allSimulatesNotPage = await simulates.GetAllSimulates(searchSelected || '', 0, 0, GetToken);
        const splitPagination = Math.floor(allSimulatesNotPage.result.length / 10);
        setCountPagination(1);
        setCountPagination(splitPagination);
        setPageActual(1);
    }

    async function ActionInitial() {
        const allSimulates = await simulates.GetAllSimulates('', 1, limit, GetToken);
        setDataFinal(allSimulates.result);
        RenderQuantityPagination(search);
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function ActionSearch() {
        setLoading(true);
        const allSimulates = await simulates.GetAllSimulates(search, 1, limit, GetToken);
        setDataFinal(allSimulates.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    async function onChangePagination(event: any, pageSelected: number) {
        const allSimulates = await simulates.GetAllSimulates(search, pageSelected, limit, GetToken);
        setDataFinal(allSimulates.result);
        setPageActual(pageSelected);
    }

    // useEffect
    useEffect(() => {
        GetAllCategories();
        ActionInitial();
    }, []);

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteSimulate') {
                await simulates.DeleteOneSimulate(item?.id, GetToken);
                ActionInitial();
                toast.success('Simulador deletado com sucesso!');
            }
            if (valueSelected === 'EditSimulate') {
                handleOpen(item);
            }
        };

        const hourTimer = Number(item?.timer) >= 3600000 ? Number(item?.timer) / 3600000 : 0;
        const defineHourTimer = hourTimer.toString().substring(0, 1);
        const minTimer = item?.timer - (Number(defineHourTimer) * 3600000);
        const defineMinTimer = (minTimer / 60000).toFixed(0);

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.title}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {`${item?.step}ª`}
                </TextTd>
                <TextTd>
                    <TextThView>Data inicial:</TextThView>
                    {lightFormat(addHours(new Date(Number(item.date_initial || 1625270400000)), 9), 'dd/MM/yyyy')}
                </TextTd>
                <TextTd>
                    <TextThView>Data final:</TextThView>
                    {lightFormat(addHours(new Date(Number(item.date_final || 1625270400000)), 9), 'dd/MM/yyyy')}
                </TextTd>
                <TextTd>
                    <TextThView>Formação:</TextThView>
                    {`${defineHourTimer}h ${defineMinTimer}min`}
                </TextTd>
                {/* <TextTd>
                    <TextThView>Exame:</TextThView>
                    {item?.engineering}
                </TextTd>
                <TextTd>
                    <TextThView>Status:</TextThView>
                    {item?.examination}
                </TextTd> */}
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditSimulate">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteSimulate">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Deletar simulado</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconSimulatesBlue} text="GERENCIAR SIMULADOS" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título dos simulados..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Novo Simulado</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>FASE</TextTh>
                            <TextTh>DATA INICIAL</TextTh>
                            <TextTh>DATA FINAL</TextTh>
                            <TextTh>DURAÇÃO</TextTh>
                            {/* <TextTh>FORMAÇÃO</TextTh>
                            <TextTh>EXAME</TextTh> */}
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                title,
                setTitle,
                timer,
                setTimer,
                selectExamination,
                setSelectExamination,
                selectEngineering,
                setSelectEngineering,
                dateInitial,
                setDateInitial,
                dateFinal,
                setDateFinal,
                allCategories,
                allFilters,
                setAllFilters,
                amountSelected,
                setAmountSelected,
                subjectSelected,
                setSubjectSelected,
                allExamination,
                allEngineering,
                archive,
                setArchive,
                gabaritoArchive,
                setGabaritoArchive,
                selectPhase,
                setSelectPhase,
                allSelectExamination,
                setAllSelectExamination,
                allSelectEngineering,
                setAllSelectEngineering,
            )}
        </Container>
    );
};

export default simulatesPage;
