/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    ContainerSelect,
    ContainerOption,
} from './ModalManageStyles';
import categories from '../../../../services/categories';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    nameCategories: string,
    setNameCategories: any,
    oneEngineering: string,
    setOneEngineering: (v: string) => void,
    allEngineering: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const [loading, setLoading] = useState(false);

    // methods
    async function CreateOrEditCategories() {
        try {
            const OnlyId = oneEngineering.split(',')[0];
            setLoading(true);
            if (!userEdit?.id) {
                const response = await categories.CreateNewCategories(
                    nameCategories,
                    OnlyId,
                    GetToken,
                );
                if (response.status !== 201) {
                    setLoading(false);
                    toast.error(response.message);
                } else {
                    setLoading(false);
                    handleClose(true);
                    toast.success(response.message);
                }
            } else {
                const response = await categories.EditOneCategories(
                    nameCategories,
                    OnlyId,
                    userEdit?.id,
                    GetToken,
                );
                if (response.status !== 200) {
                    setLoading(false);
                    toast.error(response.message);
                } else {
                    setLoading(false);
                    handleClose(true);
                    toast.success(response.message);
                }
            }
        } catch (error) {
            setLoading(false);
            handleClose(true);
        }
    }

    function ChangeSelect(txt: any) {
        const valueSelected = txt.target.value;
        if (valueSelected !== 'Selecionar uma formação') {
            setOneEngineering(valueSelected);
        }
    }

    function RenderOptionsQuestion() {
        return allEngineering?.map((item: any) => (
            <ContainerOption value={!userEdit?.id ? `${item.id},${item.name}` : item.id}>{item.name}</ContainerOption>
        ));
    }

    return (
        <>
            {loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Nova Categoria</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput>TÍTULO</TextInput>
                                    </DivInputText>
                                    <InputText
                                        placeholder="TÍTULO"
                                        value={nameCategories}
                                        type="text"
                                        onChange={(txt) => setNameCategories(txt.target.value)}
                                    />
                                </ContainerHome>
                            </Body>
                            <Body>
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput>FORMAÇÃO</TextInput>
                                    </DivInputText>
                                    <ContainerSelect onChange={ChangeSelect} defaultValue={!userEdit?.id_engineering ? '' : userEdit?.id_engineering}>
                                        <ContainerOption>Selecionar uma formação</ContainerOption>
                                        {RenderOptionsQuestion()}
                                    </ContainerSelect>
                                </ContainerHome>
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateOrEditCategories}>{!userEdit?.id ? 'Criar categoria' : 'Salvar categoria'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default modalManagePage;
