/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useState } from 'react';
import { Modal, Fade } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import lightFormat from 'date-fns/lightFormat';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone';
import { CircularProgressbar } from 'react-circular-progressbar';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    IconChecked,
    ContainerSelect,
    ContainerOption,
    ContainerMainCategories,
    ContainerCategoriesSelected,
    TxtCategoriesSelected,
    IconArrowCategories,
    ContainerRadius,
    ContainerCicleChecked,
    IconArrowCategoriesGoBack,
    TextFinish,
    ContainerAllInfoModal,
    ContainerCombo,
    TitleCombo,
    ContainerResponderTitle,
    AdjustCombo,
    TrashDelete,
    AdjustArrowBack,
    Flex,
    ContainerDropzone,
    ContainerProgressBar,
    TextProgressBar,
    TextDropzone,
    ContainerCards,
    ContainerInfoSpecifics,
    ImgStatistics,
    InfoCard,
    DivNull,
} from './ModalManageStyles';
import filtersRequest from '../../../services/filters';
import LoadingComponent from '../../../components/ModalLoading/LoadingComponent';

// images
import arrowTip from '../../../assets/images/arrowTip.png';
import favoriteLight from '../../../assets/images/favoriteLight.png';
import iconAddQuestions from '../../../assets/images/iconAddQuestions.png';
import checkCircleBlueSea from '../../../assets/images/checkCircleBlueSea.png';
import imageDeleteUser from '../../../assets/images/imageDeleteUser.png';
import tournament from '../../../services/tournament';
import constants from '../../../configs/constants';
import { AuthContext } from '../../../context/AuthContext';
import colors from '../../../styles/colors';

// components
import RankingComponent from './HeaderRanking/HeaderRankingComponent';

interface AmountInterface {
    name: string,
    id: number,
}

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    title: string,
    setTitle: any,
    queries: Array<{ idFilters: Array<number>, idSubjects: Array<number>, id: string }>,
    setQueries: any,
    qtdId: any,
    setQtdId: any,
    selectExamination: string,
    setSelectExamination: any,
    selectEngineering: string,
    setSelectEngineering: any,
    allCategories: Array<any>,
    allFilters: any,
    setAllFilters: any,
    amountSelected: AmountInterface[],
    setAmountSelected: any,
    subjectSelected: AmountInterface[],
    setSubjectSelected: any,
    allExamination: any,
    allEngineering: any,
    dateInitial: any,
    setDateInitial: any,
    dateFinal: any,
    setDateFinal: any,
    typeModal: string,
    allStatisticsRanking: Array<any>,
    widthActual: number,
) => {
    // context
    const { user } = useContext(AuthContext);

    // constants
    const baseURL = constants.baseUrl;
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    let intervalClear: any = null;
    const configs = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': GetToken || '',
        },
    };

    // useState
    const [amountSelectedFalse, setAmountSelectedFalse] = useState<AmountInterface[]>([]);
    const [subjectSelectedFalse, setSubjectSelectedFalse] = useState<AmountInterface[]>([]);
    const [indexCategories, setIndexCategories] = useState(1);
    const [idCategories, setIdCategories] = useState(0);
    const [loading, setLoading] = useState(false);
    const [messageDropzone, setMessageDropzone] = useState('Arraste e solte o arquivo zipado aqui ou clique');
    const [progressUpload, setProgressUpload] = useState(0);
    const [progressBulk, setProgressBulk] = useState(0);
    const [messageBulk, setMessageBulk] = useState('');
    const [fileDropzone, setFileDropzone] = useState<any>(null);
    const configUseDropzone = {
        accept: 'application/x-rar-compressed,application/octet-stream,application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
        onDrop: async (acceptedFiles: any) => {
            if (acceptedFiles.length === 1) {
                const newFileDropzone = acceptedFiles.map((file: any) => Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }));
                setFileDropzone(newFileDropzone[0]);
            } else {
                setMessageDropzone('Formato não suportado');
            }
        },
    };
    const { getRootProps, getInputProps } = useDropzone(configUseDropzone);

    // methods
    function RenderNumberSelected(idCategory: any) {
        const getOnlyIds = amountSelected.map((v: any) => v.id);
        const getOnlyAllFilters = allCategories.find((v: any) => v.id === idCategory);
        const filtersOnlyIds = (getOnlyAllFilters?.allFilters || []).filter((v: any) => getOnlyIds.includes(v));
        return filtersOnlyIds.length;
    }

    function Select(subtext: string, value: string, setValue: any) {
        function RenderOptionsQuestion() {
            if (subtext === 'Selecione um concurso') {
                return allExamination.map((item: any) => (
                    <ContainerOption value={item.id} key={item.id}>{item.name}</ContainerOption>
                ));
            }

            if (subtext === 'Todas as formações') {
                return allEngineering.map((item: any) => (
                    <ContainerOption value={item.id} key={item.id}>{item.name}</ContainerOption>
                ));
            }

            return null;
        }

        function ChangeSelect(txt: any) {
            const valueSelected = txt.target.value;
            if (subtext === 'Selecione um concurso') {
                const newValue = valueSelected !== 'Selecione um concurso' ? valueSelected : '';
                setValue(newValue);
            }
            if (subtext === 'Todas as formações') {
                const newValue = valueSelected !== 'Todas as formações' ? valueSelected : '';
                setValue(newValue);
            }
        }

        return (
            <ContainerSelect onChange={ChangeSelect} defaultValue={value || subtext}>
                <ContainerOption>{subtext}</ContainerOption>
                {RenderOptionsQuestion()}
            </ContainerSelect>
        );
    }

    const handleClick = (v: any, valueSubject?: any) => {
        if ((amountSelectedFalse || []).filter((item) => item.id === v.id).length) {
            const removeItem = (amountSelectedFalse || []).filter((item) => item.id !== v.id);
            setAmountSelectedFalse(removeItem);
            if (valueSubject) {
                const allIdsSubject = valueSubject.map((item: any) => item.id);
                const removeItemSubject = subjectSelectedFalse.filter((item) => !allIdsSubject.includes(item.id));
                setSubjectSelectedFalse(removeItemSubject);
            }
        } else {
            const newAmountSelectedFalse = [...amountSelectedFalse || [], { name: v.name, id: v.id }];
            setAmountSelectedFalse(newAmountSelectedFalse);
        }
    };

    const handleClickSubject = (v: any, valueFilter: any) => {
        if (subjectSelectedFalse.filter((item) => item.id === v.id).length) {
            const removeItem = subjectSelectedFalse.filter((item) => item.id !== v.id);
            setSubjectSelectedFalse(removeItem);
            if (!removeItem.length) {
                handleClick(valueFilter);
            }
        } else {
            const newAmountSelectedFalse = [...subjectSelectedFalse || [], { name: v.name, id: v.id }];
            setSubjectSelectedFalse(newAmountSelectedFalse);
        }
    };

    const resetCategories = () => {
        setIndexCategories(1);
        setAmountSelectedFalse([]);
        setSubjectSelectedFalse([]);
    };

    const concludedCategories = () => {
        setIndexCategories(1);
        setAmountSelected(amountSelectedFalse);
        setSubjectSelected(subjectSelectedFalse);
        setAmountSelectedFalse([]);
        setSubjectSelectedFalse([]);
    };

    const renderOnlyCategories = (valueCategories: any) => {
        const onChangeSelectedFilter = async () => {
            const getAllFilters = await filtersRequest.GetSelectedFilters(valueCategories.id, localStorage.getItem('@Engequest:token') || '');
            if (getAllFilters.result?.length) {
                setAllFilters(getAllFilters.result);
                setIndexCategories(2);
                setIdCategories(valueCategories.id);
                setAmountSelectedFalse(amountSelected);
                setSubjectSelectedFalse(subjectSelected);
            } else {
                alert('Não existem filtros para essa categoria');
            }
        };

        if (indexCategories !== 1) {
            return null;
        }
        return (
            <Fade in={indexCategories === 1}>
                <ContainerCategoriesSelected onClick={onChangeSelectedFilter}>
                    <TxtCategoriesSelected>{valueCategories.name}</TxtCategoriesSelected>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ContainerRadius backgroundColor={RenderNumberSelected(valueCategories.id)}>
                            <text style={{ color: 'white', fontSize: 12 }}>{RenderNumberSelected(valueCategories.id)}</text>
                        </ContainerRadius>
                        <IconArrowCategories src={arrowTip} />
                    </div>
                </ContainerCategoriesSelected>
            </Fade>
        );
    };

    const renderSubjects = (valueFilters: any) => {
        const existSubject = amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length > 0;
        if (!existSubject || !valueFilters.subjects.length) {
            return null;
        }
        return (
            valueFilters.subjects.map((valueSubjects: any) => {
                const onChangeSelectedSubjects = () => {
                    handleClickSubject(valueSubjects, valueFilters);
                };
                return (
                    <Fade in={indexCategories !== 1} key={valueSubjects.id}>
                        <ContainerCategoriesSelected
                            onClick={onChangeSelectedSubjects}
                            marginLeft="30px"
                        >
                            <Flex>
                                {subjectSelectedFalse.filter((item) => item.id === valueSubjects.id).length ? (
                                    <IconChecked src={checkCircleBlueSea} />
                                ) : (
                                    <ContainerCicleChecked />
                                )}
                                <TxtCategoriesSelected>{valueSubjects.name}</TxtCategoriesSelected>
                            </Flex>
                        </ContainerCategoriesSelected>
                    </Fade>
                );
            })
        );
    };

    const renderFilters = (valueCategories: any) => {
        if (indexCategories === 1 || valueCategories.id !== idCategories || !allFilters.length) {
            return null;
        }

        return (
            allFilters.map((valueFilters: any) => {
                const onChangeSelectedFilter = () => {
                    if (valueFilters.subjects.length) {
                        setSubjectSelectedFalse([...subjectSelectedFalse || [], ...valueFilters.subjects]);
                        handleClick(valueFilters, valueFilters.subjects);
                    } else {
                        handleClick(valueFilters);
                    }
                };
                return (
                    <>
                        <Fade in={indexCategories !== 1} key={valueFilters.id}>
                            <ContainerCategoriesSelected onClick={onChangeSelectedFilter}>
                                <Flex>
                                    {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length ? (
                                        <IconChecked src={checkCircleBlueSea} />
                                    ) : (
                                        <ContainerCicleChecked />
                                    )}
                                    <TxtCategoriesSelected>{valueFilters.name}</TxtCategoriesSelected>
                                </Flex>
                            </ContainerCategoriesSelected>
                        </Fade>
                        {renderSubjects(valueFilters)}
                    </>
                );
            })
        );
    };

    const renderCategories = (value: any) => value.map((valueCategories: any) => (
        <Flex flexDirection="column">
            {renderOnlyCategories(valueCategories)}
            {renderFilters(valueCategories)}
        </Flex>
    ));

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        if (text === 'EXAME' || text === 'FORMAÇÃO') {
            return Select(subtext, value, setValue);
        }

        if (text === 'CATEGORIAS') {
            return (
                <ContainerMainCategories>
                    {indexCategories === 1 ? null : (
                        <AdjustArrowBack>
                            <IconArrowCategoriesGoBack
                                src={arrowTip}
                                onClick={resetCategories}
                            />
                            <TextFinish onClick={concludedCategories}>
                                Concluído
                            </TextFinish>
                        </AdjustArrowBack>
                    )}
                    {renderCategories(value)}
                </ContainerMainCategories>
            );
        }

        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            setValue(txt.target.value);
        };

        const onChangeDates = (txt: any) => {
            const onlyNumber = txt.target.value.replace(/[^0-9]/g, '');
            if (text === 'ANO') {
                if (txt.target.value.length <= 4) {
                    setValue(onlyNumber);
                }
            } else {
                setValue(txt.target.value);
            }
        };

        if (['DATA INICIAL', 'DATA FINAL (OPCIONAL)'].includes(text)) {
            return (
                <InputText
                    placeholder={subtext}
                    value={value}
                    type={text === 'DATA INICIAL' || text === 'DATA FINAL (OPCIONAL)' ? 'datetime-local' : 'text'}
                    onChange={onChangeDates}
                />
            );
        }

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type="text"
                onChange={onChangeText}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue?: any) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput adjustLabel={text === 'CATEGORIAS'}>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    // apis
    async function BulkUpload(progress = -1, message = '') {
        let url = `${baseURL}/questions/bulkProgress`;
        if (progress >= 0) {
            url += `?progress=${progress}&message=${message}&idManager=${user.id}`;
        }
        const response = await axios.get(url, configs);
        if (response.data?.result) {
            setMessageBulk(response.data.result?.message || '');
            setProgressBulk(response.data.result?.progress || 0);
            if (response.data.result?.progress === 100) {
                clearInterval(intervalClear);
            }
        }
    }

    function getProgressBulk() {
        intervalClear = setInterval(async () => {
            BulkUpload();
        }, 2000);
    }

    async function SaveTest() {
        const formatDataInitial = lightFormat(new Date(dateInitial), 'yyyy-MM-dd HH:mm:ss');
        const formatDataFinal = dateFinal && new Date(dateFinal) ? lightFormat(new Date(dateFinal), 'yyyy-MM-dd HH:mm:ss') : null;
        if (!userEdit?.id) {
            const create = await tournament.CreateNewTournament(
                title,
                formatDataInitial,
                formatDataFinal,
                queries,
                Number(selectExamination),
                Number(selectEngineering),
                GetToken,
            );
            if (!fileDropzone) {
                clearInterval(intervalClear);
                setMessageBulk('');
                setProgressBulk(0);
                setProgressUpload(0);
                toast.success('Desafio diário criado com sucesso!');
                handleClose(true);
                return;
            }
            if (create?.status === 201) {
                try {
                    await BulkUpload(0);
                    const configsFinal = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'x-access-token': GetToken,
                        },
                        onUploadProgress: (event: any) => {
                            const percentCompleted = Math.round((event.loaded * 100) / event.total);
                            setProgressUpload(percentCompleted);
                            setMessageBulk('Enviando o arquivo para nossos servidores.');
                        },
                    };
                    const bodyUpload = new FormData();
                    bodyUpload.append('file', fileDropzone);
                    const responseUpload = await axios.post(`${baseURL}/uploads`, bodyUpload, configsFinal);
                    const file = responseUpload.data.result;

                    getProgressBulk();
                    const bodyBulk = {
                        fileUrl: file,
                        idManager: Number(user.id),
                    };
                    const responseBulk = await axios.post(`${baseURL}/questions/bulkUpload?idDailyChallenge=${create.result[0].id}`, bodyBulk, configs);
                    if (responseBulk.status === 201) {
                        clearInterval(intervalClear);
                        setMessageBulk('');
                        setProgressBulk(0);
                        setProgressUpload(0);
                        toast.success('Desafio diário criado com sucesso!');
                        handleClose(true);
                    }
                } catch (error: any) {
                    setMessageBulk('Ocorreu um erro ao fazer o upload. Garanta que todos os dados estão corretos e tente novamente.');
                    setProgressBulk(0);
                    setProgressUpload(0);
                    clearInterval(intervalClear);
                    await BulkUpload(100);
                }
            } else {
                toast.error(create?.message || 'Ocorreu um erro ao criar seu desafio diário.');
            }
        } else {
            // const edit = await tournament.EditOneTournament(
            //     title,
            //     formatDataInitial,
            //     formatDataFinal,
            //     // queries,
            //     Number(selectExamination),
            //     Number(selectEngineering),
            //     userEdit?.id,
            //     GetToken,
            // );
            // if (edit?.status === 200) {
            //     setLoading(false);
            //     handleClose(true);
            //     toast.success('Desafio diário editado com sucesso!');
            // } else {
            //     toast.error(edit?.message || 'Ocorreu um erro ao editar seu desafio diário.');
            // }
        }
        setQueries([]);
    }

    const AddCategories = () => {
        const oneCombo = {
            id: qtdId,
            idFilters: (amountSelected || []).map((v) => v.id) || [],
            idSubjects: (subjectSelected || []).map((v) => v.id) || [],
        };
        setQtdId(qtdId + 1);
        setQueries([...queries, oneCombo]);
        setAmountSelected([]);
        setSubjectSelected([]);
    };

    const renderQueries = () => {
        if (!queries?.length) {
            return null;
        }
        return queries.map((v) => {
            const DeleteCombo = () => {
                const newCombo = queries.filter((el) => el.id !== v.id);
                setQueries(newCombo);
            };
            return (
                <AdjustCombo key={v.id}>
                    <ContainerCombo>
                        <TitleCombo>{`Combo ${v.id}`}</TitleCombo>
                        <ContainerResponderTitle>{v.idFilters?.length}</ContainerResponderTitle>
                    </ContainerCombo>
                    <TrashDelete onClick={DeleteCombo} src={imageDeleteUser} />
                </AdjustCombo>
            );
        });
    };

    const renderPage = () => {
        if (loading) {
            return (
                <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." />
            );
        }
        return (
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <ModalContainer>
                    <Container>
                        <Header>
                            <ContainerHeader>
                                <ContainerNewCollaborator>
                                    <IconBlue src={iconAddQuestions} />
                                    <TextNewCollaborator>Adicionar Novo Desafio</TextNewCollaborator>
                                </ContainerNewCollaborator>
                                <DivLine />
                            </ContainerHeader>
                        </Header>
                        <ContainerAllInfoModal>
                            <Body>
                                {DivInput('TÍTULO', 'Título', title, setTitle)}
                                {DivInput('DATA INICIAL', 'Data inicial do simulado', dateInitial, setDateInitial)}
                                {DivInput('DATA FINAL (OPCIONAL)', 'Data final do simulado', dateFinal, setDateFinal)}
                                {DivInput('EXAME', 'Selecione um concurso', selectExamination, setSelectExamination)}
                                {DivInput('FORMAÇÃO', 'Todas as formações', selectEngineering, setSelectEngineering)}
                                {DivInput('CATEGORIAS', 'Categorias', allCategories)}
                                <ButtonSave onClick={AddCategories}>Adicionar Combo</ButtonSave>
                            </Body>
                            <Body>
                                {renderQueries()}
                            </Body>
                        </ContainerAllInfoModal>
                        <ContainerDropzone {...getRootProps()}>
                            <input {...getInputProps()} />
                            {progressUpload > 0 ? (
                                <ContainerProgressBar>
                                    <CircularProgressbar
                                        value={(progressUpload + progressBulk) / 2}
                                        text={`${((progressUpload + progressBulk) / 2).toFixed(0)}%`}
                                    />
                                    <TextProgressBar>{messageBulk}</TextProgressBar>
                                </ContainerProgressBar>
                            ) : (
                                <TextDropzone>{`${messageBulk || fileDropzone?.path || messageDropzone}`}</TextDropzone>
                            )}
                        </ContainerDropzone>
                        <Footer>
                            <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                            <ButtonSave onClick={SaveTest}>{!userEdit?.id ? 'Criar Desafio' : 'Salvar Desafio'}</ButtonSave>
                        </Footer>
                    </Container>
                </ModalContainer>
            </Modal>
        );
    };

    if (typeModal === 'RankingInfo') {
        return (

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <ModalContainer>
                    <ContainerCards backgroundColor={colors.basic.white} marginTop="20px" borderRadius="30px">
                        {allStatisticsRanking.length ? (
                            <ContainerInfoSpecifics padding={widthActual > 950 ? '10px' : '10px 10px 0px 10px'}>
                                <ImgStatistics src={favoriteLight} />
                                <InfoCard>—</InfoCard>
                                <InfoCard>RANKING</InfoCard>
                                <RankingComponent type="fristFive" widthActual={widthActual} allStatisticsRanking={allStatisticsRanking} />
                            </ContainerInfoSpecifics>
                        ) : (
                            <ContainerInfoSpecifics padding={widthActual > 950 ? '10px' : '10px 10px 0px 10px'}>
                                <InfoCard>Não há nenhum usuário no ranking com os filtros atuais.</InfoCard>
                            </ContainerInfoSpecifics>
                        )}
                        {allStatisticsRanking.length > 5 ? (
                            <ContainerInfoSpecifics marginTop={widthActual > 950 ? '20px' : '0px'} padding={widthActual > 950 ? '10px' : '0px 10px 10px 10px'}>
                                {widthActual > 950 ? (
                                    <>
                                        <DivNull />
                                        <InfoCard color={colors.basic.white}>—</InfoCard>
                                        <InfoCard color={colors.basic.white}>RANKING</InfoCard>
                                    </>
                                ) : null}
                                <RankingComponent type="lastFive" widthActual={widthActual} allStatisticsRanking={allStatisticsRanking} />
                            </ContainerInfoSpecifics>
                        ) : null}
                        <Footer>
                            <ButtonExit onClick={handleClose}>Fechar</ButtonExit>
                        </Footer>
                    </ContainerCards>
                </ModalContainer>
            </Modal>
        );
    }

    // main
    return renderPage();
};

export default modalManagePage;
