// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function ActivePayment(
    typePlan: string,
    cpf: string,
    cardName: string,
    cardNumber: string,
    cardBrand: string,
    cardCvv: string,
    cardExpirationMonth: string,
    cardExpirationYear: string,
    idUser: string,
    token: string,
) {
    try {
        const data = {
            typePlan,
            cpf,
            cardName,
            cardNumber,
            cardBrand,
            cardCvv,
            cardExpirationMonth,
            cardExpirationYear,
        };
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const method = 'POST';
        const configs = {
            body,
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/plans/payment/credit?idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CancelPlan(
    idUser: string,
    token: string,
) {
    try {
        const headers = {
            'x-access-token': token,
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/plans/cancel?idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    ActivePayment,
    CancelPlan,
};
