/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    ContainerOption,
    InputTextBox,
} from './ModalManagerStyles';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import textSupport from '../../../../services/textSupport';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    titleTextSupport: string,
    setTitleTextSupport: (v: string) => void,
    handleOnSearchIdEssay: any,
    handleOnSelectedIdEssay: any,
    allIdEssay: Array<any>,
    searchIdEssayTextSupport: string,
    selectedIdEssayTextSupport: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const objectInfoManager = localStorage.getItem('@Engequest:manager') || '';
    const [loading, setLoading] = useState(false);

    // methods
    async function CreateOrEditEssay() {
        try {
            setLoading(true);
            const infoUser = objectInfoManager ? JSON.parse(objectInfoManager) : '';
            if (!userEdit?.id) {
                const response = await textSupport.CreateNewTextSupport(
                    titleTextSupport,
                    infoUser?.id || '',
                    selectedIdEssayTextSupport?.id,
                    GetToken,
                );
                if (response.status !== 201) {
                    setLoading(false);
                    toast.error(response.message);
                } else {
                    setLoading(false);
                    handleClose(true);
                    toast.success(response.message);
                }
            } else {
                const response = await textSupport.EditOneTextSupport(
                    titleTextSupport,
                    infoUser?.id || '',
                    selectedIdEssayTextSupport?.id,
                    userEdit?.id,
                    GetToken,
                );
                if (response.status !== 200) {
                    setLoading(false);
                    toast.error(response.message);
                } else {
                    setLoading(false);
                    handleClose(true);
                    toast.success(response.message);
                }
            }
        } catch (error) {
            setLoading(false);
            handleClose(true);
        }
    }

    return (
        <>
            {loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Novo Texto de Apoio</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput>DESCRIÇÃO</TextInput>
                                    </DivInputText>
                                    <InputTextBox
                                        placeholder="DESCRIÇÃO"
                                        value={titleTextSupport}
                                        onChange={(txt: any) => setTitleTextSupport(txt.target.value)}
                                    />
                                </ContainerHome>
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput>REDAÇÃO</TextInput>
                                    </DivInputText>
                                    <div style={{ width: '100%' }}>
                                        <ReactSearchAutocomplete
                                            items={allIdEssay}
                                            onSearch={handleOnSearchIdEssay}
                                            onSelect={handleOnSelectedIdEssay}
                                            inputSearchString={searchIdEssayTextSupport}
                                            showNoResultsText="Nenhum resultado encontrado"
                                            formatResult={(item: any) => (
                                                <ContainerOption>{`${item?.id} - ${item?.name}`}</ContainerOption>
                                            )}
                                        />
                                    </div>
                                </ContainerHome>
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateOrEditEssay}>{!userEdit?.id ? 'Criar texto de apoio' : 'Salvar texto de apoio'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default modalManagePage;
