/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    ContainerManage,
    ContainerSelect,
    ContainerOption,
} from './ModalManageStyles';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import subjects from '../../../../services/subjects';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    title: string,
    setTitle: any,
    selectFilter: string,
    setSelectFilter: any,
    allFilters: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const [loading, setLoading] = useState(false);

    // methods
    function Select(subtext: string, value: string, setValue: any) {
        function RenderOptionsQuestion() {
            if (subtext === 'Selecione um filtro') {
                return allFilters.map((item: any) => (
                    <ContainerOption value={item.id}>{item.name}</ContainerOption>
                ));
            }

            return null;
        }

        function ChangeSelect(txt: any) {
            const valueSelected = txt.target.value;
            if (subtext === 'Selecione um filtro' && valueSelected !== 'Selecione um filtro') {
                setValue(valueSelected);
            }
        }

        return (
            <ContainerSelect onChange={ChangeSelect} defaultValue={value || subtext}>
                <ContainerOption>{subtext}</ContainerOption>
                {RenderOptionsQuestion()}
            </ContainerSelect>
        );
    }

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        if (text === 'FILTRO') {
            return Select(subtext, value, setValue);
        }

        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            setValue(txt.target.value);
        };

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type="text"
                onChange={onChangeText}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue: any) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    async function CreateOrEditSubject() {
        setLoading(true);
        if (!userEdit?.id) {
            await subjects.CreateNewSubjects(title, Number(selectFilter), GetToken)
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Assunto criada com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        } else {
            await subjects.EditOneSubjects(title, userEdit?.id, Number(selectFilter), GetToken)
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Assunto editado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    }

    // main
    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Novo Assunto</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                {DivInput('TÍTULO', 'Título', title, setTitle)}
                            </Body>
                            <ContainerManage>
                                {DivInput('FILTRO', 'Selecione um filtro', selectFilter, setSelectFilter)}
                            </ContainerManage>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateOrEditSubject}>{!userEdit?.id ? 'Criar assunto' : 'Salvar assunto'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default modalManagePage;
