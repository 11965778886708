/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { lightFormat } from 'date-fns';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManager/ModalManagerPage';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import iconCategoriBlue from '../../assets/images/iconCategoriBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    ContainerOption,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './textSupportStyles';
import colors from '../../styles/colors';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import textSupport from '../../services/textSupport';
import essay from '../../services/essay';

const textSupportPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [titleTextSupport, setTitleTextSupport] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchIdEssayTextSupport, setSearchIdEssayTextSupport] = useState('');
    const [selectedIdEssayTextSupport, setSelectedIdEssayTextSupport] = useState<any>({});
    const [allEssay, setAllEssay] = useState([]);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setTitleTextSupport(infoUser.description);
            setSearchIdEssayTextSupport(infoUser?.description_theme);
            setSelectedIdEssayTextSupport({ name: infoUser?.description_theme, id: infoUser?.id_theme });
            setUserEdit(infoUser);
        } else {
            setSelectedIdEssayTextSupport({});
            setSearchIdEssayTextSupport('');
            setUserEdit(null);
        }
    };

    const handleOnSearchIdEssay = (txt: string) => {
        setSearchIdEssayTextSupport(txt);
    };

    const handleOnSelectedIdEssay = (item: any) => {
        setSelectedIdEssayTextSupport(item);
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allTextSupportNotPage = await textSupport.GetAllTextSupport(searchSelected || '', 0, 0, selectedIdEssayTextSupport?.id || 0, GetToken);
        const formatPagination = String((allTextSupportNotPage?.result || []).length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial(activeRefresh?: boolean) {
        const allTextSupport = await textSupport.GetAllTextSupport('', 1, limit, typeof activeRefresh === 'boolean' ? 0 : selectedIdEssayTextSupport?.id || 0, GetToken);
        setDataFinal(allTextSupport.result);
        RenderQuantityPagination(search);
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteTextSupport') {
                const validation = await textSupport.DeleteOneTextSupport(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Texto de apoio deletado com sucesso!');
                }
            }
            if (valueSelected === 'EditTextSupport') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.description}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {item?.created_at ? lightFormat(new Date(item.created_at), 'dd/MM/yyyy HH:mm') : ''}
                </TextTd>
                <TextTd>
                    <TextThView>Atualizado em:</TextThView>
                    {item?.updated_at ? lightFormat(new Date(item.updated_at), 'dd/MM/yyyy HH:mm') : ''}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditTextSupport">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteTextSupport">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir texto de apoio</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        setSearchIdEssayTextSupport('');
        setSelectedIdEssayTextSupport({});
        if (activeRefresh === true) {
            ActionInitial(activeRefresh);
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allTextSupport = await textSupport.GetAllTextSupport(search, pageSelected, limit, selectedIdEssayTextSupport?.id || 0, GetToken);
        setDataFinal(allTextSupport.result);
        setPageActual(pageSelected);
    }

    async function ActionSearch() {
        setLoading(true);
        const allTextSupport = await textSupport.GetAllTextSupport(search, 1, limit, selectedIdEssayTextSupport?.id || 0, GetToken);
        setDataFinal(allTextSupport.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    const GetEssay = async () => {
        const GetAllFilters = await essay.GetAllEssaySearch(searchIdEssayTextSupport, 1, limit, localStorage.getItem('@Engequest:token') || '');
        setAllEssay(GetAllFilters?.result || []);
    };

    // useEffect
    useEffect(() => {
        if (!open) {
            ActionInitial();
        }
    }, [selectedIdEssayTextSupport]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            GetEssay();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchIdEssayTextSupport]);

    return (
        <Container>
            <Header>
                {loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconCategoriBlue} text="GERENCIAR TEXTOS DE APOIO DA REDAÇÃO" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título das redações..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <div style={{ width: 200 }}>
                    <ReactSearchAutocomplete
                        items={allEssay}
                        onSearch={handleOnSearchIdEssay}
                        onSelect={handleOnSelectedIdEssay}
                        onClear={() => {
                            setSearchIdEssayTextSupport('');
                            setSelectedIdEssayTextSupport({});
                        }}
                        showNoResultsText="Nenhum resultado encontrado"
                        formatResult={(item: any) => (
                            <ContainerOption>{`${item?.id} - ${item?.name}`}</ContainerOption>
                        )}
                    />
                </div>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Novo Texto de Apoio</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>DESCRIÇÃO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>ATUALIZADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                titleTextSupport,
                setTitleTextSupport,
                handleOnSearchIdEssay,
                handleOnSelectedIdEssay,
                allEssay,
                searchIdEssayTextSupport,
                selectedIdEssayTextSupport,
            )}
        </Container>
    );
};

export default textSupportPage;
