// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllUsers(
    searchTxt?: string,
    typeUser?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/users?searchTxt=${searchTxt}&typeUser=${typeUser}&page=${page}&limit=${limit || ''}` : `${baseURL}/users?searchTxt=${searchTxt}&typeUser=${typeUser}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneUser(
    typeDisabled: string,
    idUser: string,
    token: string,
) {
    try {
        const data = {
            typeDisabled,
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/users/disabled?idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditUserAdm(
    typeDisabled: string,
    idUser: string,
    token: string,
) {
    try {
        const body = new FormData();
        // os bodys
        const method = 'PUT';
        const headers = {
            'x-access-token': token,
            'Content-Type': 'multipart/form-data',
        };
        const configs = {
            method,
            headers,
            body,
        };
        const response = await fetch(`${baseURL}/users?idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    EditOneUser,
    EditUserAdm,
    GetAllUsers,
};
