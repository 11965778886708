/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import { getTime } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    ModalContainer,
    Header,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    Body,
    ContainerNewCollaborator,
} from './ModalPageStyles';
import managers from '../../../services/managers';
import LoadingComponent from '../../../components/ModalLoading/LoadingComponent';

// images
import iconBlueAdd from '../../../assets/images/iconBlueAdd.png';

const ModalPage = (
    open: boolean,
    handleClose: any,
    name: string,
    setName: (v: string) => void,
    email: string,
    setEmail: (v: string) => void,
    password: string,
    setPassword: (v: string) => void,
    confirmPassword: string,
    setConfirmPassword: (v: string) => void,
    identifier: string,
    setIdentifier: (v: string) => void,
    birthDate: string,
    setBirthDate: (v: string) => void,
    telephone: string,
    setTelephone: (v: string) => void,
    idManager: string,
) => {
    // constants
    const [loading, setLoading] = useState(false);

    // methods
    async function CreateNewManager() {
        setLoading(true);
        const [day, month, year] = birthDate.split('/');
        const result = getTime(new Date(Number(year), Number(month) - 1, Number(day), 12));
        if (!idManager) {
            managers.CreateNewManager(name, email, password, confirmPassword, identifier, result, telephone, localStorage.getItem('@Engequest:token') || '')
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Colaborador criado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                    handleClose();
                });
        } else {
            managers.EditOneManager(idManager, '', name, email, password, confirmPassword, identifier, result, telephone, localStorage.getItem('@Engequest:token') || '')
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Colaborador editado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    }

    // renders
    function DivInput(text: string, subtext: string, value: string, setValue: any, mask?: 'cpf' | 'data' | 'telefone') {
        const onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
            if (mask === 'cpf') {
                e.currentTarget.maxLength = 14;
                const cpfFormatted = e.currentTarget.value.replace(/\D/g, '')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1-$2');
                setValue(cpfFormatted);
            }
            if (mask === 'data') {
                e.currentTarget.maxLength = 10;
                const dataFormatted = e.currentTarget.value.replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{4})(\d)/, '$1/$2');
                setValue(dataFormatted);
            }
            if (mask === 'telefone') {
                const MaskPhone = (v: any) => {
                    let valueInitial = v.replace(/\D/g, '').replace(/^0/, '');
                    if (valueInitial.length > 11) {
                        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
                    } else if (valueInitial.length > 7) {
                        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
                    } else if (valueInitial.length > 2) {
                        valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
                    } else if (v.trim() !== '') {
                        valueInitial = valueInitial.replace(/^(\d*)/, '($1');
                    }
                    return valueInitial;
                };
                const formatTelephone = MaskPhone(e.currentTarget.value);
                setValue(formatTelephone);
            }
            if (!mask) {
                setValue(e.currentTarget.value);
            }
        };

        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                <InputText placeholder={subtext} value={value} onChange={onChangeInput} type={['SENHA', 'CONFIRMAR SENHA'].includes(text) ? 'password' : 'email'} />
            </ContainerHome>
        );
    }
    // main
    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconBlueAdd} />
                                        <TextNewCollaborator>Novo Colaborador</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                {DivInput('NOME', 'Nome', name, setName)}
                                {DivInput('EMAIL', 'Email', email, setEmail)}
                                {DivInput('TELEFONE', 'Telefone', telephone, setTelephone, 'telefone')}
                                {DivInput('CPF', 'Identidade', identifier, setIdentifier, 'cpf')}
                                {DivInput('DATA DE NASCIMENTO', 'Data de Nascimento', birthDate, setBirthDate, 'data')}
                                {DivInput('SENHA', 'Senha', password, setPassword)}
                                {DivInput('CONFIRMAR SENHA', 'Confirmar Senha', confirmPassword, setConfirmPassword)}
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateNewManager}>{!idManager ? 'Criar' : 'Editar'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default ModalPage;
