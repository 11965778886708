// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllManagers(
    searchTxt?: string,
    typeManager?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/managers?searchTxt=${searchTxt}&typeManager=${typeManager}&page=${page}&limit=${limit || ''}` : `${baseURL}/managers?searchTxt=${searchTxt}&typeManager=${typeManager}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditDisabledOrDeleteManager(
    typeDisabled: string,
    idManager: string,
    token: string,
) {
    try {
        const data = {
            typeDisabled,
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/managers/disabled?idManager=${idManager}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewManager(
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    cpf: string,
    birthDate: number,
    telephone: string,
    token: string,
) {
    try {
        const data = {
            name,
            email,
            password,
            confirmPassword,
            cpf: cpf.replace(/\D/g, ''),
            birthDate,
            telephone: telephone.replace(/\D/g, ''),
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/managers`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneManager(
    idManager?: string | number,
    photo?: string,
    name?: string,
    email?: string,
    password?: string,
    confirmPassword?: string,
    cpf?: string,
    birthDate?: any,
    telephone?: string,
    token?: string,
) {
    try {
        interface DataInterfaces {
            name?: string,
            email?: string,
            cpf?: string,
            birthDate?: string,
            telephone?: string,
            photo?: string,
            password?: string,
            confirmPassword?: string,
        }
        let data: DataInterfaces = {
            name,
            email,
            cpf,
            birthDate,
            telephone,
        };
        if (photo) {
            data = {
                ...data,
                photo: photo || 'test',
            };
        }
        if (password && confirmPassword) {
            data = {
                ...data,
                password,
                confirmPassword,
            };
        }
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const configs = {
            body,
            method,
            headers,
        };
        const response = await fetch(`${baseURL}/managers?idManager=${idManager}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    CreateNewManager,
    GetAllManagers,
    EditDisabledOrDeleteManager,
    EditOneManager,
};
