/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
// libraries
import React, { useEffect, useState, useContext } from 'react';
import { Drawer } from '@material-ui/core';
import FlatList from 'flatlist-react';
import json from '../../../package.json';

// js
import {
    Line,
    Main,
    Aside,
    Header,
    Logout,
    Footer,
    ImgExit,
    ImgArrow,
    NameUser,
    ListItem,
    HomeImage,
    Container,
    TextFooter,
    ButtonEnter,
    ImgEngequest,
    DrawerOption,
    ContainerExit,
    ContainerImage,
} from './DrawerNavigationStyles';

// contexts
import { AuthContext } from '../../context/AuthContext';

// components (navigation)
import ToNavigation from '../navigation';

// Images
import drawerEngequest from '../../assets/images/drawerEngequest.png';
import iconLogout from '../../assets/images/iconLogout.png';
import iconUserDrawer from '../../assets/images/iconUserDrawer.png';
import arrow from '../../assets/images/arrow.png';
import iconContributors from '../../assets/images/iconContributors.png';
import iconQuestions from '../../assets/images/iconQuestions.png';
import menuHamburguer from '../../assets/images/menuHamburguer.png';
import iconPlans from '../../assets/images/iconPlans.png';
import iconUser from '../../assets/images/iconUser.png';
import iconExamination from '../../assets/images/iconExamination.png';
import iconEngineering from '../../assets/images/iconEngineering.png';
import iconTests from '../../assets/images/iconTests.png';
import iconSimulates from '../../assets/images/iconSimulates.png';
import iconBookSuggested from '../../assets/images/iconBookSuggested.png';
import iconCategori from '../../assets/images/iconCategori.png';
import iconTimeline from '../../assets/images/iconTimeline.png';
import iconFilter from '../../assets/images/iconFilter.png';
import iconSubject from '../../assets/images/iconSubject.png';
import iconNotification from '../../assets/images/iconNotification.png';
import iconBook from '../../assets/images/iconBook.png';
import iconFaq from '../../assets/images/iconFaq.png';
import iconReports from '../../assets/images/iconReports.png';
import iconColaborador from '../../assets/images/iconColaborador.png';

const DrawerNavigation: React.FC = () => {
    // constants
    const AllOptionsDrawer = [
        'Usuários',
        'Colaboradores',
        'Gerenciar Questões',
        'Gerenciar Planos',
        'Gerenciar Exames',
        'Gerenciar Formações',
        'Gerenciar Provas',
        'Gerenciar Simulados',
        'Gerenciar Cadernos Sugeridos',
        'Gerenciar Categorias',
        'Gerenciar Filtros',
        'Gerenciar Assuntos',
        'Gerenciar Estatísticas',
        'Gerenciar Timeline',
        'Gerenciar Notificações',
        'Gerenciar Livros',
        'Gerenciar FAQ',
        'Gerenciar Reports',
        'Gerenciar Desafios Diários',
        'Gerenciar Comentários',
        'Gerenciar Temas da Redação',
        'Gerenciar Textos de Apoio da Redação',
    ];

    // states
    const [openDrawerLeft, setOpenDrawerLeft] = useState(false);
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // useCotext
    const { logout, refreshToken, user } = useContext(AuthContext);

    const CloseDrawer = () => {
        setOpenDrawerLeft(false);
    };

    const OpenDrawer = () => {
        setOpenDrawerLeft(true);
    };

    const TransitionScreen = (index: number) => {
        if (index === 0) {
            setPathRoute('/users');
        }
        if (index === 1) {
            setPathRoute('/contributors');
        }
        if (index === 2) {
            setPathRoute('/questions');
        }
        if (index === 3) {
            setPathRoute('/plans');
        }
        if (index === 4) {
            setPathRoute('/examination');
        }
        if (index === 5) {
            setPathRoute('/engineering');
        }
        if (index === 6) {
            setPathRoute('/tests');
        }
        if (index === 7) {
            setPathRoute('/simulates');
        }
        if (index === 8) {
            setPathRoute('/bookSuggested');
        }
        if (index === 9) {
            setPathRoute('/categories');
        }
        if (index === 10) {
            setPathRoute('/filters');
        }
        if (index === 11) {
            setPathRoute('/subject');
        }
        if (index === 12) {
            setPathRoute('/statistics');
        }
        if (index === 13) {
            setPathRoute('/emitNotification');
        }
        if (index === 14) {
            setPathRoute('/emitPushNotification');
        }
        if (index === 15) {
            setPathRoute('/books');
        }
        if (index === 16) {
            setPathRoute('/faq');
        }
        if (index === 17) {
            setPathRoute('/reports');
        }
        if (index === 18) {
            setPathRoute('/tournament');
        }
        if (index === 19) {
            setPathRoute('/comments');
        }
        if (index === 20) {
            setPathRoute('/essay');
        }
        if (index === 21) {
            setPathRoute('/textSupport');
        }
        setRedirect(true);
        setOpenDrawerLeft(false);
    };

    const RenderImage = (index: any) => {
        if (index === 0) {
            return (iconUser);
        }
        if (index === 1) {
            return (iconColaborador);
        }
        if (index === 2) {
            return (iconQuestions);
        }
        if (index === 3) {
            return (iconExamination);
        }
        if (index === 4) {
            return (iconExamination);
        }
        if (index === 5) {
            return (iconEngineering);
        }
        if (index === 6) {
            return (iconTests);
        }
        if (index === 7) {
            return (iconSimulates);
        }
        if (index === 8) {
            return (iconBookSuggested);
        }
        if (index === 9) {
            return (iconCategori);
        }
        if (index === 10) {
            return (iconFilter);
        }
        if (index === 11) {
            return (iconSubject);
        }
        if (index === 12) {
            return (iconTimeline);
        }
        if (index === 13) {
            return (iconTimeline);
        }
        if (index === 14) {
            return (iconNotification);
        }
        if (index === 15) {
            return (iconBook);
        }
        if (index === 16) {
            return (iconFaq);
        }
        if (index === 17) {
            return (iconReports);
        }
        if (index === 18) {
            return (iconReports);
        }
        if (index === 19) {
            return (iconReports);
        }
        if (index === 20) {
            return (iconReports);
        }
        if (index === 21) {
            return (iconReports);
        }
        return (iconPlans);
    };

    // Renders
    const RenderitemList = (item: any, index: any) => {
        const onClick = () => {
            TransitionScreen(index);
        };

        if (index !== 3 && index !== 12) {
            return (
                <ListItem
                    index={index}
                    key={item}
                    onClick={onClick}
                >
                    <HomeImage src={RenderImage(index)} />
                    <DrawerOption>{item}</DrawerOption>
                    <ImgArrow src={arrow} />
                </ListItem>
            );
        }

        return null;
    };

    const GoLogout = () => {
        logout();
        setRedirect(true);
        setPathRoute('/');
    };

    const ActiveRefreshToken = async () => {
        const validation = await refreshToken({ refresh: localStorage.getItem('@Engequest:tokenRefresh') || '' });
        localStorage.setItem('@Engequest:refreshTokenEnable', 'false');
        if (!validation) {
            logout();
            window.location.replace('/');
        }
    };

    useEffect(() => {
        ActiveRefreshToken();
    }, []);

    // main
    const RenderList = () => (
        <Container>
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <Header>
                <ImgEngequest src={drawerEngequest} />
            </Header>
            <Main>
                <NameUser>{user.name || ''}</NameUser>
                <ContainerExit onClick={GoLogout}>
                    <Logout>Sair</Logout>
                    <ImgExit src={iconLogout} />
                </ContainerExit>
            </Main>
            <Aside>
                <Line />
                <FlatList
                    list={AllOptionsDrawer}
                    renderItem={RenderitemList}
                />
            </Aside>
            <Footer>
                <Line />
                <TextFooter>{`Versão: ${json.version}`}</TextFooter>
            </Footer>
        </Container>
    );
    return (
        <>
            <ButtonEnter onClick={OpenDrawer}>
                <ContainerImage src={menuHamburguer} />
            </ButtonEnter>
            <Drawer
                anchor="left"
                onClose={CloseDrawer}
                open={openDrawerLeft}
            >
                {RenderList()}
            </Drawer>
        </>
    );
};

export default DrawerNavigation;
