// libraries
import styled from 'styled-components';

// colors
import colors from '../../styles/colors';
import consts from '../../utils/consts';

export const Container = styled.div`
    background-color: ${colors.basic.white};
    min-width: 100vw;
    min-height: 100vh;
`;

export const Header = styled.div`
    background-color: ${colors.basic.white};
    height: 30vh;
    width: 100%;
    box-shadow: 1px 1px 3px gray;
`;

export const ContainerTeachers = styled.div`
    display: flex;
    align-items: center;
    margin-left: 94px;
`;

export const ContainerUsers = styled.div`
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: ${colors.basic.white};
    border-radius: 4px;
    box-shadow: 1px 1px 3px gray;
    width: 86vw;
`;

export const IconTeacher = styled.img`
    width: 30px;
    margin-top: 10px;
`;

export const TextTeacher = styled.h4`
    color: ${colors.basic.darkblue};
    margin-left: 10px;
    margin-top: 10px;
`;

export const Line = styled.div`
    background-color: ${colors.basic.blue};
    height: 40px;
    width: 2px;
`;

export const IconUser = styled.img`
    width: 26px;
    margin-left: 10px;
`;

export const IconEdit = styled.img`
    height: 26px;
    margin-right: 10px;
    width: 26px;
`;

export const TextUser = styled.h5`
    color: ${colors.basic.blue};
    margin-right: 20px;
`;

export const Arrow = styled.img`
    width: 28px;
    margin-left: 40px;
    color: ${colors.basic.blue};
`;

export const ContainerItens = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
`;

export const ImgUser = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ContainerShare = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    @media (max-width: 800px) {
        flex-direction: column;
        font-size: 14px;
    }
`;

export const TextSelector = styled.text`
    color: ${colors.basic.darkerblue};
    margin-left: 10px;
`;

export const InputShare = styled.input`
    width: 100%;
    height: 42px;
    border: none;
    background: transparent;
    font-size: 14px;
    border-radius: 6px;
    color: ${colors.basic.darkblue};
    margin-left: 4px;
    padding-left: 5px;

    ::-webkit-input-placeholder{
        /* padding: 8px; */
        color: ${colors.basic.blue};
    }
`;

export const ContainerBox = styled.div`
    background-color: ${colors.basic.orange};
    margin-top: 40px;
    max-width: 80vw;
`;

export const ButoonToAdd = styled.button`
    background-color: ${colors.basic.orange};
    border: none;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    cursor: pointer;
`;

export const ContainerAcess = styled.a`
    display: flex;
    margin-top: 20px;
    margin-left: 94px;

    @media (max-width: 800px) {
        justify-content: center;
        margin-left: 0px;
    }
`;

export const TextTeacherActive = styled.text`
    color: ${colors.basic.darkerblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
`;

export const IconUserTeacher = styled.img`
    height: 26px;
    width: 26px;
`;

export const TextSegment = styled.text`
    color: ${colors.basic.darkblue};
    width: 12.5%;
    word-wrap: break-word;
`;

export const ContainerTeacherRadio = styled.input`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserNameTeacher = styled.text`
    color: ${colors.basic.lightgray};
    word-wrap: break-word;
    width: 13%;
`;

export const UserNameDepartment = styled.text`
    color: ${colors.basic.lightgray};
    width: 13%;
    word-wrap: break-word;
`;

export const LineTop = styled.div`
    background-color: ${colors.basic.blue};
    width: 94vw;
    height: 1px;
    margin-top: 50px;
    margin-left: 40px;
`;

export const TextNumberActive = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-top: 5px;
    border-radius: 10px;
    width: 28px;
    height: 18px;
    background-color: #C2C9DE;
`;

export const ContainerInputSearch = styled.div`
    width: 420px;
    border: 1px solid ${colors.basic.blue};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;

    @media (max-width: 800px) {
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;

export const ImgDefault = styled.img`
    background-color: ${colors.basic.blue};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const SendSearch = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
`;

export const DivLine = styled.div`
    background-color: #74F4D6;
    min-width: 100%;
    margin-top: 10px;
    height: 8px;
    cursor: pointer;
`;

export const ContainerButtonAdd = styled.div`
    background-color: ${colors.basic.orange};
    width: 300px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 20px;
    cursor: pointer;

    @media (max-width: 800px) {
        margin-bottom: 20px;
    }
`;

export const TextDefault = styled.text`
    font-size: 12px;
`;

export const ContainerInputUser = styled.div`
    display: flex;
    padding-right: 25px;
    padding-left: 20px;
    cursor: pointer;
`;

export const ContainerUserAll = styled.div`
    margin-right: 10px;
    align-items: center;
    justify-content: center;
`;

export const ContainerDepartament = styled.div`
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 86vw;
    margin-left: 94px;
    margin-bottom: 20px;
    margin-top: 50px;
`;
export const ThreePoints = styled.img`
    width: 20px;
    height: 4px;
    cursor: pointer;
`;
export const ContainerShareInput = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    margin-top: 50px;
    width: 90%;
    margin-left: 94px;
`;
export const UserDivComplete = styled.div`
    height: 10vh;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 94px;
    margin-bottom: 20px;
`;
export const LineRadio = styled.div`
    background-color: #74F4D6;
    margin-left: 10px;
    height: 10vh;
    width: 8px;
`;
export const ImageGear = styled.img`
    margin-left: 10px;
    width: 40px;
`;

export const TextSegmentUser = styled.text`
    color: ${colors.basic.darkblue};
    width: 13%;
    word-wrap: break-word;
`;

export const ImageIconSelect = styled.img`
    width: 20px;
    margin-right: 8px;
`;

export const ImgUserSpace = styled.div`
    background-color: ${colors.basic.white};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ContainerSpace = styled.div`
    width: 40px;
`;

// styles lista area total

export const DivTable = styled.table`
    margin: 25px 0px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;

    @media (max-width: 800px) {
        width: 90%;
    }
`;

// styles legenda tabela

export const DivThead = styled.thead`
    text-align: left;
    padding: 12px 15px;

    @media (max-width: 800px) {
        display: none;
    }
`;

export const AreaTr = styled.tr``;

export const TextTh = styled.th`
    padding: 12px 15px;
    color: ${colors.basic.darkblue};
    word-wrap: break-word;

    @media (max-width: 1060px) {
        font-size: 12px;
    }
`;

// styles area itens lista

export const DivTbody = styled.tbody``;

export const AreaBodyTr = styled.tr`
    background-color: ${colors.basic.white};
    border-radius: 4px;
    box-shadow: 1px 1px 3px gray;
`;

export const TextTd = styled.td`
    padding: 12px 15px;
    color: ${colors.basic.lightgray};
    word-wrap: break-word;

    @media (max-width: 1070px) {
        font-size: 12px;
    }

    @media (max-width: 800px) {
        padding: 10px 15px;
        display: -webkit-box;
        font-size: 14px;
    }
`;

export const ContainerAreaTotalList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;

    @media (max-width: 800px) {
        padding: 10px;
    }
`;

// renderização depois de 800px

export const TextThView = styled.div`
    display: none;

    @media (max-width: 800px) {
       display: flex;
       margin-right: 10px;
       font-size: 14px;
       color: ${colors.basic.darkblue};
    }
`;

export const TextSelect = styled.text``;

export const ContainerDivLine = styled.div``;

export const ContainerInputBox = styled.div``;

export const ContainerRadio = styled.input``;
