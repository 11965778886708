// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllBook(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/bookReference?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/bookReference?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function GetAllBookSearch(
    searchTxt?: string,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = `${baseURL}/bookReference/autocomplete?searchTxt=${searchTxt}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewBook(
    name?: string,
    author?: string,
    edition?: string,
    placeOfPublication?: string,
    publishingCompany?: string,
    year?: string,
    token?: string,
) {
    try {
        const data = {
            name,
            author,
            edition,
            placeOfPublication,
            publishingCompany,
            year,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/bookReference`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneBook(
    name?: string,
    author?: string,
    edition?: string,
    placeOfPublication?: string,
    publishingCompany?: string,
    year?: string,
    idBookReference?: number,
    token?: string,
) {
    try {
        const data = {
            name,
            author,
            edition,
            placeOfPublication,
            publishingCompany,
            year,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/bookReference?idBookReference=${idBookReference}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneBook(
    idBookReference?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/bookReference?idBookReference=${idBookReference}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetAllBook,
    GetAllBookSearch,
    CreateNewBook,
    EditOneBook,
    DeleteOneBook,
};
