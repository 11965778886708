/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconSubjectBlue from '../../assets/images/iconSubjectBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './subjectStyles';
import colors from '../../styles/colors';
import subjects from '../../services/subjects';
import filters from '../../services/filters';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

const subjectPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [title, setTitle] = useState('');
    const [selectFilter, setSelectFilter] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [allFilters, setAllFilters] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setTitle(infoUser.name);
            setSelectFilter(infoUser.id_filter);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
            setTitle('');
            setSelectFilter('');
        }
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allSubjectsNotPage = await subjects.GetAllSubjects(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String(allSubjectsNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const allSubjects = await subjects.GetAllSubjects('', 1, limit, GetToken);
        setDataFinal(allSubjects.result);
        RenderQuantityPagination(search);
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteSubjects') {
                const validation = await subjects.DeleteOneSubjects(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Assunto deletado com sucesso!');
                }
            }
            if (valueSelected === 'EditSubjects') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {item?.created_at}
                </TextTd>
                <TextTd>
                    <TextThView>Atualizado em:</TextThView>
                    {item?.updated_at}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditSubjects">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteSubjects">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir assunto</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allSubjects = await subjects.GetAllSubjects(search, pageSelected, limit, GetToken);
        setDataFinal(allSubjects.result);
        setPageActual(pageSelected);
    }

    async function GetAllFilters() {
        const allFiltersSelected = await filters.GetAllFilters('', 0, 0, GetToken);
        setAllFilters(allFiltersSelected.result);
    }

    async function ActionSearch() {
        setLoading(true);
        const allSubjects = await subjects.GetAllSubjects(search, 1, limit, GetToken);
        setDataFinal(allSubjects.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
        GetAllFilters();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconSubjectBlue} text="GERENCIAR ASSUNTOS" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título dos assuntos..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Novo Assunto</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>ATUALIZADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                title,
                setTitle,
                selectFilter,
                setSelectFilter,
                allFilters,
            )}
        </Container>
    );
};

export default subjectPage;
