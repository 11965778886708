// libraries
import styled from 'styled-components';

// colors
import colors from '../../styles/colors';
import consts from '../../utils/consts';

export const Container = styled.div`
    background-color: ${colors.basic.white};
    min-width: 100vw;
    min-height: 100vh;
    padding-bottom: 20px;
`;

export const Header = styled.div`
    background-color: ${colors.basic.white};
    height: 30vh;
    width: 100%;
    box-shadow: 1px 1px 3px gray;
`;

export const ContainerTeachers = styled.div`
    display: flex;
    align-items: center;
    margin-left: 94px;
`;

export const IconTeacher = styled.img`
    width: 30px;
    margin-top: 10px;
`;

export const IconEdit = styled.img`
    height: 26px;
    margin-right: 10px;
    width: 26px;
`;

export const TextTeacher = styled.h4`
    color: ${colors.basic.darkblue};
    margin-left: 10px;
    margin-top: 10px;
`;

export const ContainerItens = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
`;

export const Line = styled.div`
    background-color: ${colors.basic.blue};
    height: 40px;
    width: 2px;
`;

export const IconUser = styled.img`
    width: 26px;
    margin-left: 10px;
`;

export const TextUser = styled.h5`
    color: ${colors.basic.blue};
    margin-right: 20px;
`;

export const Arrow = styled.img`
    width: 28px;
    margin-left: 40px;
    color: ${colors.basic.blue};
`;

export const ImgUser = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const LineTop = styled.div`
    background-color: ${colors.basic.blue};
    width: 94vw;
    height: 1px;
    margin-top: 50px;
    margin-left: 40px;
`;

export const ContainerUserAll = styled.div`
    margin-right: 10px;
`;

export const ContainerAcess = styled.div`
    display: flex;
    margin-top: 20px;
    margin-left: 94px;
`;

export const ContainerUsers = styled.div`
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: ${colors.basic.white};
    border-radius: 4px;
    box-shadow: 1px 1px 3px gray;
    width: 86vw;
`;

export const ContainerInputUser = styled.div`
    display: flex;
    padding-right: 25px;
    padding-left: 20px;
    cursor: pointer;
`;

export const UserNameTeacher = styled.div`
    color: ${colors.basic.lightgray};
    width: 20%;
    padding: 10px;
`;

export const UserNameDepartment = styled.div`
    color: ${colors.basic.lightgray};
    width: 16%;
`;

export const UserDivComplete = styled.div`
    height: 10vh;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 94px;
    margin-bottom: 20px;
`;

export const IconUserTeacher = styled.img`
    height: 26px;
    width: 26px;
`;

export const TextTeacherActive = styled.div`
    color: ${colors.basic.darkerblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
`;

export const TextNumberActive = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-top: 5px;
    border-radius: 10px;
    width: 28px;
    height: 18px;
    background-color: #C2C9DE;
`;

export const TextDefault = styled.div`
    font-size: 12px;
`;

export const DivLine = styled.div`
    background-color: #74F4D6;
    min-width: 100%;
    margin-top: 10px;
    height: 8px;
    cursor: pointer;
`;

export const ContainerShareInput = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    margin-top: 50px;
    width: 90%;
    margin-left: 94px;
`;

export const ContainerShare = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const TextSelector = styled.div`
    color: ${colors.basic.darkerblue};
    margin-left: 10px;
`;

export const ContainerInputSearch = styled.div`
    width: 420px;
    border: 1px solid ${colors.basic.blue};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 190px;
`;

export const InputShare = styled.input`
    width: 100%;
    height: 42px;
    border: none;
    background: transparent;
    font-size: 14px;
    border-radius: 6px;
    color: ${colors.basic.darkblue};
    margin-left: 4px;
    padding-left: 5px;

    ::-webkit-input-placeholder{
        /* padding: 8px; */
        color: ${colors.basic.blue};
    }
`;

export const ContainerDepartament = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 86vw;
    margin-left: 94px;
    margin-top: 50px;
`;

export const TextSegmentUser = styled.div`
    color: ${colors.basic.darkblue};
    width: 20%;
    padding: 10px;
`;

export const TextSegment = styled.div`
    color: ${colors.basic.darkblue};
    width: 16%;
`;
export const ContainerButtonAdd = styled.div`
    background-color: ${colors.basic.orange};
    width: 300px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    font-size: 20px;
    cursor: pointer;
`;

export const ContainerInfo = styled.div`
    margin-left: 50px;
    margin-top: 50px;
`;

export const ButoonToAdd = styled.button`
    background-color: ${colors.basic.orange};
    border: none;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    cursor: pointer;
    margin-left: 360px;
    margin-top: 20px;

    @media (max-width: 800px) {
        margin-left: 0px;
    }
`;

export const TxtCategoriesSelected = styled.text`
    color: ${colors.basic.darkblue};
    font-weight: 500;
`;

export const ContainerCicleChecked = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.basic.blue};
    border-radius: 10px;
    margin-right: 10px;
`;

export const IconChecked = styled.img`
    height: 20px;
    margin-right: 10px;
    width: 20px;
`;

export const ImgDefault = styled.img`
    background-color: ${colors.basic.blue};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ContainerCategoriesSelected = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 20px;
    height: 30px;
`;

export const InputText = styled.input`
    display: inline;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    color: ${colors.basic.darkblue};
    font-size: 14px;
    padding-left: 10px;

    /* styles placeholder */
    ::-webkit-input-placeholder {
        color: ${colors.basic.blue};
        font-size: 14px;
    }
`;

export const TextInput = styled.h4`
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 18px;
    color: ${colors.basic.darkblue};
`;

export const ContainerHome = styled.div`
    display: flex;
    width: 50%;
    margin-top: 10px;

    @media (max-width: 800px) {
        width: 80%;
    }
`;

export const DivInputText = styled.div`
    display: flex;
    width: 100px;
`;

export const ImageIconSelect = styled.img`
    width: 24px;
    margin-right: 8px;
`;

export const ContainerSpace = styled.div`
    width: 40px;
`;
export const ConainerDivLine = styled.div``;

export const TextSelect = styled.text``;
