/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components (navigation)
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import iconNotificationBlue from '../../assets/images/iconNotificationBlue.png';

import {
    Container,
    ContainerHome,
    TextInput,
    DivInputText,
    InputText,
    ButoonToAdd,
    ContainerInfo,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './emitPushNotificationStyles';

// js
import notifications from '../../services/notifications';

const emitPushNotificationPage: React.FC = () => {
    // states
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [loading, setLoading] = useState(false);

    // methods
    function DivInput(text: string, subtext: string, value: any, setValue: any) {
        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            setValue(txt.target.value);
        };

        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                <InputText
                    placeholder={subtext}
                    value={value}
                    type="text"
                    onChange={onChangeText}
                />
            </ContainerHome>
        );
    }

    async function EmitNotification() {
        setLoading(true);
        if (title && subtitle) {
            const GetInfoNotification = await notifications.CreateNewEmitNotification(title, subtitle, '', [], '', 'notification', localStorage.getItem('@Engequest:token') || '');
            if (GetInfoNotification.status === 201) {
                setLoading(false);
                setTitle('');
                setSubtitle('');
                toast.success(GetInfoNotification.message);
            } else {
                alert(GetInfoNotification?.message || 'Erro ao enviar a notificação.');
                setLoading(false);
            }
        } else {
            alert('Preencha todos os campos.');
            setLoading(false);
        }
    }

    // renders
    return (
        <Container>
            <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." />
            <ToastContainer />
            <HeaderComponent iconPage={iconNotificationBlue} text="GERENCIAR NOTIFICAÇÕES" />
            <ContainerInfo>
                {DivInput('TÍTULO', 'Título', title, setTitle)}
                {DivInput('SUBTÍTULO', 'Subtítulo', subtitle, setSubtitle)}
                <ButoonToAdd onClick={EmitNotification}>
                    <text>Enviar</text>
                </ButoonToAdd>
            </ContainerInfo>
            {/* {ModalManagePage(open, handleClose, userEdit, nameExamination, setNameExamination)} */}
        </Container>
    );
};

export default emitPushNotificationPage;
