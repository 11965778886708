// libraries
import React, { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// js
import constants from '../configs/constants';

// interfaces
interface AuthState {
    token: string;
    tokenRefresh: string;
    id?: number;
    photo?: string;
    name?: string;
    email?: string;
    telephone?: string;
    birthDate?: string;
    cpf?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    inactivatedAt?: string;
}

interface SignInCredentials {
    email: string;
    password: string;
}

interface RecoveryCredentials {
    email: string;
}

interface RefreshTokenCredentials {
    refresh: string;
}

interface AuthContextInterface {
    user: AuthState;
    signIn(credentials: SignInCredentials): Promise<boolean>;
    recoveryPassword(credentials: RecoveryCredentials): Promise<void>;
    logout(): void;
    refreshToken(credentials: RefreshTokenCredentials): Promise<boolean>;
}

// context
export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);
export const AuthProvider: React.FC = ({ children }) => {
    const baseURL = constants.baseUrl;

    const [dataAuth, setDataAuth] = useState<AuthState>(() => {
        const token = localStorage.getItem('@Engequest:token');
        const tokenRefresh = localStorage.getItem('@Engequest:tokenRefresh');
        const managerInitial: any = localStorage.getItem('@Engequest:manager');
        const manager = JSON.parse(managerInitial);

        if (token && tokenRefresh) {
            return {
                token,
                tokenRefresh,
                id: manager?.id,
                photo: manager?.photo,
                name: manager?.name,
                email: manager?.email,
                telephone: manager?.telephone,
                birthDate: manager?.birthDate,
                cpf: manager?.cpf,
                createdAt: manager?.created_at,
                updatedAt: manager?.updated_at,
                deletedAt: manager?.deleted_at,
                inactivatedAt: manager?.inactivated_at,
            };
        }
        return {} as AuthState;
    });

    const logout = useCallback(() => {
        localStorage.removeItem('@Engequest:token');
        localStorage.removeItem('@Engequest:tokenRefresh');
        localStorage.removeItem('@Engequest:refreshTokenEnable');
        localStorage.removeItem('@Engequest:manager');
        setDataAuth({} as AuthState);
    }, []);

    const signIn = useCallback(async ({ email, password }) => {
        const body = JSON.stringify({ email, password });
        const configRequest = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        };
        const response = await fetch(`${baseURL}/managers/token`, configRequest);
        const data = await response.json();
        if (data?.status !== 200) {
            // eslint-disable-next-line no-alert
            alert(data.message);
            return false;
        }
        const { token, tokenRefresh, manager } = data.result;
        localStorage.setItem('@Engequest:token', token);
        localStorage.setItem('@Engequest:tokenRefresh', tokenRefresh);
        localStorage.setItem('@Engequest:manager', JSON.stringify(manager));
        localStorage.setItem('@Engequest:refreshTokenEnable', 'true');
        setDataAuth({ token, tokenRefresh, ...manager });
        return true;
    }, []);

    const recoveryPassword = useCallback(async ({ email }) => {
        const body = JSON.stringify({ email });
        const configRequest = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        };
        const response = await fetch(`${baseURL}/managers/recoverPassword`, configRequest);
        const data = await response.json();
        // eslint-disable-next-line no-alert
        alert(data.message);
    }, []);

    const refreshToken = useCallback(async ({ refresh }) => {
        const dataBody = {
            refresh,
        };
        const body = JSON.stringify(dataBody);
        const configRequest = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        };
        const response = await fetch(`${baseURL}/managers/refreshToken`, configRequest);
        const data = await response.json();
        if (data.status >= 401) {
            return false;
        }
        return true;
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user: dataAuth, signIn, recoveryPassword, logout, refreshToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
