// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';

// interface
interface ContainerImgStatisticsInterfaces {
    backgroundColor: string,
    width?: string,
    alignItems?: string,
    flexDirection?: string,
    marginTop?: string,
    borderRadius?: string,
    border?: string,
}

interface InfoCardInterfaces {
    marginTop?: string,
    padding?: string,
    marginLeft?: string,
}

// styles
export const ContainerHeader = styled.div`
    display: flex;
    padding: 0px 20px 0px 20px;
    margin-top: 20px;
    justify-content: space-between;
`;

export const TextInfoUser = styled.text`
    color: ${colors.basic.gray};
    font-size: 14px;
    width: 25%;
    @media (max-width: 1700px) {
        font-size: 12px;
    }
    @media (max-width: 1400px) {
        font-size: 11px;
    }
`;

export const YouIdentifier = styled.text`
    font-weight: normal;
    font-size: 20px;
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;

export const ContainerUser = styled.div<ContainerImgStatisticsInterfaces>`
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: ${(props) => (props.border || 'none')};
    align-items: center;
    height: 70px;
    padding: 0px 10px 0px 10px;
    border-radius: 35px;
    width: 100%;
`;

export const InfoUser = styled.div`
    align-items: center;
    display: flex;
    width: 45%;
`;

export const AdjustNumberRanking = styled.div`
    width: 25%;
`;

export const ContainerPosition = styled.div<ContainerImgStatisticsInterfaces>`
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || colors.basic.black)};
    min-height: 50px;
    min-width: 50px;
    max-width: 50px;
`;

export const InfoCard = styled.text<InfoCardInterfaces>`
    color: ${(props) => (props.color || colors.basic.white)};
    display: flex;
    margin-top: ${(props) => (props.marginTop || '5px')};
    flex-direction: column;
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    font-size: 20px;
    width: ${(props) => (props.width || 'auto')};
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;
