/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalFaPage from './components/ModalFaq/ModalFaqPage';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconFaqBlue from '../../assets/images/iconFaqBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './faqStyles';
import colors from '../../styles/colors';
import faq from '../../services/faq';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';

const faqPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setTitle(infoUser.title);
            setDescription(infoUser.description);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
            setTitle('');
            setDescription('');
        }
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allSubjectsNotPage = await faq.GetAllFaq(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String(allSubjectsNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const faqAll = await faq.GetAllFaq('', 1, limit, GetToken);
        setDataFinal(faqAll.result.faq);
        RenderQuantityPagination(search);
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteFaq') {
                const validation = await faq.DeleteOneFaq(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Faq deletado com sucesso!');
                }
            }
            if (valueSelected === 'EditFaq') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.title}
                </TextTd>
                <TextTd>
                    <TextThView>Descrição:</TextThView>
                    {item?.description}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {item?.created_at}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditFaq">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar Faq</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteFaq">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir Faq</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allSubjects = await faq.GetAllFaq(search, pageSelected, limit, GetToken);
        setDataFinal(allSubjects.result);
        setPageActual(pageSelected);
    }

    async function ActionSearch() {
        setLoading(true);
        const allSubjects = await faq.GetAllFaq(search, 1, limit, GetToken);
        setDataFinal(allSubjects.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconFaqBlue} text="GERENCIAR FAQ" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título dos assuntos..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Novo FAQ</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>DESCRIÇÃO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalFaPage(
                open,
                handleClose,
                userEdit,
                title,
                setTitle,
                description,
                setDescription,
            )}
        </Container>
    );
};

export default faqPage;
