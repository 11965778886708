// js
import axios from 'axios';
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

// api
async function GetAllComments(
    idQuestion: string,
    isFeedback: number,
    isVerified: number,
    startDate: string,
    endDate: string,
    isDifficulty: string,
    isBank: string,
    isContests: string,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const renderBank = () => {
            if (isBank && isDifficulty) {
                return `,${isBank}`;
            }
            if (isBank) {
                return isBank;
            }
            return '';
        };
        const renderContests = () => {
            if (isContests && (isBank || isDifficulty)) {
                return `,${isContests}`;
            }
            if (isContests) {
                return isContests;
            }
            return '';
        };
        let formatBaseURL = `${baseUrl}/comments?idQuestion=${idQuestion || ''}`;
        if (startDate) {
            formatBaseURL = `${formatBaseURL}&startDate=${startDate}`;
        }
        if (endDate) {
            formatBaseURL = `${formatBaseURL}&endDate=${endDate}`;
        }
        if (Number(isFeedback)) {
            formatBaseURL = `${formatBaseURL}&isFeedback=${Number(isFeedback) === 1}`;
        }
        if (Number(isVerified)) {
            formatBaseURL = `${formatBaseURL}&isVerified=${Number(isVerified) === 1}`;
        }
        if (isDifficulty || isBank || isContests) {
            formatBaseURL = `${formatBaseURL}&idFilters=${isDifficulty || ''}${renderBank()}${renderContests()}`;
        }
        const response = await axios.get(formatBaseURL, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function ActiveVerified(
    idComment: number,
    idQuestion: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.patch(`${baseUrl}/comments/verified?idComment=${idComment}&idQuestion=${idQuestion}`, null, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateNewChildrenComments(
    image: string,
    comment: string,
    idComment: number,
    idManager: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            image,
            comment,
            idComment,
            idManager,
        };
        const stringyBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/childrenComments`, stringyBody, { headers });
        const data: any = response?.data;
        if (response.status === 201) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DelOneChildrenComment(
    idChildrenComment: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/childrenComments?idChildrenComment=${idChildrenComment}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DelOneComment(
    idComment: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/comments?idComment=${idComment}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllComments,
    ActiveVerified,
    CreateNewChildrenComments,
    DelOneChildrenComment,
    DelOneComment,
};
