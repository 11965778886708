// libraries
import styled from 'styled-components';

// colors
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// style home
export const Home = styled.div`
    background-color: ${colors.basic.white};
    border-radius: 4px;
    max-width: 90vw;
    height: 85vh;
    margin: 20px auto 0 auto;
`;

// style header
export const Header = styled.div``;

export const ContainerHeader = styled.div`
    width: 20%;
    padding: 18px;
`;

export const ContainerNewCollaborator = styled.div`
    display: flex;
    padding-left: 8px;
`;

export const IconBlue = styled.img`
    align-items: center;
    justify-content: center;
    width: 30px;
`;

export const PhotoAdm = styled.img`
    border-radius: 100px;
    height: 200px;
    width: 200px;
`;

export const DivLine = styled.div`
    background-color: #74F4D6;
    width: 100%;
    height: 8px;
    margin-top: 10px;
`;

// style body
export const Body = styled.div`
    float: left;
    width: 40%;
`;

export const TextNewCollaborator = styled.h4`
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

export const ContainerHome = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
`;

export const DivInputText = styled.div`
    display: flex;
    width: 50%;
`;

export const TextInput = styled.h4`
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 18px;
    color: ${colors.basic.darkblue};
`;

export const InputText = styled.input`
    display: inline;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    color: ${colors.basic.darkblue};
    font-size: 14px;
    padding-left: 10px;
    /* styles placeholder */
    ::-webkit-input-placeholder {
        color: ${colors.basic.blue};
        font-size: 14px;
    }
`;

// style footer
export const Footer = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;

export const ButtonExit = styled.div`
    width: 150px;
    height: 40px;
    background-color: ${colors.basic.white};
    border: 1px solid ${colors.basic.darkblue};
    border-radius: 20px;
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
`;

export const ButtonSave = styled.div`
    width: 150px;
    height: 40px;
    background-color: ${colors.basic.orange};
    border-radius: 20px;
    color: ${colors.basic.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContainerUser = styled.div`
    justify-content: space-around;
    display: flex;
`;

export const ContainerTextUser = styled.div`
    font-size: 100px;
    font-weight: 600;
`;

export const ContainerImageUser = styled.img`
    width: 60px;
    margin-top: 150px;
    margin-left: 130px;
    position: absolute;
    cursor: pointer;
`;

export const ContainerPhoto = styled.div`
    position: absolute;
    display: grid;
    border-radius: 5px;
    width: 348px;
    height: 260px;
`;

export const AddPhotoUser = styled.img`
    width: 100px;
    height: 100px;
    margin-left: 35%;
`;

export const TextAddPhotoUser = styled.text`
    display: flex;
    color: ${colors.basic.darkblue};
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
`;

export const ButtonAddPhoto = styled.button`
    width: 100%;
    height: 50px;
    margin-top: 14px;
    border: solid 1px ${colors.basic.darkblue};
    background-color: white;
    border-radius: 30px;
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
`;

export const InputNullSelectedArchive = styled.input`
    width: 100%;
    height: 300px;
    position: absolute;
    opacity: 0;
    margin-bottom: 50px;
    background-color: black;
    cursor: pointer;
`;

export const ContainerAddPhoto = styled.div`
    background-color: ${colors.basic.white};
    border-radius: 4px;
    max-width: 90vw;
    height: 80vh;
    margin: 80px auto 0 auto;
`;

export const ContainerBodyPhoto = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 50%;
    margin-left: 25%;
    margin-top: 4%;
`;

export const ButtonSavePhoto = styled.div`
    width: 190px;
    height: 40px;
    background-color: ${colors.basic.orange};
    border-radius: 20px;
    color: ${colors.basic.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContainerButtonAction = styled.div`
    width: 350px;
    height: 280px;
    position: absolute;
`;
