/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import iconBookBlue from '../../assets/images/iconBookBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './bookStyles';
import colors from '../../styles/colors';
import book from '../../services/book';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';

const bookPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [nameBook, setNameBook] = useState('');
    const [author, setAuthor] = useState('');
    const [edition, setEdition] = useState('');
    const [placeOfPublication, setPlaceOfPublication] = useState('');
    const [publishingCompany, setPublishingCompany] = useState('');
    const [year, setYear] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setNameBook(infoUser.name);
            setAuthor(infoUser.author);
            setEdition(infoUser.edition);
            setPlaceOfPublication(infoUser.place_of_publication);
            setPublishingCompany(infoUser.publishing_company);
            setYear(infoUser.year);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
        }
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allCategoriesNotPage = await book.GetAllBook(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String(allCategoriesNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const allBook = await book.GetAllBook('', 1, limit, GetToken);
        setDataFinal(allBook.result);
        RenderQuantityPagination(search);
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteBook') {
                const validation = await book.DeleteOneBook(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Livro deletado com sucesso!');
                }
            }
            if (valueSelected === 'EditBook') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Nome:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>Autor:</TextThView>
                    {item?.author}
                </TextTd>
                <TextTd>
                    <TextThView>Edição:</TextThView>
                    {item?.edition}
                </TextTd>
                <TextTd>
                    <TextThView>Local de publicação:</TextThView>
                    {item?.place_of_publication}
                </TextTd>
                <TextTd>
                    <TextThView>Editora:</TextThView>
                    {item?.publishing_company}
                </TextTd>
                <TextTd>
                    <TextThView>Ano:</TextThView>
                    {item?.year}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditBook">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteBook">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir livro</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function ActionSearch() {
        setLoading(true);
        const allCategories = await book.GetAllBook(search, 1, limit, GetToken);
        setDataFinal(allCategories.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    async function onChangePagination(event: any, pageSelected: number) {
        const allCategories = await book.GetAllBook(search, pageSelected, limit, GetToken);
        setDataFinal(allCategories.result);
        setPageActual(pageSelected);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconBookBlue} text="GERENCIAR LIVROS" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título dos livros..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Novo Livro</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>NOME</TextTh>
                            <TextTh>AUTOR</TextTh>
                            <TextTh>EDIÇÃO</TextTh>
                            <TextTh>LOCAL DE PUBLICAÇÃO</TextTh>
                            <TextTh>EDITORA</TextTh>
                            <TextTh>ANO</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                nameBook,
                setNameBook,
                author,
                setAuthor,
                edition,
                setEdition,
                placeOfPublication,
                setPlaceOfPublication,
                publishingCompany,
                setPublishingCompany,
                year,
                setYear,
            )}
        </Container>
    );
};

export default bookPage;
