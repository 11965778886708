/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import iconFilterBlue from '../../assets/images/iconFilterBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './filtersStyles';
import colors from '../../styles/colors';
import filters from '../../services/filters';
import categories from '../../services/categories';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import engineering from '../../services/engineering';

const filtersPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;
    const [loading, setLoading] = useState(false);

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [title, setTitle] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    const [allEngineering, setAllEngineering] = useState([]);
    const [selectCategories, setSelectCategories] = useState<Array<string>>([]);
    const [selectFormations, setSelectFormations] = useState<Array<any>>([]);
    const [oneCategories, setOneCategories] = useState('');
    const [oneFormations, setOneFormations] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setTitle(infoUser.name);
            setSelectCategories([]);
            const getEngineering = allEngineering.filter((v: any) => (infoUser?.ids_engineering || []).includes(v.id))?.map((item: any) => `${item.id},${item.name}`);
            setSelectFormations(getEngineering || []);
            setOneFormations(String(getEngineering[0]) || '');
            setOneCategories(infoUser.id_category);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
            setTitle('');
            setSelectCategories([]);
            setSelectFormations([]);
        }
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allFiltersNotPage = await filters.GetAllFilters(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String(allFiltersNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const allFilters = await filters.GetAllFilters('', 1, limit, GetToken);
        setDataFinal(allFilters.result);
        RenderQuantityPagination(search);
    }

    async function ActionSearch() {
        setLoading(true);
        const allFilters = await filters.GetAllFilters(search, 1, limit, GetToken);
        setDataFinal(allFilters.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteFilters') {
                const validation = await filters.DeleteOneFilters(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Filtro deletado com sucesso!');
                }
            }
            if (valueSelected === 'EditFilters') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {item?.created_at}
                </TextTd>
                <TextTd>
                    <TextThView>Atualizado em:</TextThView>
                    {item?.updated_at}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditFilters">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteFilters">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir filtro</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setOneCategories('');
        setOneFormations('');
        setSelectCategories([]);
        setSelectFormations([]);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allFilters = await filters.GetAllFilters(search, pageSelected, limit, GetToken);
        setDataFinal(allFilters.result);
        setPageActual(pageSelected);
    }

    async function GetCategories() {
        const GetAllCategories = await categories.GetAllCategories('', 0, 0, GetToken);
        setAllCategories(GetAllCategories.result);
        const GetAllEngineering = await engineering.GetAllEngineering('', 0, 0, GetToken);
        setAllEngineering(GetAllEngineering.engineering);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
        GetCategories();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconFilterBlue} text="GERENCIAR FILTROS" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título dos filtros..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={() => handleOpen(null)}>Adicionar Novo Filtro</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>ATUALIZADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                title,
                setTitle,
                selectCategories,
                setSelectCategories,
                selectFormations,
                setSelectFormations,
                oneCategories,
                setOneCategories,
                oneFormations,
                setOneFormations,
                allCategories,
                allEngineering,
            )}
        </Container>
    );
};

export default filtersPage;
