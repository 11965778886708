// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllCategories(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/categories?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/categories?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewCategories(
    name?: string,
    idEngineering?: string,
    token?: string,
) {
    const data = {
        name,
        idEngineering,
    };
    const headers = {
        'x-access-token': token || '',
        'Content-Type': 'application/json',
    };
    const body = JSON.stringify(data);
    const method = 'POST';
    const configs = {
        headers,
        method,
        body,
    };
    const response = await fetch(`${baseURL}/categories`, configs);
    const json = await response.json();
    return json;
}

async function EditOneCategories(
    name?: string,
    idEngineering?: string,
    idCategory?: number,
    token?: string,
) {
    try {
        const data = {
            name,
            idEngineering,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/categories?idCategory=${idCategory}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneCategories(
    idCategory?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/categories?idCategory=${idCategory}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetAllCategories,
    CreateNewCategories,
    EditOneCategories,
    DeleteOneCategories,
};
