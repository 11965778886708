/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Pagination } from '@material-ui/lab';
import { Button, Select, MenuItem } from '@material-ui/core';
import Modal from 'react-modal';

// components (navigation)
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import messageSquare from '../../assets/images/messageSquare.png';
import search from '../../assets/images/search.png';
import calendar from '../../assets/images/calendar.png';
import checkCircle from '../../assets/images/checkCircle.png';
import checkCircleDisabled from '../../assets/images/checkCircleDisabled.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconObjective from '../../assets/images/iconObjective.png';

import {
    Header,
    Container,
    ContainerComments,
    CardComments,
    ContainerQuestion,
    ContainerQtd,
    ContainerDescription,
    TitleComment,
    TimeComment,
    DescriptionComment,
    ContainerHeaderDescription,
    ContainerFilters,
    BoxFilter,
    InputSearch,
    IconSearch,
    ContainerSelect,
    ContainerOption,
    ContainerSeparator,
    TitleAnswer,
    ContainerVerified,
    ImgVerified,
    Input,
    ContentModal,
    ContainerCardSelected,
    ButtonSend,
    ContainerAdjustButtons,
    TextTd,
    ImageIconSelect,
    TextSelect,
    PhotoAdm,
    InputNullSelectedArchive,
    ContainerChildrenComments,
    PhotoUser,
    PhotoComment,
    AdjustInfo,
    TitleStatement,
} from './commentsStyles';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import comments from '../../services/comments';
import filters from '../../services/filters';
import uploads from '../../services/uploads';
import questions from '../../services/questions';

const commentsPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const objectInfoManager = localStorage.getItem('@Engequest:manager') || '';

    // styles modal
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.524)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: '50%',
            width: 600,
            height: 450,
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'center',
        },
    };
    const styleSelect = { width: 40 };
    const adjustButtonPhoto = {
        minHeight: 100,
        maxHeight: 100,
        minWidth: 200,
        maxWidth: 200,
        alignSelf: 'center',
        marginBottom: 20,
    };
    const valueInitialPhotoProfile = {
        eventFile: new Blob(),
        eventName: '',
        file: '',
    };

    // states
    const [loading, setLoading] = useState(false);
    // const [countPagination, setCountPagination] = useState(5);
    // const [pageActual, setPageActual] = useState(1);
    const [listComments, setListComments] = useState([]);
    const [infoComment, setInfoComment] = useState<any>(null);
    const [openAnswer, setOpenAnswer] = useState(false);
    const [comment, setComment] = useState('');
    const [idQuestion, setIdQuestion] = useState('');
    const [isFeedback, setIsFeedback] = useState(0);
    const [isVerified, setIsVerified] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isDifficulty, setIsDifficulty] = useState('');
    const [isBank, setIsBank] = useState('');
    const [isContests, setIsContests] = useState('');
    const [photoProfile, setPhotoProfile] = useState(valueInitialPhotoProfile);
    const [filtersDifficulty, setFiltersDifficulty] = useState([]);
    const [filtersBank, setFiltersBank] = useState([]);
    const [filtersContests, setFiltersContests] = useState([]);
    const [openDetailsQuestion, setOpenDetailsQuestion] = useState(false);
    const [infoComments, setInfoComments] = useState<any>(null);

    // methods
    // async function onChangePagination(event: any, pageSelected: number) {
    //     // Carregar a próxima página da lista
    //     setPageActual(pageSelected);
    // }

    const GetComments = async (idComment?: number) => {
        const allComments = await comments.GetAllComments(idQuestion, isFeedback, isVerified, startDate, endDate, isDifficulty, isBank, isContests, GetToken);
        if (allComments?.status === 200) {
            setListComments(allComments.result?.comments || []);
            if (openAnswer) {
                setInfoComment(allComments.result?.comments?.find((v: any) => v.id === idComment) || null);
            }
        }
    };

    function handleCloseModal() {
        setOpenAnswer(false);
    }

    function handleCloseModalDetailsQuestion() {
        setOpenDetailsQuestion(!openDetailsQuestion);
    }

    const onChangeComment = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = txt.target;
        setComment(value);
    };

    const ChangeFeedback = (txt: any) => {
        const { value } = txt.target;
        setIsFeedback(value);
    };

    const ChangeVerified = (txt: any) => {
        const { value } = txt.target;
        setIsVerified(value);
    };

    const ChangeDifficulty = (txt: any) => {
        const { value } = txt.target;
        setIsDifficulty(value);
    };

    const ChangeBank = (txt: any) => {
        const { value } = txt.target;
        setIsBank(value);
    };

    const ChangeContests = (txt: any) => {
        const { value } = txt.target;
        setIsContests(value);
    };

    const onChangeIdQuestion = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = txt.target;
        const getOnlyNumber = value.replace(/\D/g, '');
        if ((!idQuestion.length && getOnlyNumber) || idQuestion) {
            setIdQuestion(getOnlyNumber);
        }
    };

    const ChangeStartDate = (txt: any) => {
        const { value } = txt.target;
        setStartDate(value);
    };

    const ChangeEndDate = (txt: any) => {
        const { value } = txt.target;
        setEndDate(value);
    };

    const getFilters = async () => {
        const GetFiltersDifficulty = await filters.GetAllFilters('', 0, 10, GetToken, 3);
        setFiltersDifficulty(GetFiltersDifficulty?.result || []);
        const GetFiltersBank = await filters.GetAllFilters('', 0, 10, GetToken, 2);
        setFiltersBank(GetFiltersBank?.result || []);
        const GetFiltersContests = await filters.GetAllFilters('', 0, 10, GetToken, 1);
        setFiltersContests(GetFiltersContests?.result || []);
    };

    // useEffect
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            GetComments();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [idQuestion, isVerified, isFeedback, startDate, endDate, isDifficulty, isBank, isContests]);

    useEffect(() => {
        getFilters();
    }, []);

    // renders
    const renderLoading = () => {
        if (!loading) {
            return null;
        }
        return (
            <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." />
        );
    };

    const renderCardContent = (v: any, isModal: boolean, isChildrenComments?: boolean) => {
        const ActionVerified = async () => {
            const responseVerified = await comments.ActiveVerified(v.id, v.id_question, GetToken);
            if (responseVerified) {
                alert(responseVerified.message);
                const allComments = await comments.GetAllComments(idQuestion, isFeedback, isVerified, startDate, endDate, isDifficulty, isBank, isContests, GetToken);
                if (allComments.status === 200) {
                    setListComments(allComments.result?.comments || []);
                    const getInfo = (allComments.result?.comments || []).find((item: any) => item.id === v.id);
                    setInfoComment(getInfo);
                }
            }
        };

        const openModalAnswer = () => {
            setInfoComment(v);
            setComment('');
            setOpenAnswer(true);
        };

        const onChangeSelectChildren = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'Delete') {
                const responseDelete = await comments.DelOneChildrenComment(v.id, GetToken);
                if (responseDelete?.status === 200) {
                    GetComments(v.id_comment);
                    alert(responseDelete.message);
                }
            }
        };

        const onChangeSelectComment = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'Delete') {
                const responseDelete = await comments.DelOneComment(v.id, GetToken);
                if (responseDelete?.status === 200) {
                    GetComments();
                    setOpenAnswer(false);
                    alert(responseDelete.message);
                }
            }
            if (valueSelected === 'View') {
                setOpenDetailsQuestion(true);
                const responseDelete = await questions.GetAllQuestions('', undefined, undefined, undefined, GetToken, v.id_question);
                setInfoComments(responseDelete?.result?.list[0]);
            }
        };

        return (
            <>
                {isChildrenComments ? null : (
                    <ContainerQuestion>
                        Questão
                        <ContainerQtd>{v.id_question}</ContainerQtd>
                    </ContainerQuestion>
                )}
                <ContainerDescription marginBottom={isChildrenComments ? '20px' : '0px'}>
                    <ContainerVerified>
                        <AdjustInfo>
                            {isModal ? '' : 'Comentário'}
                            {isModal ? null : (
                                <>
                                    {v.verified ? (
                                        <ImgVerified src={checkCircle} onClick={ActionVerified} />
                                    ) : (
                                        <ImgVerified src={checkCircleDisabled} onClick={ActionVerified} />
                                    )}
                                </>
                            )}
                        </AdjustInfo>
                        {isModal ? null : (
                            <TextTd>
                                <Select value="" style={styleSelect} onChange={onChangeSelectComment}>
                                    <MenuItem value="View">
                                        <ImageIconSelect src={iconObjective} />
                                        <TextSelect>Visualizar questões</TextSelect>
                                    </MenuItem>
                                    <MenuItem value="Delete">
                                        <ImageIconSelect src={imageDeleteUser} />
                                        <TextSelect>Excluir comentário</TextSelect>
                                    </MenuItem>
                                </Select>
                            </TextTd>
                        )}
                    </ContainerVerified>
                    <ContainerHeaderDescription>
                        <AdjustInfo>
                            <PhotoUser src={v?.photoUser || v?.photoManager} />
                            <TitleComment>
                                {v?.nameUser || v?.nameManager}
                            </TitleComment>
                            <TimeComment>
                                {v.created_at}
                            </TimeComment>
                            {/* {isModal ? (
                                <>
                                    {v.verified ? (
                                        <ImgVerified src={checkCircle} />
                                    ) : (
                                        <ImgVerified src={checkCircleDisabled} />
                                    )}
                                </>
                            ) : null} */}
                        </AdjustInfo>
                        {!isModal ? null : (
                            <TextTd>
                                <Select value="" style={styleSelect} onChange={isChildrenComments ? onChangeSelectChildren : onChangeSelectComment}>
                                    <MenuItem value="Delete">
                                        <ImageIconSelect src={imageDeleteUser} />
                                        <TextSelect>Excluir comentário</TextSelect>
                                    </MenuItem>
                                </Select>
                            </TextTd>
                        )}
                    </ContainerHeaderDescription>
                    <DescriptionComment>
                        {v.comment}
                    </DescriptionComment>
                    {isModal && v.image ? (
                        <PhotoComment src={v.image} />
                    ) : null}
                    {isModal ? null : (
                        <TitleAnswer onClick={isModal ? () => null : openModalAnswer}>
                            Ver mais
                        </TitleAnswer>
                    )}
                </ContainerDescription>
            </>
        );
    };

    function InputArchive(event: any) {
        const newDataFiles = {
            eventFile: event.target.files[0],
            eventName: event.target.files[0].name,
            file: URL.createObjectURL(event.target.files[0]),
        };
        setPhotoProfile(newDataFiles);
    }

    const createChildrenComment = async () => {
        const infoUser = objectInfoManager ? JSON.parse(objectInfoManager) : '';
        const uploadPhoto = photoProfile?.file ? await uploads.UploadFile(photoProfile.eventFile) : '';
        const addNewChildren = await comments.CreateNewChildrenComments(uploadPhoto?.result || '', comment, infoComment.id, infoUser?.id || '', GetToken);
        if (addNewChildren?.status === 201) {
            alert(addNewChildren.message);
            // setOpenAnswer(false);
            setPhotoProfile(valueInitialPhotoProfile);
            setComment('');
            GetComments(infoComment.id);
        }
    };

    const renderCardComments = (v: any, isModal: boolean) => {
        if (!isModal) {
            return (
                <CardComments key={v.id} width={isModal ? '100%' : '32%'}>
                    {renderCardContent(v, isModal)}
                </CardComments>
            );
        }
        return null;
    };

    return (
        <Container>
            <Header>
                {renderLoading()}
                <ToastContainer />
                <HeaderComponent iconPage={messageSquare} text="GERENCIAR COMENTÁRIOS" />
            </Header>
            <ContainerFilters marginBottom="0px">
                <ContainerSeparator>
                    <BoxFilter>
                        <InputSearch
                            placeholder="Buscar por questão"
                            value={idQuestion}
                            onChange={onChangeIdQuestion}
                        />
                        <IconSearch src={search} />
                    </BoxFilter>
                    <BoxFilter>
                        <ContainerSelect defaultValue={isFeedback} onChange={ChangeFeedback}>
                            <ContainerOption value={0}>Todas</ContainerOption>
                            <ContainerOption value={1}>Respondidas</ContainerOption>
                            <ContainerOption value={2}>Não respondidas</ContainerOption>
                        </ContainerSelect>
                    </BoxFilter>
                    <BoxFilter>
                        <ContainerSelect defaultValue={isVerified} onChange={ChangeVerified}>
                            <ContainerOption value={0}>Todas</ContainerOption>
                            <ContainerOption value={1}>Verificados</ContainerOption>
                            <ContainerOption value={2}>Não verificados</ContainerOption>
                        </ContainerSelect>
                    </BoxFilter>
                </ContainerSeparator>
                <ContainerSeparator>
                    <BoxFilter>
                        <InputSearch
                            placeholder="Buscar por questão"
                            type="datetime-local"
                            value={startDate}
                            onChange={ChangeStartDate}
                        />
                        <IconSearch src={calendar} />
                    </BoxFilter>
                    <BoxFilter>
                        <InputSearch
                            placeholder="Buscar por questão"
                            type="datetime-local"
                            value={endDate}
                            onChange={ChangeEndDate}
                        />
                        <IconSearch src={calendar} />
                    </BoxFilter>
                </ContainerSeparator>
                {/* <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                /> */}
            </ContainerFilters>
            <ContainerFilters marginTop="0px" marginBottom="50px">
                <ContainerSeparator>
                    <BoxFilter>
                        <ContainerSelect defaultValue={isDifficulty} onChange={ChangeDifficulty}>
                            <ContainerOption value="">Selecione uma dificuldade</ContainerOption>
                            {filtersDifficulty.map((v: any) => (
                                <ContainerOption value={v?.id}>{v?.name}</ContainerOption>
                            ))}
                        </ContainerSelect>
                    </BoxFilter>
                    <BoxFilter>
                        <ContainerSelect defaultValue={isBank} onChange={ChangeBank}>
                            <ContainerOption value="">Selecione uma banca</ContainerOption>
                            {filtersBank.map((v: any) => (
                                <ContainerOption value={v?.id}>{v?.name}</ContainerOption>
                            ))}
                        </ContainerSelect>
                    </BoxFilter>
                    <BoxFilter>
                        <ContainerSelect defaultValue={isContests} onChange={ChangeContests}>
                            <ContainerOption value="">Selecione um concurso</ContainerOption>
                            {filtersContests.map((v: any) => (
                                <ContainerOption value={v?.id}>{v?.name}</ContainerOption>
                            ))}
                        </ContainerSelect>
                    </BoxFilter>
                </ContainerSeparator>
            </ContainerFilters>
            <ContainerComments>
                {listComments.map((v: any) => renderCardComments(v, false))}
            </ContainerComments>
            <Modal
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
                isOpen={openDetailsQuestion}
                onRequestClose={handleCloseModalDetailsQuestion}
            >
                <ContentModal>
                    {infoComments?.statement ? (
                        <>
                            <TitleStatement>Enunciado:</TitleStatement>
                            <div
                                dangerouslySetInnerHTML={{ __html: `<p style='color: #000000'>${infoComments?.statement}</p>` }}
                            />
                        </>
                    ) : null}
                    {infoComments?.answer ? (
                        <>
                            <TitleStatement>Resolução:</TitleStatement>
                            <div
                                dangerouslySetInnerHTML={{ __html: `<p style='color: #000000'>${infoComments?.answer}</p>` }}
                            />
                        </>
                    ) : null}
                    <ButtonSend outline onClick={handleCloseModalDetailsQuestion}>
                        Cancelar
                    </ButtonSend>
                </ContentModal>
            </Modal>
            <Modal
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
                isOpen={openAnswer}
                onRequestClose={handleCloseModal}
            >
                <ContentModal>
                    {!infoComment ? null : (
                        <ContainerCardSelected>
                            {renderCardContent(infoComment, true)}
                        </ContainerCardSelected>
                    )}
                    <ContainerChildrenComments>
                        {(infoComment?.childrenComments || []).map((v: any) => renderCardContent(v, true, true))}
                    </ContainerChildrenComments>
                    <Button style={adjustButtonPhoto}>
                        {photoProfile.file && !infoComment?.image ? (
                            <PhotoAdm src={photoProfile.file} />
                        ) : null}
                        {!photoProfile.file && infoComment?.image ? (
                            <PhotoAdm src={infoComment.image} />
                        ) : null}
                        {!photoProfile?.file && !infoComment?.image ? (
                            <TitleAnswer>ADICIONAR FOTO</TitleAnswer>
                        ) : null}
                        <InputNullSelectedArchive
                            accept="image/png, image/jpeg, image/jpg"
                            type="file"
                            onChange={InputArchive}
                        />
                    </Button>
                    <Input
                        placeholder="Adicionar resposta"
                        value={comment}
                        onChange={onChangeComment}
                    />
                    <ContainerAdjustButtons>
                        <ButtonSend outline onClick={handleCloseModal}>
                            Cancelar
                        </ButtonSend>
                        <ButtonSend onClick={createChildrenComment}>
                            Enviar resposta
                        </ButtonSend>
                    </ContainerAdjustButtons>
                </ContentModal>
            </Modal>
        </Container>
    );
};

export default commentsPage;
