import styled from 'styled-components';

// colors
import colors from '../../styles/colors';

// constants
import consts from '../../utils/consts';

export const Body = styled.div``;

export const Footer = styled.div``;

export const Home = styled.div`
    background-color: ${colors.basic.white};
    min-width: 100vw;
    min-height: 100vh;
`;

export const Header = styled.div`
    background-color: ${colors.basic.white};
    height: 30vh;
    width: 100%;
    box-shadow: 1px 1px 3px gray;
`;

export const ContainerPlans = styled.div`
    display: flex;
    align-items: center;
    margin-left: 94px;
`;

export const IconPlans = styled.img`
   width: 30px;
   margin-top: 10px;
`;

export const IconEdit = styled.img`
    height: 26px;
    margin-right: 10px;
    width: 26px;
`;

export const TextPlans = styled.h4`
    color: ${colors.basic.darkblue};
    margin-left: 10px;
    margin-top: 10px;
`;

export const Line = styled.div`
    background-color: ${colors.basic.blue};
    height: 40px;
    width: 2px;
`;

export const IconUser = styled.img`
    width: 26px;
    margin-left: 10px;
`;

export const TextUser = styled.h5`
    color: ${colors.basic.blue};
    margin-right: 20px;
`;

export const Arrow = styled.img`
    width: 28px;
    margin-left: 40px;
    color: ${colors.basic.blue};
`;

export const ImgUser = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ImgDefault = styled.img`
    background-color: ${colors.basic.blue};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ContainerItens = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
`;

export const ContainerShareInput = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    margin-top: 50px;
    width: 86vw;
    margin-left: 94px;
`;

export const ContainerShare = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const ContainerInputSearch = styled.div`
    width: 420px;
    border: 1px solid ${colors.basic.blue};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 170px;
`;

export const ContainerRadio = styled.input`
    margin-right: 20px;
`;

export const TextSelector = styled.text`
    color: ${colors.basic.darkerblue};
    margin-left: 10px;
    width: 190px;
`;

export const InputShare = styled.input`
    width: 100%;
    height: 42px;
    border: none;
    background: transparent;
    font-size: 14px;
    border-radius: 6px;
    color: ${colors.basic.darkblue};
    margin-left: 4px;
    padding-left: 5px;

    ::-webkit-input-placeholder{
        color: ${colors.basic.blue};
    }
`;

export const AiOutlineSearch = styled.div``;

export const ContainerDepartament = styled.div`
    height: 10vh;
    display: flex;
    align-items: center;
    margin-top: 50px;
    width: 86vw;
    margin-left: 56px;
`;

export const TextSegmentUser = styled.text`
    color: ${colors.basic.darkblue};
    margin-left: 60px;
`;

export const TextSegment = styled.text`
    color: ${colors.basic.darkblue};
`;

export const UserDivComplete = styled.text`
    height: 10vh;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 94px;
    margin-bottom: 20px;
`;
export const ContainerUsers = styled.text`
    height: 10vh;
    display: flex;
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 4px;
    box-shadow: 1px 1px 3px gray;
    width: 86vw;
`;

export const UserNameTeacher = styled.text`
    color: ${colors.basic.lightgray};
    margin-left: 20px;
    width: 130px;
`;

export const FormGroup = styled.div`
    display: flex;
    justify-content: space-between;
    width: 51%;
    margin-left: 300px;
`;

export const LineTop = styled.div`
    background-color: ${colors.basic.blue};
    width: 94vw;
    height: 1px;
    margin-top: 50px;
    margin-left: 40px;
`;

export const ContainerPlansName = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 330px;
    width: 52%;
`;

export const ContainerButtonAdd = styled.div`
    background-color: ${colors.basic.orange};
    width: 270px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    font-size: 20px;
    cursor: pointer;
`;

export const ButoonToAdd = styled.button`
    background-color: ${colors.basic.orange};
    border: none;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    cursor: pointer;
`;
export const ContainerButtonBlue = styled.div`
    background-color: #05B6F7;
    width: 190px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    font-size: 20px;
    cursor: pointer;
`;

export const ButoonToAddBlue = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #05B6F7;
    border: none;
    border-radius: 30px;
    width: 170px;
    height: 40px;
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    cursor: pointer;
`;

export const ContainerButton = styled.div`
    width: 600px;
    display: flex;
`;

export const ImageGear = styled.img`
    margin-left: 30px;
    width: 40px;
    cursor: pointer;
`;
