import styled from 'styled-components';

import colors from '../../styles/colors';

// styles
export const ContainerTeachers = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconTeacher = styled.img`
    width: 25px;
    height: 25px;
`;

export const TextTeacher = styled.h4`
    color: ${colors.basic.darkblue};
    display: flex;
    text-align: center;
    margin-left: 10px;
    /* margin-top: 10px; */

    @media (max-width: 800px) {
        font-size: 14px;
    }
`;

export const Line = styled.div`
    background-color: ${colors.basic.blue};
    height: 30px;
    width: 2px;
`;

export const ContainerItens = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
`;

export const IconUser = styled.img`
    width: 26px;
    margin: 10px;
`;

export const TextUser = styled.h5`
    color: ${colors.basic.blue};
    margin-right: 20px;
`;

export const IconEdit = styled.img`
    height: 26px;
    margin-right: 10px;
    width: 26px;
`;

export const ImgUser = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ImgDefault = styled.img`
    background-color: ${colors.basic.blue};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 8px;
`;

export const ContainerAreaHead = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 50px;
`;
