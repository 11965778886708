/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addHours, lightFormat } from 'date-fns';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManagePage';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import premium from '../../assets/images/premium.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconBookSuggestedBlue from '../../assets/images/iconBookSuggestedBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
} from './tournamentStyles';
import categories from '../../services/categories';
import engineering from '../../services/engineering';
import examination from '../../services/examination';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';

// contexts
import colors from '../../styles/colors';
import tournament from '../../services/tournament';
import statistics from '../../services/statistics';

interface AmountInterface {
    name: string,
    id: number,
}

const tournamentPage: React.FC = () => {
    // constants
    const objectInfoManager = localStorage.getItem('@Engequest:manager') || '';
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;
    const [loading, setLoading] = useState(false);

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [title, setTitle] = useState('');
    const [selectExamination, setSelectExamination] = useState('');
    const [selectEngineering, setSelectEngineering] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    const [allExamination, setAllExamination] = useState([]);
    const [allEngineering, setAllEngineering] = useState([]);
    const [queries, setQueries] = useState([]);
    const [allFilters, setAllFilters] = useState([]);
    const [amountSelected, setAmountSelected] = useState<AmountInterface[]>([]);
    const [subjectSelected, setSubjectSelected] = useState<AmountInterface[]>([]);
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [qtdId, setQtdId] = useState(1);
    const [dateInitial, setDateInitial] = useState('');
    const [dateFinal, setDateFinal] = useState('');
    const [typeModal, setTypeModal] = useState('');
    const [allStatisticsRanking, setAllStatisticsRanking] = useState([]);
    const [widthActual, setWidthActual] = useState(window.innerWidth);

    // methods
    const GetAllCategories = async () => {
        const GetCategories = await categories.GetAllCategories('', 0, 0, GetToken);
        setAllCategories(GetCategories.result);
        const GetExamination = await examination.GetAllExamination('', 0, 0, GetToken);
        setAllExamination(GetExamination.examination);
        const GetEngineering = await engineering.GetAllEngineering('', 0, 0, GetToken);
        setAllEngineering(GetEngineering.engineering);
    };

    const handleOpen = (item: any, type: string) => {
        setTypeModal(type);
        setOpen(true);
        if (type !== 'RankingInfo') {
            if (item?.id) {
                const formatQueries = JSON.parse(item?.queries);
                const maxId = formatQueries?.map((v: any) => v?.id);
                const formatDateInitial = lightFormat(addHours(new Date(item.date_start), 3), 'yyyy-MM-dd HH:mm');
                const formatDateFinal = lightFormat(addHours(new Date(item.date_end), 3), 'yyyy-MM-dd HH:mm');
                setDateInitial(formatDateInitial);
                setDateFinal(formatDateFinal);
                setTitle(item.title);
                setSelectExamination(item.id_examination);
                setSelectEngineering(item.id_engineering);
                setUserEdit(item);
                setQueries(formatQueries || []);
                setQtdId(Math.max(...maxId) + 1);
            } else {
                setUserEdit(null);
                setTitle('');
                setDateInitial('');
                setDateFinal('');
                setSelectExamination('');
                setAmountSelected([]);
                setSubjectSelected([]);
                setSelectEngineering('');
                setQueries([]);
                setQtdId(1);
            }
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allTournament = await tournament.GetAllTournament(search, pageSelected, limit, GetToken);
        setDataFinal(allTournament?.result || []);
        setPageActual(pageSelected);
    }

    async function RenderQuantityPagination(searchSelected: string) {
        const allTournament = await tournament.GetAllTournament(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String((allTournament?.result || []).length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const allTournament = await tournament.GetAllTournament('', 1, limit, GetToken);
        setDataFinal(allTournament?.result || []);
        RenderQuantityPagination(search);
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        setQueries([]);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function ActionSearch() {
        setLoading(true);
        const allTournament = await tournament.GetAllTournament(search, 1, limit, GetToken);
        setDataFinal(allTournament?.result || []);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    // useEffect
    useEffect(() => {
        GetAllCategories();
        ActionInitial();
    }, []);

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteInfo') {
                await tournament.DeleteOneTournament(item.id, GetToken);
                ActionInitial();
                toast.success('Desafio deletado com sucesso!');
            }
            // if (valueSelected === 'EditInfo') {
            //     handleOpen(item);
            // }
            if (valueSelected === 'RankingInfo') {
                const infoUser = JSON.parse(objectInfoManager);
                const allStatistics = await statistics.GetStatisticsRanking(item.id, infoUser?.id || '', GetToken);
                if (allStatistics?.status === 200) {
                    setAllStatisticsRanking(allStatistics.result);
                    handleOpen(item, valueSelected);
                }
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item.id}
                </TextTd>
                {!item.title ? null : (
                    <TextTd>
                        <TextThView>Título:</TextThView>
                        {item.title}
                    </TextTd>
                )}
                {!item.examinationName ? null : (
                    <TextTd>
                        <TextThView>Exame:</TextThView>
                        {item.examinationName}
                    </TextTd>
                )}
                <TextTd>
                    <TextThView>Formação:</TextThView>
                    {item?.engineeringName || 'Todas as formações'}
                </TextTd>
                {!item.created_at ? null : (
                    <TextTd>
                        <TextThView>Criado em:</TextThView>
                        {lightFormat(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
                    </TextTd>
                )}
                {!item.date_start ? null : (
                    <TextTd>
                        <TextThView>Data de Início:</TextThView>
                        {lightFormat(addHours(new Date(item.date_start), 3), 'dd/MM/yyyy HH:mm')}
                    </TextTd>
                )}
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="RankingInfo">
                            <ImageIconSelect src={premium} />
                            <TextSelect>Ver ranking</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteInfo">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir desafio</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const renderLoading = () => {
        if (!loading) {
            return null;
        }
        return (
            <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." />
        );
    };

    const openAdd = () => {
        handleOpen(null, '');
    };

    // useEffect
    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    return (
        <Container>
            <Header>
                {renderLoading()}
                <ToastContainer />
                <HeaderComponent iconPage={iconBookSuggestedBlue} text="GERENCIAR DESAFIOS DIÁRIOS" />
            </Header>
            <ContainerShare>
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título dos desafios..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={openAdd}>Adicionar Novo Desafio</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>EXAME</TextTh>
                            <TextTh>FORMAÇÃO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>DATA DE INÍCIO</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(
                open,
                handleClose,
                userEdit,
                title,
                setTitle,
                queries,
                setQueries,
                qtdId,
                setQtdId,
                selectExamination,
                setSelectExamination,
                selectEngineering,
                setSelectEngineering,
                allCategories,
                allFilters,
                setAllFilters,
                amountSelected,
                setAmountSelected,
                subjectSelected,
                setSubjectSelected,
                allExamination,
                allEngineering,
                dateInitial,
                setDateInitial,
                dateFinal,
                setDateFinal,
                typeModal,
                allStatisticsRanking,
                widthActual,
            )}
        </Container>
    );
};

export default tournamentPage;
