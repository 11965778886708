/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { getTime, lightFormat, addHours } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    TextInput,
    InputText,
    DivInputText,
    Body,
} from './ModalReportsStyles';
import LoadingComponent from '../../../components/ModalLoading/LoadingComponent';

const ModalReports = (
    open: boolean,
    handleClose: any,
    userEdit?: any,
) => {
    const formatBirthDate = lightFormat(addHours(Number(userEdit?.birth_date || '987681600000'), 9), 'dd/MM/yyyy') || '';

    const MaskcPF = (v: any) => {
        const cpfFormatted = v?.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2');
        return cpfFormatted;
    };

    const MaskPhone = (v: any) => {
        let valueInitial = v?.replace(/\D/g, '').replace(/^0/, '') || '';
        if (valueInitial.length > 11) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (valueInitial.length > 7) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (valueInitial.length > 2) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        }
        return valueInitial;
    };

    // constants
    const [loading, setLoading] = useState(false);
    const [cpf, setCpf] = useState(MaskcPF(userEdit?.cpf));
    const [data, setData] = useState(formatBirthDate || '');
    const [telephone, setTelephone] = useState(MaskPhone(userEdit?.telephone) || '');

    // renders
    function DivInput(text: string, subtext: string, value: any, setValue?: any) {
        const onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
            if (text === 'CPF') {
                e.currentTarget.maxLength = 14;
                const formattedCpf = MaskcPF(e.currentTarget.value);
                setValue(formattedCpf);
            }
            if (text === 'DATA DE NASCIMENTO') {
                e.currentTarget.maxLength = 10;
                const dataFormatted = e.currentTarget.value.replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{4})(\d)/, '$1/$2');
                setValue(dataFormatted);
            }
            if (text === 'TELEFONE') {
                const formatTelephone = MaskPhone(e.currentTarget.value);
                setValue(formatTelephone);
            } else {
                setValue(e.currentTarget.value);
            }
        };

        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                <InputText disabled id="" placeholder={subtext} value={value} onChange={onChangeInput} />
            </ContainerHome>
        );
    }
    // main
    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Body>
                                {DivInput('NOME', 'Nome', userEdit?.name)}
                                {DivInput('DESCRIÇÃO', 'descrição', userEdit?.description)}
                                {DivInput('TELEFONE', 'Telefone', MaskPhone(userEdit?.telephone), setTelephone)}
                                {DivInput('CPF', 'cpf', MaskcPF(userEdit?.cpf), setCpf)}
                                {DivInput('E-MAIL', 'email', userEdit?.email)}
                                {DivInput('DATA DE NASCIMENTO', 'data', data, setData)}
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Fechar</ButtonExit>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default ModalReports;
