/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
} from './ModalManageStyles';
import examination from '../../../../services/examination';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    nameExamination: string,
    setNameExamination: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const [loading, setLoading] = useState(false);

    // methods
    async function CreateOrEditExamination() {
        setLoading(true);
        if (!userEdit?.id) {
            await examination.CreateNewExamination(nameExamination, GetToken)
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Exame criado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        } else {
            await examination.EditOneExamination(nameExamination, userEdit?.id, GetToken)
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Exame editado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    }

    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Novo Exame</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput>TÍTULO</TextInput>
                                    </DivInputText>
                                    <InputText
                                        placeholder="TÍTULO"
                                        value={nameExamination}
                                        type="text"
                                        onChange={(txt) => setNameExamination(txt.target.value)}
                                    />
                                </ContainerHome>
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateOrEditExamination}>{!userEdit?.id ? 'Criar concurso' : 'Salvar concurso'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            ) }
        </>

    );
};

export default modalManagePage;
