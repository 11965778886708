// js
import colors from '../../styles/colors';

// functions
const ValidationBackgroundColorButton = (index: number): string => {
    if (document.URL.includes('/users') && index === 0) {
        return `${colors.basic.blue}`;
    }
    if (document.URL.includes('/contributors') && index === 1) {
        return `${colors.basic.blue}`;
    }
    if (document.URL.includes('/questions') && index === 2) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/plans') && index === 3) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/examination') && index === 4) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/engineering') && index === 5) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/tests') && index === 6) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/simulates') && index === 7) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/bookSuggested') && index === 8) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/categories') && index === 9) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/filters') && index === 10) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/subject') && index === 11) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/statistics') && index === 12) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/emitNotification') && index === 13) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/emitPushNotification') && index === 14) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/books') && index === 15) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/faq') && index === 16) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/reports') && index === 17) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/tournament') && index === 18) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/comments') && index === 19) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/essay') && index === 20) {
        return `${colors.basic.blue};`;
    }
    if (document.URL.includes('/textSupport') && index === 21) {
        return `${colors.basic.blue};`;
    }
    return `${colors.basic.darkblue}`;
};

export {
    ValidationBackgroundColorButton,
};
