// libraries
import React from 'react';

// js
import colors from '../../../../styles/colors';
import {
    ContainerUser,
    InfoUser,
    ContainerPosition,
    InfoCard,
    ContainerHeader,
    TextInfoUser,
    AdjustNumberRanking,
} from './headerRankingStyles';

// interfaces
interface Props {
    type: string,
    widthActual: number,
    allStatisticsRanking: Array<any>
}

const RankingComponent: React.FC<Props> = ({
    type,
    widthActual,
    allStatisticsRanking,
}: Props) => {
    // constants
    const formatTopTen = type === 'fristFive' ? allStatisticsRanking.slice(0, 5) : allStatisticsRanking.slice(5, 10);

    // main
    return (
        <>
            {(widthActual > 950 || type === 'fristFive') && allStatisticsRanking.length ? (
                <ContainerHeader>
                    <InfoUser>
                        <TextInfoUser>#</TextInfoUser>
                        <TextInfoUser>NOME</TextInfoUser>
                    </InfoUser>
                    <TextInfoUser>PONTOS</TextInfoUser>
                    <TextInfoUser>APROVEITAMENTO</TextInfoUser>
                </ContainerHeader>
            ) : null}
            {formatTopTen.map((v: any) => (
                <ContainerUser backgroundColor={colors.basic.gray} border="none">
                    <InfoUser>
                        <AdjustNumberRanking>
                            <ContainerPosition backgroundColor={colors.basic.lightGrey}>
                                <InfoCard color={colors.basic.white} marginTop="0px">{`${v.ranking}º`}</InfoCard>
                            </ContainerPosition>
                        </AdjustNumberRanking>
                        <InfoCard fontWeight="normal" width="25%">
                            {v.name}
                        </InfoCard>
                    </InfoUser>
                    <InfoCard width="25%">{v.points}</InfoCard>
                    <InfoCard width="25%">{v.performance}</InfoCard>
                </ContainerUser>
            ))}
        </>
    );
};

export default RankingComponent;
