// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllQuestions(
    searchTxt?: string,
    typeQuestion?: Array<string>,
    page?: number,
    limit?: number,
    token?: string,
    idQuestion?: number,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        // &typeQuestion=${typeQuestion}
        const RenderBaseURL = page ? `${baseURL}/questions/search?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/questions/search?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const validationTypeQuestion = typeQuestion?.length ? `${RenderBaseURL}&typeQuestion=${typeQuestion}` : RenderBaseURL;
        const ExistIdQuestion = idQuestion ? `${validationTypeQuestion}&idQuestion=${idQuestion}` : validationTypeQuestion;
        const response = await fetch(ExistIdQuestion, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteAllQuestions(
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        // &typeQuestion=${typeQuestion}
        const response = await fetch(`${baseURL}/questions/removeBulkUpload`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditNewQuestion(
    examYear: any,
    examColor: any,
    typeReference: any,
    idFilters: any,
    idSubjects: any,
    idBookReference: number,
    page: number,
    numberQuestion: number,
    author: string,
    statement: any,
    answer: any,
    video: any,
    options: any,
    feedback: string,
    type: string,
    optionRightOrWrong: string,
    idQuestion: string,
    token?: string,
) {
    try {
        let data = {};
        if (examYear) {
            data = { ...data, examYear };
        }
        if (examColor) {
            data = { ...data, examColor };
        }
        if (typeReference) {
            data = { ...data, typeReference };
        }
        if (idFilters) {
            data = { ...data, idFilters };
        }
        if (idSubjects) {
            data = { ...data, idSubjects };
        }
        if (idBookReference) {
            data = { ...data, idBookReference };
        }
        if (page) {
            data = { ...data, page };
        }
        if (numberQuestion) {
            data = { ...data, numberQuestion };
        }
        if (author) {
            data = { ...data, author };
        }
        if (statement) {
            data = { ...data, statement };
        }
        if (answer) {
            data = { ...data, answer };
        }
        if (video) {
            data = { ...data, video };
        }
        if (options) {
            data = { ...data, options };
        }
        if (feedback) {
            data = { ...data, feedback };
        }
        if (type) {
            data = { ...data, type };
        }
        if (optionRightOrWrong) {
            data = { ...data, optionRightOrWrong };
        }
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const method = 'PUT';
        const configs = {
            headers,
            body,
            method,
        };
        const response = await fetch(`${baseURL}/questions?idQuestion=${idQuestion}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewQuestion(
    examYear: any,
    examColor: any,
    typeReference: any,
    idFilters: any,
    idSubjects: any,
    idBookReference: number,
    page: number,
    numberQuestion: number,
    author: string,
    statement: any,
    answer: any,
    video: any,
    options: any,
    feedback: string,
    type: string,
    optionRightOrWrong: string,
    token?: string,
) {
    try {
        let data = {};
        if (examYear) {
            data = { ...data, examYear };
        }
        if (examColor) {
            data = { ...data, examColor };
        }
        if (typeReference) {
            data = { ...data, typeReference };
        }
        if (idFilters) {
            data = { ...data, idFilters };
        }
        if (idSubjects) {
            data = { ...data, idSubjects };
        }
        if (idBookReference) {
            data = { ...data, idBookReference };
        }
        if (page) {
            data = { ...data, page };
        }
        if (numberQuestion) {
            data = { ...data, numberQuestion };
        }
        if (author) {
            data = { ...data, author };
        }
        if (statement) {
            data = { ...data, statement };
        }
        if (answer) {
            data = { ...data, answer };
        }
        if (video) {
            data = { ...data, video };
        }
        if (!options.includes('')) {
            data = { ...data, options };
        }
        if (feedback) {
            data = { ...data, feedback };
        }
        if (type) {
            data = { ...data, type };
        }
        if (optionRightOrWrong) {
            data = { ...data, optionRightOrWrong };
        }
        const body = JSON.stringify(data);
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const method = 'POST';
        const configs = {
            headers,
            body,
            method,
        };
        const response = await fetch(`${baseURL}/questions`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneQuestion(
    idQuestion?: string,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/questions?idQuestion=${idQuestion}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    CreateNewQuestion,
    GetAllQuestions,
    DeleteOneQuestion,
    EditNewQuestion,
    DeleteAllQuestions,
};
