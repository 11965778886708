// libraries
import styled from 'styled-components';

// colors
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// interfaces
interface ButtonColor {
    backgroundColor: boolean,
    disabled: boolean,
}

// styles home
export const Home = styled.div`
    box-sizing: border-box;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// stuyles header
export const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
`;

export const ContainerImage = styled.img`
    width: 300px;
    margin-bottom: 20px;
`;

// styles inputs
export const ContainerInputs = styled.div`
    border: 1px solid ${colors.basic.blue};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: 'black';
`;

// styles Action
export const Action = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonEnter = styled.button<ButtonColor>`
    background-color: ${(props) => (props.backgroundColor === true ? 'blue' : colors.basic.blue)};
    width: 100%;
    height: 54px;
    border-radius: 26px;
    border: 1px solid ${colors.basic.blue};
    margin-top: 18px;
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    font-size: 16px;
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    :hover {
        background-color: ${(props) => (props.backgroundColor === true ? colors.basic.darkblue : '')};
    }
`;

export const CheckBox = styled.input`
    outline: 1px solid ${colors.basic.darkblue};
    width: 20px;
    height: 20px;
`;

export const TextCheckBox = styled.h4`
    color: ${colors.basic.darkblue};
    font-size: 16px;
`;

export const ContainerEmail = styled.input`
    width: 100%;
    height: 52px;
    border: none;
    background: transparent;
    font-size: 16px;
    border-radius: 6px;
    color: ${colors.basic.black};
    padding-left: 10px;

    /* styles placeholder */
    ::-webkit-input-placeholder {
    color: ${colors.basic.blue};
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    font-size: 16px;
    padding: 8px;
}
`;

export const ContainerPassword = styled.input`
    width: 100%;
    height: 52px;
    border: none;
    background: transparent;
    font-size: 16px;
    border-radius: 6px;
    color: ${colors.basic.black};
    padding-left: 10px;
    /* styles placeholder */
    ::-webkit-input-placeholder {
    color: ${colors.basic.blue};
    font-weight: ${consts.getFontWeight('Roboto-Bold')};
    font-size: 16px;
    padding: 8px;
}
`;

export const ForgotPassword = styled.h4`
    font-size: 16px;
    color: ${colors.basic.darkblue};
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
`;

export const InputIconPassword = styled.div`
    float: left;
    margin-right: 10px;
    width: 24px;
    border: none;
    cursor: pointer;
`;

export const Container = styled.div`
    min-width: 50%;

    @media(max-width: 700px){
        min-width: 70%;
    }
`;

export const FormArea = styled.form``;
