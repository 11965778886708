const isStaging = window.location.hostname.includes('staging');
const isBeta = window.location.hostname.includes('beta.adm.engequest');
const isProd = window.location.hostname.includes('adm.engequest');
const getBaseUrl = () => {
    if (isBeta) {
        return 'https://beta.api.engequest.com.br';
    }
    if (isProd) {
        return 'https://api.engequest.com.br';
    }
    if (isStaging) {
        return 'https://staging.engequestapi.dotcoding.com.br';
    }
    return 'https://development.engequestapi.dotcoding.com.br';
};
const baseUrl = getBaseUrl();

export default {
    baseUrl,
};
