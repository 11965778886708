/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
// import { FaLocationArrow } from 'react-icons/fa';

// components (navigation)
import ModalReports from './Modal/ModalReports';

// images
import userAdd from '../../assets/images/userAdd.png';
import iconReportsBlue from '../../assets/images/iconReportsBlue.png';
// import iconActived from '../../assets/images/iconActived.png';
/* TODO será usado em uma versão futura */
// import iconGear from '../../assets/images/iconGear.png';
// import iconBlue from '../../assets/images/iconBlue.png';
// import iconThreePoints from '../../assets/images/iconThreePoints.png';

// js
import {
    Header,
    LineTop,
    Container,
    InputShare,
    TextSegment,
    ContainerUsers,
    ContainerShare,
    UserNameTeacher,
    ImageIconSelect,
    UserDivComplete,
    TextSegmentUser,
    UserNameDepartment,
    ContainerDepartament,
    ContainerInputSearch,
    ContainerSpace,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // TextSelector,
    // ContainerRadio,
    // LineRadio,
    // ImageGear,
    // ButoonToAdd,
    // ThreePoints,
    // ContainerButtonAdd,
} from './ReportsStyles';
import reports from '../../services/reports';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// contexts
import { AuthContext } from '../../context/AuthContext';
// import colors from '../../styles/colors';

const ReportsPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleOutlineSearch = {
        paddingRight: 5,
        height: 40,
        cursor: 'pointer',
    };

    // useState
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [typeUser, setTypeUser] = useState(['active', 'inactive']);
    const [allUsersActive, setAllUsersActive] = useState('');
    const [allUsersInactive, setAllUsersInactive] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);

    // open modal Info
    const handleOpen = (infoUser: any) => {
        setOpen(true);
        setUserEdit(infoUser);
    };

    const handleClose = () => {
        setOpen(false);
        setUserEdit(null);
    };

    const closeModalProfile = (fastRefresh: boolean) => {
        handleClose();
        if (fastRefresh === true) {
            document.location.reload();
        }
    };

    // contexts
    const { user } = useContext(AuthContext);

    // methods
    async function RenderQuantityPagination(searchSelected: string, typeUserSelected: string) {
        const allUsersNotPage = await reports.GetAllReports(searchSelected || '', typeUserSelected || '', 0, 0, GetToken);
        const formatPagination = String(allUsersNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function ActionInitial() {
        const allUsers = await reports.GetAllReports('', '', 1, limit, GetToken);
        const allUsersNotPage = await reports.GetAllReports('', '', 0, 0, GetToken);
        setAllUsersActive(allUsersNotPage?.result?.filter((v: any) => !v.inactivated_at).length);
        setAllUsersInactive(allUsersNotPage?.result?.filter((v: any) => v.inactivated_at !== null).length);
        setDataFinal(allUsers.result);
        RenderQuantityPagination(search, '');
    }

    function RenderListItems(item: any) {
        const EditUser = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'view') {
                ActionInitial();
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Nome:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>Descrição:</TextThView>
                    {item?.description}
                </TextTd>
                <TextTd>
                    <TextThView>Id da Questão:</TextThView>
                    {item?.id_question}
                </TextTd>
                <TextTd>
                    <Select value="" onChange={EditUser}>
                        <MenuItem value="view">
                            <ImageIconSelect src={userAdd} />
                            <TextSelect>Visualizar</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionSearch() {
        setLoading(true);
        const validationType = typeUser?.length === 2 ? '' : typeUser?.[0] || '';
        const allUsers = await reports.GetAllReports(search, validationType, 1, limit, GetToken);
        setDataFinal(allUsers.result);
        RenderQuantityPagination(search, validationType);
        setLoading(false);
    }

    async function onChangePagination(event: any, pageSelected: number) {
        const allUsers = await reports.GetAllReports(search, typeUser?.length === 2 ? '' : typeUser?.[0] || '', pageSelected, limit, GetToken);
        setDataFinal(allUsers.result);
        setPageActual(pageSelected);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconReportsBlue} text="REPORTS" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todos</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por reports..."
                        onChange={changeInputShare}
                        value={search}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>NOME</TextTh>
                            <TextTh>DESCRIÇÃO</TextTh>
                            <TextTh>ID DA QUESTÃO</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {/* Modals */}
            {ModalReports(open, closeModalProfile, userEdit)}
        </Container>
    );
};
export default ReportsPage;
