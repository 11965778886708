/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import { getTime, lightFormat, addHours } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// images
import iconAddBlue from '../../assets/images/iconAddBlue.png';
import addImageUser from '../../assets/images/addImageUser.png';

// js
import {
    Home,
    Body,
    Header,
    ContainerHeader,
    ContainerNewCollaborator,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    ContainerHome,
    DivInputText,
    TextInput,
    InputText,
    Footer,
    ButtonExit,
    ButtonSave,
    ContainerTextUser,
    ContainerUser,
    ContainerImageUser,
    InputNullSelectedArchive,
    PhotoAdm,
} from './ModalProfileStyles';
import colors from '../../styles/colors';
import managers from '../../services/managers';
import uploads from '../../services/uploads';
import LoadingComponent from '../ModalLoading/LoadingComponent';

// contexts
import { AuthContext } from '../../context/AuthContext';

const ModalProfilePage: React.FC = (
    openModalProfile: any,
    closeModalProfile: any,
) => {
    // context
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    // style
    const adjustButtonPhoto = {
        width: 200,
        height: 200,
        borderRadius: 100,
        backgroundColor: colors.basic.darkblue,
    };

    // constants
    const valueInitialPhotoProfile = {
        eventFile: new Blob(),
        eventName: '',
        file: '',
    };
    const formatBirthDate = lightFormat(addHours(Number(user.birthDate || '987681600000'), 9), 'dd/MM/yyyy') || '';

    // methods
    const MaskPhone = (v: any) => {
        let valueInitial = v?.replace(/\D/g, '').replace(/^0/, '') || '';
        if (valueInitial.length > 11) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (valueInitial.length > 7) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (valueInitial.length > 2) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else if (v?.trim() !== '') {
            valueInitial = valueInitial.replace(/^(\d*)/, '($1');
        }
        return valueInitial;
    };

    const MaskIdentifier = (v: any) => {
        const cpfFormatted = v?.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2');
        return cpfFormatted;
    };

    // states
    const [photoProfile, setPhotoProfile] = useState(valueInitialPhotoProfile);
    const [name, setName] = useState(user.name || '');
    const [email, setEmail] = useState(user.email || '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [identifier, setIdentifier] = useState(MaskIdentifier(user.cpf));
    const [birthDate, setBirthDate] = useState(formatBirthDate || '');
    const [telephone, setTelephone] = useState(MaskPhone(user.telephone) || '');

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        const onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
            if (text === 'IDENTIDADE') {
                e.currentTarget.maxLength = 14;
                const formattedCpf = MaskIdentifier(e.currentTarget.value);
                setValue(formattedCpf);
            } else if (text === 'DATA DE NASCIMENTO') {
                e.currentTarget.maxLength = 10;
                const dataFormatted = e.currentTarget.value.replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{2})(\d)/, '$1/$2')
                    .replace(/(\d{4})(\d)/, '$1/$2');
                setValue(dataFormatted);
            } else if (text === 'TELEFONE') {
                const formatTelephone = MaskPhone(e.currentTarget.value);
                setValue(formatTelephone);
            } else {
                setValue(e.currentTarget.value);
            }
        };
        return (
            <InputText
                id="password"
                placeholder={subtext}
                value={value}
                onChange={onChangeInput}
                type={text === 'SENHA' || text === 'CONFIRMAR SENHA' ? 'password' : 'text'}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue: any) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    function InputArchive(event: any) {
        const newDataFiles = {
            eventFile: event.target.files[0],
            eventName: event.target.files[0].name,
            file: URL.createObjectURL(event.target.files[0]),
        };
        setPhotoProfile(newDataFiles);
    }

    async function EditUserAdm() {
        setLoading(true);
        const [day, month, year] = birthDate.split('/');
        const timeBirthDate = getTime(new Date(Number(year), Number(month) - 1, Number(day)));
        const uploadPhoto = await uploads.UploadFile(photoProfile.eventFile);
        const uploadPhotoProfile = photoProfile.file ? uploadPhoto.result : photoProfile.eventName;
        const editManager = await managers.EditOneManager(
            user?.id,
            uploadPhotoProfile,
            name,
            email,
            password,
            confirmPassword,
            identifier?.replace(/\D/g, ''),
            timeBirthDate,
            telephone.replace(/\D/g, ''),
            user.token,
        );
        // .then(() => {
        //     toast.success('Perfil editado com sucesso!');
        //     const objectInfoManager = localStorage.getItem('@Engequest:manager') || '';
        //
        //     setLoading(false);
        // })
        // .catch((err) => {
        //     toast.error(err.response.data.message);
        // });
        if (editManager.status === 200) {
            const objectInfoManager = localStorage.getItem('@Engequest:manager') || '';
            const newManager = {
                ...JSON.parse(objectInfoManager),
                id: user.id,
                photo: uploadPhotoProfile,
                name,
                email,
                cpf: identifier?.replace(/\D/g, ''),
                birthDate: timeBirthDate,
                telephone: telephone.replace(/\D/g, ''),
            };
            localStorage.setItem('@Engequest:manager', JSON.stringify(newManager));
            closeModalProfile(true);
            setLoading(false);
            toast.success('Perfil editado com sucesso!');
        }
    }

    const RenderScreen = () => (
        <>
            <Header>
                <ContainerHeader>
                    <ContainerNewCollaborator>
                        <IconBlue src={iconAddBlue} />
                        <TextNewCollaborator>Perfil</TextNewCollaborator>
                    </ContainerNewCollaborator>
                    <DivLine />
                </ContainerHeader>
            </Header>
            <ContainerUser>
                <Body>
                    {DivInput('NOME', 'Nome', name, setName)}
                    {DivInput('E-MAIL', 'Email', email, setEmail)}
                    {DivInput('IDENTIDADE', 'Identidade', identifier, setIdentifier)}
                    {DivInput('DATA DE NASCIMENTO', 'Data de nascimento', birthDate, setBirthDate)}
                    {DivInput('TELEFONE', 'Telefone', telephone, setTelephone)}
                    {DivInput('SENHA', 'Senha', password, setPassword)}
                    {DivInput('CONFIRMAR SENHA', 'Confirmar senha', confirmPassword, setConfirmPassword)}
                </Body>
                <Button style={adjustButtonPhoto}>
                    {photoProfile.file ? (
                        <PhotoAdm src={photoProfile.file} />
                    ) : null}
                    {!photoProfile.file && user.photo ? (
                        <PhotoAdm src={user.photo} />
                    ) : null}
                    {!photoProfile.file && !user.photo ? (
                        <ContainerTextUser>CS</ContainerTextUser>
                    ) : null}
                    <ContainerImageUser src={addImageUser} />
                    <InputNullSelectedArchive
                        accept="image/png, image/jpeg, image/jpg"
                        type="file"
                        onChange={InputArchive}
                    />
                </Button>
            </ContainerUser>
            <Footer>
                <ButtonExit onClick={closeModalProfile}>Cancelar</ButtonExit>
                <ButtonSave onClick={EditUserAdm}>Salvar Alterações</ButtonSave>
            </Footer>
        </>
    );

    // main
    return (
        <>
            { loading
                ? (
                    <>
                        <ToastContainer />
                        <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." />
                    </>
                )
                : (
                    <Modal
                        open={openModalProfile}
                        onClose={closeModalProfile}
                        closeAfterTransition={false}
                    >
                        <Home>
                            {RenderScreen()}
                        </Home>
                    </Modal>
                ) }
        </>

    );
};

export default ModalProfilePage;
