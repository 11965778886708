// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface TitleDefaultInterfaces {
    fontSize?: string,
    fontWeight?: string,
    color?: string,
    textDecoration?: string,
    marginLeft?: string,
    marginBottom?: string,
    marginRight?: string,
}

interface ContainerAdjustPricesInterfaces {
    flexDirection?: string,
    marginBottom?: string,
    widthContainer?: string,
    justifyContent?: string,
}

interface IconDescriptionInterfaces {
    height?: string,
    marginLeft?: string,
    width?: string,
}

interface ButtonActionInterfaces {
    disabledButton?: boolean,
    backgroundColor?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    /* justify-content: center; */
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
`;

export const ContainerSignatures = styled.div`
    background-color: ${colors.basic.white};
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    width: 30%;
    margin-bottom: 20px;
    @media (max-width: 800px) {
        width: 90%;
    }
`;

export const Title = styled.text<TitleDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    text-decoration: ${(props) => (props.textDecoration || 'none')};
    margin-left: ${(props) => (props.marginLeft || '0px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
`;

export const ContainerAdjustPrices = styled.div<ContainerAdjustPricesInterfaces>`
    align-items: center;
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    flex-direction: ${(props) => (props.flexDirection || 'row')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    width: ${(props) => (props.widthContainer || 'auto')};
`;

export const ButtonAction = styled.button<ButtonActionInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || colors.basic.blueDefault)};
    margin-top: 20px;
    border-radius: 20px;
    border: none;
    cursor: ${(props) => (props.disabledButton ? 'default' : 'pointer')};
    display: flex;
    margin-bottom: 20px;
    height: 40px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    width: 140px;
`;

export const IconDescription = styled.img<IconDescriptionInterfaces>`
    object-fit: contain;
    margin-left: ${(props) => (props.marginLeft || '0px')};
    height: ${(props) => (props.height || '48px')};
    width: ${(props) => (props.width || '48px')};
`;

export const ContainerDiscount = styled.div`
    align-items: center;
    background-color: ${colors.theme.blackDiscount};
    display: flex;
    flex-direction: column;
    transform: rotate(45deg);
    justify-content: center;
    border-radius: 50% 50% 50% 5px;
    width: 55px;
    height: 55px;
`;

export const TextDiscount = styled.text`
    color: ${colors.basic.yellow};
    font-size: 12px;
    font-weight: bold;
    transform: rotate(-45deg);
`;

export const ContainerIconSignature = styled.div`
    align-items: center;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 5px 15px 5px 15px;
    margin-bottom: 20px;
`;

export const IconSignature = styled.img`
    object-fit: contain;
    height: 30px;
    width: 30px;
`;
