/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
} from './ModalManageStyles';
import book from '../../../../services/book';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    nameBook: string,
    setNameBook: any,
    author: string,
    setAuthor: (v: string) => void,
    edition: string,
    setEdition: (v: string) => void,
    placeOfPublication: string,
    setPlaceOfPublication: (v: string) => void,
    publishingCompany: string,
    setPublishingCompany: (v: string) => void,
    year: string,
    setYear: (v: string) => void,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const [loading, setLoading] = useState(false);

    // methods
    async function CreateOrEditCategories() {
        setLoading(true);
        if (!userEdit?.id) {
            await book.CreateNewBook(
                nameBook,
                author,
                edition,
                placeOfPublication,
                publishingCompany,
                year,
                GetToken,
            )
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Livro criado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        } else {
            await book.EditOneBook(
                nameBook,
                author,
                edition,
                placeOfPublication,
                publishingCompany,
                year,
                userEdit?.id,
                GetToken,
            )
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    toast.success('Livro editado com sucesso!');
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    }

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            if (text === 'ANO' || text === 'EDIÇÃO') {
                setValue(txt.target.value.replace(/[^0-9]/g, ''));
            } else {
                setValue(txt.target.value);
            }
        };

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type="text"
                onChange={onChangeText}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue: any) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition={false}
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Novo Livro</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                {DivInput('NOME', 'Nome', nameBook, setNameBook)}
                                {DivInput('AUTOR', 'Autor', author, setAuthor)}
                                {DivInput('EDIÇÃO', 'Edição', edition, setEdition)}
                            </Body>
                            <Body>
                                {DivInput('LOCAL DE PUBLICAÇÃO', 'Local de publicação', placeOfPublication, setPlaceOfPublication)}
                                {DivInput('EDITORA', 'Editora', publishingCompany, setPublishingCompany)}
                                {DivInput('ANO', 'Ano', year, setYear)}
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateOrEditCategories}>{!userEdit?.id ? 'Criar livro' : 'Salvar livro'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}

        </>

    );
};

export default modalManagePage;
