/* eslint-disable no-multi-spaces */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FlatList from 'flatlist-react';
import Switch from '@material-ui/core/Switch';
import { Select, MenuItem } from '@material-ui/core';

// icons
import { AiOutlineSearch } from 'react-icons/ai';
import { RiMenuAddFill, RiFilterFill } from 'react-icons/ri';

// components
import plansConstants from './plansConstants';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// colors
import colors from '../../styles/colors';

// images
import iconGear from '../../assets/images/iconGear.png';

// js
import {
    Home,
    Header,
    Body,
    ContainerShareInput,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerDepartament,
    TextSegmentUser,
    TextSegment,
    UserDivComplete,
    ContainerUsers,
    UserNameTeacher,
    FormGroup,
    LineTop,
    ContainerPlansName,
    ContainerButtonAdd,
    ButoonToAdd,
    ContainerButtonBlue,
    ButoonToAddBlue,
    ContainerButton,
    ImageGear,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './plansStyles';

const UsersPage: React.FC = () => {
    // styles
    const styleOutlineSearch = { paddingRight: 5 };
    const styleSelect = { marginLeft: 74 };
    const styleRiFilterFill = { marginRight: 10 };

    // states
    const [dataFinal] = useState([...plansConstants.plansConstantesName]);
    // const [userEdit, setUserEdit] = useState<any>();

    const PurpleSwitch = withStyles({
        switchBase: {
            color: '#ffffff',
            '&$checked': {
                color: '#63D600',
            },
            '&$checked + $track': {
                backgroundColor: '#63D600',
            },
        },
        checked: {},
        track: {},
    })(Switch);

    function RenderListItems(item: any) {
        return (
            <UserDivComplete>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" /> */}
                <ContainerUsers>
                    <UserNameTeacher>{item?.name}</UserNameTeacher>
                    <FormGroup>
                        <PurpleSwitch />
                        <PurpleSwitch />
                        <PurpleSwitch />
                    </FormGroup>
                    <Select value="" style={styleSelect}>
                        <MenuItem />
                    </Select>
                </ContainerUsers>
            </UserDivComplete>
        );
    }

    return (
        <Home>
            <Header>
                <HeaderComponent text="GERENCIAR PLANOS" />
                <LineTop />
            </Header>
            <Body>
                <ContainerShareInput>
                    <ContainerShare>
                        {/* TODO será usado em uma versão futura */}
                        {/* <ContainerRadio type="radio" />
                        <TextSelector>Selecionar todos</TextSelector> */}
                        <ContainerInputSearch>
                            <InputShare
                                placeholder="Procure por enunciado, matéria, data, professor, concurso..."
                            />
                            <AiOutlineSearch
                                color={colors.basic.blue}
                                size={26}
                                style={styleOutlineSearch}
                            />
                        </ContainerInputSearch>
                        <ContainerButton>
                            <ContainerButtonBlue>
                                <ButoonToAddBlue>
                                    <RiFilterFill style={styleRiFilterFill} color={colors.basic.white} />
                                    Filtrar Benefício
                                </ButoonToAddBlue>
                            </ContainerButtonBlue>
                            <ContainerButtonAdd>
                                <ButoonToAdd>
                                    <RiMenuAddFill style={styleRiFilterFill} color={colors.basic.white} />
                                    Adicionar Novo Benefício
                                </ButoonToAdd>
                            </ContainerButtonAdd>
                            <ImageGear src={iconGear} />
                        </ContainerButton>
                    </ContainerShare>
                </ContainerShareInput>
            </Body>
            <ContainerDepartament>
                <TextSegmentUser>BENEFÍCIOS</TextSegmentUser>
                <ContainerPlansName>
                    <TextSegment>PLANO A</TextSegment>
                    <TextSegment>PLANO B</TextSegment>
                    <TextSegment>PLANO C</TextSegment>
                </ContainerPlansName>
            </ContainerDepartament>
            <FlatList
                list={dataFinal}
                renderItem={RenderListItems}
            />
        </Home>
    );
};
export default UsersPage;
