// libraries
import styled from 'styled-components';

// colors
import colors from '../../styles/colors';

export const Container = styled.div`
    background-color: ${colors.basic.white};
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Header = styled.div`
    background-color: ${colors.basic.white};
    height: 30vh;
    width: 100%;
    box-shadow: 1px 1px 3px gray;
`;

export const ContainerComments = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
`;

interface CardCommentsInterfaces {
    width?: string,
}

export const CardComments = styled.div<CardCommentsInterfaces>`
    box-shadow: 1px 1px 3px gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 180px;
    /* position: relative; */
    /* z-index: 1; */
    margin-bottom: 10px;
    width: ${(props) => props.width || '32%'};

    @media (max-width: 900px) {
        width: 46%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const ContainerQuestion = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80%;
    width: 30%;
    border-right: 1px solid ${colors.basic.blue};
    font-size: 14px;
    color: ${colors.basic.blue};
`;

export const PhotoUser = styled.img`
    min-height: 30px;
    max-height: 30px;
    border-radius: 15px;
    min-width: 30px;
    max-width: 30px;
    margin-right: 5px;
`;

export const PhotoComment = styled.img`
    min-height: 100px;
    max-height: 100px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 5px;
    margin-top: 5px;
`;

export const ContainerQtd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.basic.blueDefault};
    border-radius: 23px;
    color: ${colors.basic.white};
    margin-top: 5px;
    min-height: 46px;
    min-width: 46px;
    font-size: 12px;
`;

interface ContainerDescriptionInterfaces {
    marginBottom?: string,
}

export const ContainerDescription = styled.div<ContainerDescriptionInterfaces>`
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 80%;
    width: 65%;
    font-size: 14px;
    position: relative;
    margin-bottom: ${(props) => props.marginBottom || '0px'};
    color: ${colors.basic.blue};
`;

export const ContainerVerified = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ImgVerified = styled.img`
    height: 11px;
    margin-left: 5px;
    cursor: pointer;
    width: 11px;
`;

export const Input = styled.input`
    height: 40px;
    margin-left: 5px;
    font-size: 12px;
    border: none;
    color: ${colors.basic.blueDefault};
    width: 70%;
    margin-bottom: 20px;
    align-self: flex-end;
    padding: 5px;
    border-bottom: 1px solid ${colors.basic.blue};
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    position: relative;
    overflow-y: auto;
    padding: 10px;
    z-index: 10;
`;

export const ContainerChildrenComments = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
`;

export const ContainerCardSelected = styled.div`
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
`;

export const TitleStatement = styled.text`
    font-size: 20px;
    color: ${colors.basic.darkblue};
    font-weight: bold;
`;

export const TitleComment = styled.text`
    font-size: 10px;
    color: ${colors.basic.darkblue};
    margin-right: 5px;
`;

export const TitleAnswer = styled.text`
    font-size: 10px;
    color: ${colors.basic.darkblue};
    cursor: pointer;
    margin-top: 5px;
`;

export const TimeComment = styled.text`
    font-size: 8px;
    color: ${colors.basic.grayLight};
`;

export const DescriptionComment = styled.text`
    font-size: 12px;
    color: ${colors.basic.blackMedium};
    overflow: hidden; // Removendo barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 2; // Quantidade de linhas
    -webkit-box-orient: vertical;
`;

export const ContainerHeaderDescription = styled.div`
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
`;

export const BoxFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    box-shadow: 1px 1px 3px gray;
    width: 220px;

    @media (max-width: 1200px) {
        width: 48%;
    }
`;

interface ContainerFiltersInterfaces {
    marginTop?: string;
    marginBottom?: string;
}

export const ContainerFilters = styled.div<ContainerFiltersInterfaces>`
    display: flex;
    align-items: center;
    margin-top: ${({ marginTop }) => marginTop || '40px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '50px'};
    width: 90%;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const InputSearch = styled.input`
    width: 85%;
    height: 40px;
    border: none;
    font-size: 14px;
    color: ${colors.basic.darkblue};
    padding-left: 5px;

    ::-webkit-input-placeholder{
        /* padding: 8px; */
        color: ${colors.basic.blue};
    }
`;

export const ContainerSeparator = styled.div`
    display: flex;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
        width: 100%;
    }
`;

export const IconSearch = styled.img``;

export const ContainerSelect = styled.select`
    display: inline;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    color: ${colors.basic.darkblue};
    background-color: transparent;
    font-size: 14px;
    /* padding-left: 10px; */

    /* styles placeholder */
    ::-webkit-input-placeholder {
        color: ${colors.basic.blue};
        font-size: 14px;
    }
`;

export const ContainerOption = styled.option``;

interface ButtonSendInterfaces {
    outline?: boolean
}

export const ButtonSend = styled.div<ButtonSendInterfaces>`
    background-color: ${(props) => (props.outline ? colors.basic.white : colors.basic.blueDefault)};
    color: ${(props) => (props.outline ? colors.basic.blueDefault : colors.basic.white)};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px 5px 10px;
    border: 1px solid ${colors.basic.blueDefault};
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
    border-radius: 6px;
    width: 124px;
    height: 30px;
`;

export const ContainerAdjustButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AdjustInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const TextTd = styled.td`
    padding: 12px 15px;
    color: ${colors.basic.lightgray};
    word-wrap: break-word;
    /* position: absolute; */
    /* right: 0px; */
    /* top: 0px; */
    /* z-index: 2; */

    @media (max-width: 1070px) {
        font-size: 12px;
    }

    @media (max-width: 800px) {
        padding: 10px 15px;
        display: -webkit-box;
        font-size: 14px;
    }
`;

export const ImageIconSelect = styled.img`
    width: 24px;
    margin-right: 8px;
`;

export const TextSelect = styled.text``;

export const PhotoAdm = styled.img`
    border-radius: 5px;
    height: 100px;
    width: 200px;
`;

export const ContainerTextUser = styled.div`
    font-size: 100px;
    font-weight: 600;
`;

export const InputNullSelectedArchive = styled.input`
    width: 100%;
    height: 100px;
    position: absolute;
    opacity: 0;
    margin-bottom: 50px;
    background-color: black;
    cursor: pointer;
`;
