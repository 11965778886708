/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import { lightFormat } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import iconCategoriBlue from '../../assets/images/iconCategoriBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './essayStyles';
import colors from '../../styles/colors';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import essay from '../../services/essay';

const essayPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [titleEssey, setTitleEssey] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setTitleEssey(infoUser.description);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
        }
    };

    async function RenderQuantityPagination(searchSelected: string) {
        const allEssayNotPage = await essay.GetAllEssay(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String(allEssayNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionInitial() {
        const allEssay = await essay.GetAllEssay('', 1, limit, GetToken);
        setDataFinal(allEssay.result);
        RenderQuantityPagination(search);
    }

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteEssay') {
                const validation = await essay.DeleteOneEssay(item?.id, GetToken);
                if (validation.status === 200) {
                    ActionInitial();
                    toast.success('Redação deletada com sucesso!');
                }
            }
            if (valueSelected === 'EditEssay') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>Título:</TextThView>
                    {item?.description}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {item?.created_at ? lightFormat(new Date(item.created_at), 'dd/MM/yyyy HH:mm') : ''}
                </TextTd>
                <TextTd>
                    <TextThView>Atualizado em:</TextThView>
                    {item?.updated_at ? lightFormat(new Date(item.updated_at), 'dd/MM/yyyy HH:mm') : ''}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditEssay">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteEssay">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir redação</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    async function onChangePagination(event: any, pageSelected: number) {
        const allEssay = await essay.GetAllEssay(search, pageSelected, limit, GetToken);
        setDataFinal(allEssay.result);
        setPageActual(pageSelected);
    }

    async function ActionSearch() {
        setLoading(true);
        const allEssay = await essay.GetAllEssay(search, 1, limit, GetToken);
        setDataFinal(allEssay.result);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconCategoriBlue} text="GERENCIAR TEMAS DE REDAÇÕES" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título das redações..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Nova Redação</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>ATUALIZADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            {ModalManagePage(open, handleClose, userEdit, titleEssey, setTitleEssey)}
        </Container>
    );
};

export default essayPage;
