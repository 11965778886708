/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import FlatList from 'flatlist-react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// images
import userAdd from '../../assets/images/userAdd.png';
import userDelete from '../../assets/images/userDelete.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import imageDisable from '../../assets/images/imageDisable.png';
import iconActived from '../../assets/images/iconActived.png';
import iconUserBlue from '../../assets/images/iconUserBlue.png';
/* TODO será usado em uma versão futura */
// import iconGear from '../../assets/images/iconGear.png';
// import iconBlue from '../../assets/images/iconBlue.png';
// import iconThreePoints from '../../assets/images/iconThreePoints.png';

// js
import {
    Header,
    DivLine,
    ImgUser,
    LineTop,
    Container,
    InputShare,
    TextDefault,
    ContainerShare,
    ContainerAcess,
    IconUserTeacher,
    ImageIconSelect,
    ContainerUserAll,
    TextNumberActive,
    TextTeacherActive,
    ContainerInputUser,
    ContainerInputSearch,
    TextSelect,
    ImgDefault,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // TextSelector,
    // ContainerRadio,
    // LineRadio,
    // ImageGear,
    // ButoonToAdd,
    // ThreePoints,
    // ContainerButtonAdd,
} from './usersStyles';
import usersServices from '../../services/users';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// import colors from '../../styles/colors';

const UsersPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleOutlineSearch = {
        paddingRight: 5,
        height: 40,
        cursor: 'pointer',
    };

    // useState
    const [dataFinal, setDataFinal] = useState<any[]>();
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [openProfile, setOpenProfile] = useState<any>();
    const [typeUser, setTypeUser] = useState(['active', 'inactive']);
    const [allUsersActive, setAllUsersActive] = useState('');
    const [allUsersInactive, setAllUsersInactive] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [loading, setLoading] = useState(false);

    // open modal Info
    const handleOpen = (infoUser: any) => {
        setOpen(true);
        setUserEdit(infoUser);
    };

    const handleClose = () => {
        setOpen(false);
        setUserEdit(null);
    };

    // open modal Profile
    const openModalProfile = () => {
        setOpenProfile(true);
    };

    const closeModalProfile = (fastRefresh: boolean) => {
        setOpenProfile(false);
        if (fastRefresh === true) {
            document.location.reload();
        }
    };

    // methods
    async function RenderQuantityPagination(searchSelected: string, typeUserSelected: string) {
        const allUsersNotPage = await usersServices.GetAllUsers(searchSelected || '', typeUserSelected || '', 0, 0, GetToken);
        const formatPagination = String(allUsersNotPage.result.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function ActionInitial() {
        const allUsers = await usersServices.GetAllUsers('', '', 1, limit, GetToken);
        const allUsersNotPage = await usersServices.GetAllUsers('', '', 0, 0, GetToken);
        setAllUsersActive(allUsersNotPage?.result?.filter((v: any) => !v.inactivated_at).length);
        setAllUsersInactive(allUsersNotPage?.result?.filter((v: any) => v.inactivated_at !== null).length);
        setDataFinal(allUsers.result);
        RenderQuantityPagination(search, '');
    }

    function renderUser(img: string, title: string, subtitle: string, index: number) {
        const TradeInfo = async () => {
            if (index === 1) {
                if (typeUser.includes('active')) {
                    setTypeUser(['inactive']);
                    const allUsers = await usersServices.GetAllUsers(search, 'inactive', 1, limit, GetToken);
                    setDataFinal(allUsers.result);
                    RenderQuantityPagination(search, 'inactive');
                } else {
                    setTypeUser(['active', ...typeUser]);
                    const validationTypeUser = ['active', ...typeUser]?.length === 2 ? '' : ['active', ...typeUser]?.[0] || '';
                    const allUsers = await usersServices.GetAllUsers(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allUsers.result);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            } else if (index === 2) {
                if (typeUser.includes('inactive')) {
                    setTypeUser(['active']);
                    const allUsers = await usersServices.GetAllUsers(search, 'active', 1, limit, GetToken);
                    setDataFinal(allUsers.result);
                    RenderQuantityPagination(search, 'active');
                } else {
                    setTypeUser([...typeUser, 'inactive']);
                    const validationTypeUser = [...typeUser, 'inactive']?.length === 2 ? '' : [...typeUser, 'inactive']?.[0] || '';
                    const allUsers = await usersServices.GetAllUsers(search, validationTypeUser, 1, limit, GetToken);
                    setDataFinal(allUsers.result);
                    RenderQuantityPagination(search, validationTypeUser);
                }
            }
        };

        return (
            <ContainerUserAll>
                <ContainerInputUser onClick={TradeInfo}>
                    <IconUserTeacher src={img} />
                    <TextTeacherActive>{title}</TextTeacherActive>
                    <TextNumberActive>
                        <TextDefault>{subtitle}</TextDefault>
                    </TextNumberActive>
                </ContainerInputUser>
                {typeUser.includes(index === 1 ? 'active' : 'inactive') ? <DivLine /> : null}
            </ContainerUserAll>
        );
    }

    function RenderListItems(item: any) {
        const onClick = () => {
            handleOpen(item);
        };

        const EditUser = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteUser') {
                await usersServices.EditOneUser('deleted', item.id, GetToken);
                ActionInitial();
                toast.success('Usuário deletado com sucesso!');
            }
            if (valueSelected === 'InactiveUser') {
                await usersServices.EditOneUser('inactivated', item.id, GetToken);
                ActionInitial();
                toast.success('Usuário desativado com sucesso!');
            }
            if (valueSelected === 'actived') {
                await usersServices.EditOneUser('actived', item.id, GetToken);
                ActionInitial();
                toast.success('Usuário ativado com sucesso!');
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    {item?.photo ? (
                        <ImgUser src={item?.photo} />
                    ) : (
                        <ImgDefault />
                    )}
                </TextTd>
                <TextTd>
                    <TextThView>Nome:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>E-mail:</TextThView>
                    {item?.email}
                </TextTd>
                <TextTd>
                    <TextThView>Telefone:</TextThView>
                    {item?.telephone}
                </TextTd>
                <TextTd>
                    <TextThView>Identidade:</TextThView>
                    {item?.cpf}
                </TextTd>
                <TextTd>
                    <TextThView>Data de nascimento:</TextThView>
                    {item?.birth_date}
                </TextTd>
                <TextTd>
                    <TextThView>Status:</TextThView>
                    {item?.inactivated_at ? 'inativo' : 'ativo'}
                </TextTd>
                <TextTd>
                    <Select value="" onChange={EditUser}>
                        { item?.inactivated_at ? (
                            <MenuItem value="actived">
                                <ImageIconSelect src={iconActived} />
                                <TextSelect>Ativar usuário</TextSelect>
                            </MenuItem>
                        ) : (
                            <MenuItem value="InactiveUser">
                                <ImageIconSelect src={imageDisable} />
                                <TextSelect>Desativar usuário</TextSelect>
                            </MenuItem>
                        ) }

                        <MenuItem value="DeleteUser">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir usuário</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function ActionSearch() {
        setLoading(true);
        const validationType = typeUser?.length === 2 ? '' : typeUser?.[0] || '';
        const allUsers = await usersServices.GetAllUsers(search, validationType, 1, limit, GetToken);
        setDataFinal(allUsers.result);
        RenderQuantityPagination(search, validationType);
        setLoading(false);
    }

    async function onChangePagination(event: any, pageSelected: number) {
        const allUsers = await usersServices.GetAllUsers(search, typeUser?.length === 2 ? '' : typeUser?.[0] || '', pageSelected, limit, GetToken);
        setDataFinal(allUsers.result);
        setPageActual(pageSelected);
    }

    // useEffect
    useEffect(() => {
        setTimeout(() => {
            ActionInitial();
        }, 500);
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconUserBlue} text="USUÁRIOS" />
                <LineTop />
                <ContainerAcess>
                    {renderUser(userAdd, 'Usuários Ativos', allUsersActive || '0', 1)}
                    {renderUser(userDelete, 'Inativos', allUsersInactive || '0', 2)}
                </ContainerAcess>
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todos</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por professores, cargo, departamento..."
                        onChange={changeInputShare}
                        value={search}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh />
                            <TextTh>NOME</TextTh>
                            <TextTh>EMAIL</TextTh>
                            <TextTh>TELEPHONE</TextTh>
                            <TextTh>IDENTIDADE</TextTh>
                            <TextTh>DATA DE NASCIMENTO</TextTh>
                            <TextTh>STATUS</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
        </Container>
    );
};
export default UsersPage;
