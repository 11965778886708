/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Modal, Fade, Button } from '@material-ui/core';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputTextBox,
    ButtonSave,
    ContainerNewCollaborator,
    ContainerFilters,
    ContainerCategoriesSelected,
    IconChecked,
    ContainerCicleChecked,
    TxtCategoriesSelected,
    ContainerAllInputs,
    ContainerTest,
    SelectEmoji,
    InputNullSelectedArchive,
    ContainerArchive,
    TextArchive,
} from './ModalManageStyles';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import checkCircleBlueSea from '../../../../assets/images/checkCircleBlueSea.png';
import selectEmoji from '../../../../assets/images/selectEmoji.png';
import uploads from '../../../../services/uploads';
import notifications from '../../../../services/notifications';

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    title: string,
    setTitle: any,
    subtitle: string,
    setSubtitle: any,
    description: string,
    setDescription: any,
    archiveProp: any,
    setArchive: any,
    subjectSelected: any,
    setSubjectSelected: any,
    selectInput: any,
    setSelectInput: any,
    allExamination: any,
) => {
    const archive = !archiveProp ? {
        eventFile: {},
        eventName: 'Importar Vídeo ou Imagem',
        file: null,
    } : archiveProp;
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const [loading, setLoading] = useState(false);
    const adjustButtonArchive = { width: '100%' };

    // methods
    function DivInput(text: string, subtext: string, value: any, setValue: any) {
        const onChangeText = (txt: React.ChangeEvent<HTMLTextAreaElement>) => {
            setValue(txt.target.value);
        };

        const onEmojiClick = (event: any, emojiObject: any) => {
            setValue(`${value || ''} ${emojiObject.emoji} `);
        };

        const OpenPicker = () => {
            setSelectInput(text === selectInput ? '' : text);
        };

        async function SelectedArchive(event: any) {
            let newDataFiles = {
                eventFile: null,
                eventName: null,
                file: '',
            };
            if (event.target.files.length) {
                newDataFiles = {
                    eventFile: event.target.files[0],
                    eventName: event.target.files[0].name,
                    file: URL.createObjectURL(event.target.files[0]),
                };
            }
            if (newDataFiles.eventFile) {
                const uploadPhoto = await uploads.UploadFile(newDataFiles.eventFile);
                setValue(uploadPhoto.result);
            }
        }

        const RenderEventName = () => {
            if (value?.eventName === 'Importar Vídeo ou Imagem') {
                return value.eventName;
            }
            return `${String(value)?.substring(0, 25)}...`;
        };

        return (
            <ContainerAllInputs>
                <ContainerHome style={{ alignItems: text !== 'MÍDIA' ? 'flex-start' : 'center', justifyContent: text !== 'MÍDIA' ? 'flex-start' : 'center' }}>
                    <TextInput>{text}</TextInput>
                    {text !== 'MÍDIA' ? (
                        <>
                            <InputTextBox
                                placeholder={subtext}
                                value={value}
                                onChange={onChangeText}
                            />
                            <SelectEmoji src={selectEmoji} onClick={OpenPicker} />
                        </>
                    ) : (
                        <ContainerTest>
                            <Button style={adjustButtonArchive}>
                                <InputNullSelectedArchive
                                    accept={'video/mp4, video/x-m4v, video/*, image/png, image/jpeg, image/jpg'}
                                    onChange={SelectedArchive}
                                    type="file"
                                />
                                <ContainerArchive>
                                    <TextArchive>{RenderEventName()}</TextArchive>
                                </ContainerArchive>
                            </Button>
                        </ContainerTest>
                    )}
                </ContainerHome>
                {selectInput === text ? (
                    <Picker
                        onEmojiClick={onEmojiClick}
                        disableAutoFocus
                        skinTone={SKIN_TONE_MEDIUM_DARK}
                        groupNames={{ smileys_people: 'PEOPLE' }}
                        pickerStyle={{ position: 'absolute', left: '50%' }}
                        native
                    />
                ) : null}
            </ContainerAllInputs>
        );
    }

    async function CreateOrEditSubject() {
        setLoading(true);
        if (title && subtitle && description) {
            const editArchive = typeof archive === 'string' ? archive : '';
            if (!userEdit?.id) {
                await notifications.CreateNewEmitNotification(title, subtitle, description, subjectSelected, editArchive, 'timeline', GetToken)
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        toast.success('Timeline criada com sucesso!');
                    })
                    .catch((err) => {
                        setLoading(false);
                        toast.error(err.response.data.message);
                    });
            } else {
                await notifications.EditEmitNotification(userEdit?.id, title, subtitle, description, subjectSelected, editArchive, 'timeline', GetToken)
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        toast.success('Timeline editada com sucesso!');
                    })
                    .catch((err) => {
                        setLoading(false);
                        toast.error(err.response.data.message);
                    });
            }
        } else {
            setLoading(false);
            alert('Preencha todos os campos.');
        }
    }

    const handleClickSubject = (v: any) => {
        if (subjectSelected?.filter((item: any) => item.id === v.id).length) {
            const removeItem = subjectSelected?.filter((item: any) => item.id !== v.id);
            setSubjectSelected(removeItem);
        } else {
            setSubjectSelected([...subjectSelected || [], { name: v.name, id: v.id }]);
        }
    };

    const RenderAllSubjects = () => (
        allExamination.map((v: any) => (
            <Fade in>
                <ContainerCategoriesSelected
                    onClick={() => {
                        handleClickSubject(v);
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        {subjectSelected?.filter((item: any) => item.id === v.id).length ? (
                            <IconChecked src={checkCircleBlueSea} />
                        ) : (
                            <ContainerCicleChecked />
                        )}
                        <TxtCategoriesSelected>{v.name}</TxtCategoriesSelected>
                    </div>
                </ContainerCategoriesSelected>
            </Fade>
        ))
    );

    // main
    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Nova Timeline</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                {DivInput('TÍTULO', 'Título', title, setTitle)}
                                {DivInput('SUBTÍTULO', 'Subtítulo', subtitle, setSubtitle)}
                                {DivInput('DESCRIÇÃO', 'Descrição', description, setDescription)}
                                {DivInput('MÍDIA', 'Mídia', archive, setArchive)}
                                <ContainerFilters>
                                    {RenderAllSubjects()}
                                </ContainerFilters>
                            </Body>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={CreateOrEditSubject}>{!userEdit?.id ? 'Criar timeline' : 'Salvar timeline'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default modalManagePage;
