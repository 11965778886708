/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import FlatList from 'flatlist-react';
import '../../../node_modules/react-vis/dist/style.css';
import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    LineSeries,
    DiscreteColorLegend,
    VerticalGridLines,
    VerticalBarSeries,
    LabelSeries,
} from 'react-vis';

// components (navigation)
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
/* TODO será usado em uma versão futura */
// import iconGear from '../../assets/images/iconGear.png';
// import iconBlue from '../../assets/images/iconBlue.png';
// import iconThreePoints from '../../assets/images/iconThreePoints.png';

// js
import {
    Header,
    Container,
    ContainerUsers,
    UserDivComplete,
    TitleChart,
    DescriptionChart,
    /* TODO será usado em uma versão futura */
    // TextSelector,
    // ContainerRadio,
    // LineRadio,
    // ImageGear,
    // ButoonToAdd,
    // ThreePoints,
    // ContainerButtonAdd,
} from './statisticsStyles';

// contexts
import { AuthContext } from '../../context/AuthContext';
import result from './statisticsConstants';

const statisticsPage: React.FC = () => {
    // constants
    const stylesXAxis = { text: { stroke: 'none', fontSize: 10, fontWeight: 600 } };

    // useState
    const [dataFinal, setDataFinal] = useState(result);
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [openProfile, setOpenProfile] = useState<any>();

    // open modal Info
    const handleOpen = (infoUser: any) => {
        setOpen(true);
        setUserEdit(infoUser);
    };

    const handleClose = () => {
        setOpen(false);
        setUserEdit(null);
    };

    // open modal Profile
    const openModalProfile = () => {
        setOpenProfile(true);
    };

    const closeModalProfile = (fastRefresh: boolean) => {
        setOpenProfile(false);
        if (fastRefresh === true) {
            document.location.reload();
        }
    };

    // contexts
    const { user } = useContext(AuthContext);

    function RenderListItems(item: any) {
        return (
            <ContainerUsers>
                <TitleChart>{item.name}</TitleChart>
                <DiscreteColorLegend width={350} height={100} items={item.legend} />
                {item.type === 'line' ? (
                    <XYPlot width={350} height={300} margin={{ right: 50 }} xType="ordinal">
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        <XAxis style={stylesXAxis} />
                        <YAxis style={stylesXAxis} />
                        {item.result.map((v: any) => (
                            <LineSeries data={v} />
                        ))}
                    </XYPlot>
                ) : null}
                {item.type === 'column' ? (
                    <XYPlot width={300} height={300} xDistance={100} xType="ordinal">
                        <VerticalGridLines />
                        <HorizontalGridLines />
                        <XAxis style={stylesXAxis} />
                        <YAxis style={stylesXAxis} />
                        {item.result.map((v: any) => (
                            <VerticalBarSeries data={v} barWidth={0.3} />
                        ))}
                    </XYPlot>
                ) : null}
                <DescriptionChart>{item.description}</DescriptionChart>
            </ContainerUsers>
        );
    }

    return (
        <Container>
            <Header>
                <HeaderComponent text="ESTATÍSTICAS" />
            </Header>
            <UserDivComplete>
                <FlatList
                    list={dataFinal}
                    renderItem={RenderListItems}
                />
            </UserDivComplete>
        </Container>
    );
};
export default statisticsPage;
