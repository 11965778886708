const allSelect = [
    {
        value: '2',
        options: ['A', 'B'],
    },
    {
        value: '3',
        options: ['A', 'B', 'C'],
    },
    {
        value: '4',
        options: ['A', 'B', 'C', 'D'],
    },
    {
        value: '5',
        options: ['A', 'B', 'C', 'D', 'E'],
    },
    {
        value: '6',
        options: ['A', 'B', 'C', 'D', 'E', 'F'],
    },
    {
        value: '7',
        options: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    },
    {
        value: '8',
        options: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
    },
];

export default allSelect;
