const toPrice = (price: number, scale?: number | 2): string => (
    String(((price * 100) / 100).toFixed(scale)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
);

const MaskIdentifier = (v: string): string => {
    const cpfFormatted = v?.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2');
    return cpfFormatted;
};

const MaskPhone = (v: string): string => {
    let valueInitial = v.replace(/\D/g, '').replace(/^0/, '');
    if (valueInitial.length > 11) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valueInitial.length > 7) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valueInitial.length > 2) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (v.trim() !== '') {
        valueInitial = valueInitial.replace(/^(\d*)/, '($1');
    }
    return valueInitial;
};

// functions
async function getDownloadRemoteFile(file: string): Promise<null> {
    // get file in Uint8Array
    const response = await fetch(file);
    const reader = response.body?.getReader();
    const result = await reader?.read();

    // Uint8Array ==> String
    const decoder = new TextDecoder('utf-8');
    const csv = decoder.decode(result?.value);

    // download file
    const fileBlob = new Blob([csv], { type: 'application/pdf' });
    const csvURL = window.URL.createObjectURL(fileBlob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.download = file;
    tempLink.click();

    return null;
}

async function downloadPDF(pdf: string, type: string): Promise<null> {
    const base64RegExp = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;
    const isBase64 = (str) => base64RegExp.test(str);

    if (isBase64(pdf)) {
        const linkSource = `data:application/docx;base64,${test}`;
        const downloadLink = document.createElement('a');
        const fileName = `${type}.docx`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    } else {
        alert('Questão não possui arquivo preparado. Realize o upload do " + type + " novamente para resolver.');
    }
    return null;
}

export default {
    toPrice,
    MaskIdentifier,
    MaskPhone,
    getDownloadRemoteFile,
    downloadPDF,
};
