const result = {
    plansConstantesName: [
        {
            name: 'Título do Feature',
        },
        {
            name: 'Título do Feature',
        },
        {
            name: 'Título do Feature',
        },
    ],
};

export default result;
