/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { lightFormat } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { RiMenuAddFill } from 'react-icons/ri';

// components (navigation)
import ModalManagePage from './components/ModalManage/ModalManagePage';
import HeaderComponent from '../../components/HeaderComponent/HeaderPage';

// images
import imageToEdit from '../../assets/images/imageToEdit.png';
import imageDeleteUser from '../../assets/images/imageDeleteUser.png';
import iconEngineeringBlue from '../../assets/images/iconEngineeringBlue.png';

import {
    Header,
    Container,
    ContainerShare,
    ContainerInputSearch,
    InputShare,
    ContainerButtonAdd,
    ButoonToAdd,
    ImageIconSelect,
    TextSelect,
    SendSearch,
    DivTable,
    DivThead,
    AreaTr,
    TextTh,
    DivTbody,
    AreaBodyTr,
    TextTd,
    ContainerAreaTotalList,
    TextThView,
    /* TODO será usado em uma versão futura */
    // ContainerRadio,
    // TextSelector,
} from './engineeringStyles';
import engineering from '../../services/engineering';
import sendSearch from '../../assets/images/sendSearch.png';
import LoadingComponent from '../../components/ModalLoading/LoadingComponent';

// contexts
import colors from '../../styles/colors';

const engineeringPage: React.FC = () => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const limit = 10;

    // styles
    const styleSelect = { width: 40 };

    // states
    const [dataFinal, setDataFinal] = useState<any>();
    const [open, setOpen] = useState(false);
    const [userEdit, setUserEdit] = useState<any>();
    const [nameExamination, setNameExamination] = useState('');
    const [search, setSearch] = useState('');
    const [countPagination, setCountPagination] = useState(10);
    const [pageActual, setPageActual] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState('');
    const [idUserDelete, setIdUserDelete] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleOpen = (infoUser: any) => {
        setOpen(true);
        if (infoUser) {
            setNameExamination(infoUser.name);
            setUserEdit(infoUser);
        } else {
            setUserEdit(null);
        }
    };

    function RenderListItems(item: any) {
        const onChangeSelect = async (event: any) => {
            const valueSelected = event.target.value;
            if (valueSelected === 'DeleteEngineering') {
                const actionInitialDelete = await engineering.DeleteOneEngineering(item?.id, '', GetToken);
                setOpenDialog(true);
                setMessageDialog(actionInitialDelete.message);
                setIdUserDelete(item?.id);
            }
            if (valueSelected === 'EditEngineering') {
                handleOpen(item);
            }
        };

        return (
            <AreaBodyTr>
                <TextTd>
                    <TextThView>Id:</TextThView>
                    {item?.id}
                </TextTd>
                <TextTd>
                    <TextThView>titulo:</TextThView>
                    {item?.name}
                </TextTd>
                <TextTd>
                    <TextThView>Criado em:</TextThView>
                    {lightFormat(new Date(item?.created_at || '2021-07-26T19:40:54.919Z'), 'dd/MM/yyyy') || ''}
                </TextTd>
                <TextTd>
                    <TextThView>Atualizado em:</TextThView>
                    {lightFormat(new Date(item?.updated_at || '2021-07-26T19:40:54.919Z'), 'dd/MM/yyyy') || ''}
                </TextTd>
                <TextTd>
                    <Select value="" style={styleSelect} onChange={onChangeSelect}>
                        <MenuItem value="EditEngineering">
                            <ImageIconSelect src={imageToEdit} />
                            <TextSelect>Editar informações</TextSelect>
                        </MenuItem>
                        <MenuItem value="DeleteEngineering">
                            <ImageIconSelect src={imageDeleteUser} />
                            <TextSelect>Excluir formação</TextSelect>
                        </MenuItem>
                    </Select>
                </TextTd>
            </AreaBodyTr>
        );
    }

    async function RenderQuantityPagination(searchSelected: string) {
        const allEngineeringsNotPage = await engineering.GetAllEngineering(searchSelected || '', 0, 0, GetToken);
        const formatPagination = String(allEngineeringsNotPage.engineering.length / 10).split('.');
        setCountPagination(formatPagination.length === 1 ? Number(formatPagination[0]) : Number(formatPagination[0]) + 1);
        setPageActual(1);
    }

    async function ActionInitial() {
        const allEngineering = await engineering.GetAllEngineering('', 1, limit, GetToken);
        setDataFinal(allEngineering.engineering);
        RenderQuantityPagination(search);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setMessageDialog('');
        setIdUserDelete(0);
    };

    const handleClose = (activeRefresh: boolean) => {
        setOpen(false);
        setUserEdit(null);
        if (activeRefresh === true) {
            ActionInitial();
        }
    };

    const deleteHandleCloseDialog = async () => {
        await engineering.DeleteOneEngineering(idUserDelete, 'true', GetToken);
        ActionInitial();
        handleCloseDialog();
        toast.success('Formação deletada com sucesso!');
    };

    async function changeInputShare(item: any) {
        const text = item.target.value;
        setSearch(text);
    }

    async function onChangePagination(event: any, pageSelected: number) {
        const allEngineering = await engineering.GetAllEngineering(search, pageSelected, limit, GetToken);
        setDataFinal(allEngineering.engineering);
        setPageActual(pageSelected);
    }

    async function ActionSearch() {
        setLoading(true);
        const allEngineering = await engineering.GetAllEngineering(search, 1, limit, GetToken);
        setDataFinal(allEngineering.engineering);
        RenderQuantityPagination(search);
        setLoading(false);
    }

    // useEffect
    useEffect(() => {
        ActionInitial();
    }, []);

    return (
        <Container>
            <Header>
                { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : null}
                <ToastContainer />
                <HeaderComponent iconPage={iconEngineeringBlue} text="GERENCIAR FORMAÇÕES" />
            </Header>
            <ContainerShare>
                {/* TODO será usado em uma versão futura */}
                {/* <ContainerRadio type="radio" />
                <TextSelector>Selecionar todas as questões</TextSelector> */}
                <ContainerInputSearch>
                    <InputShare
                        placeholder="Procure por título das formações..."
                        onChange={changeInputShare}
                    />
                    <SendSearch src={sendSearch} onClick={ActionSearch} />
                </ContainerInputSearch>
                <ContainerButtonAdd>
                    <RiMenuAddFill color={colors.basic.white} />
                    <ButoonToAdd onClick={handleOpen}>Adicionar Nova Formação</ButoonToAdd>
                </ContainerButtonAdd>
                <Pagination
                    count={countPagination}
                    variant="outlined"
                    color="primary"
                    onChange={onChangePagination}
                    page={pageActual}
                />
            </ContainerShare>
            <ContainerAreaTotalList>
                <DivTable>
                    <DivThead>
                        <AreaTr>
                            <TextTh>ID</TextTh>
                            <TextTh>TÍTULO</TextTh>
                            <TextTh>CRIADO EM</TextTh>
                            <TextTh>ATUALIZADO EM</TextTh>
                            <TextTh />
                        </AreaTr>
                    </DivThead>
                    <DivTbody>
                        <FlatList
                            list={dataFinal}
                            renderItem={RenderListItems}
                        />
                    </DivTbody>
                </DivTable>
            </ContainerAreaTotalList>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Aviso</DialogTitle>
                <DialogContent>
                    <DialogContentText>{messageDialog}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={deleteHandleCloseDialog} color="primary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
            {ModalManagePage(open, handleClose, userEdit, nameExamination, setNameExamination)}
        </Container>
    );
};

export default engineeringPage;
