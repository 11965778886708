/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext } from 'react';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import ModalProfilePage from '../ModalProfile/ModalProfilePage';

import {
    ContainerTeachers,
    IconTeacher,
    TextTeacher,
    ContainerItens,
    Line,
    IconUser,
    TextUser,
    IconEdit,
    ImgUser,
    ImgDefault,
    ContainerAreaHead,
} from './HeaderStyles';

// images
import iconTeacher from '../../assets/images/iconTeacher.png';
import iconUser from '../../assets/images/iconUser.png';
import editDisabled from '../../assets/images/editDisabled.png';

// contexts
import { AuthContext } from '../../context/AuthContext';

// interface
interface Props {
    text: string,
    iconPage?: string,
}

const HeaderComponent: React.FC<Props> = (props: Props) => {
    // constants
    const {
        text,
        iconPage,
    } = props;

    // contexts
    const { user } = useContext(AuthContext);

    // consts
    const [openProfile, setOpenProfile] = useState<any>();

    // open modal Profile
    const openModalProfile = () => {
        setOpenProfile(true);
    };

    // fechar modal profile
    const closeModalProfile = (fastRefresh: boolean) => {
        setOpenProfile(false);
        if (fastRefresh === true) {
            document.location.reload();
        }
    };

    return (
        <>
            <ContainerAreaHead>
                <ContainerTeachers>
                    <DrawerNavigation />
                    <IconTeacher src={iconPage} />
                    <TextTeacher>{text}</TextTeacher>
                </ContainerTeachers>
                <ContainerItens onClick={openModalProfile}>
                    <Line />
                    <IconUser src={iconUser} />
                    <TextUser>{user.name || ''}</TextUser>
                    <IconEdit src={editDisabled} />
                    <Line />
                    {user.photo ? (
                        <ImgUser src={user.photo} />
                    ) : (
                        <ImgDefault />
                    )}
                </ContainerItens>
                {ModalProfilePage(openProfile, closeModalProfile)}
            </ContainerAreaHead>
        </>
    );
};

export default HeaderComponent;
