// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllFaq(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/faq?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/faq?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewFaq(
    title?: string,
    description?: string,
    idManager?: number,
    token?: string,
) {
    try {
        const data = {
            title,
            description,
            idManager,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/faq`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneFaq(
    title?: string,
    description?: string,
    idManager?: number,
    idFaq?: number,
    token?: string,
) {
    try {
        const data = {
            title,
            description,
            idManager,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/faq?idFaq=${idFaq}&idManager=${idManager}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneFaq(
    idFaq?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/faq?idFaq=${idFaq}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetAllFaq,
    CreateNewFaq,
    EditOneFaq,
    DeleteOneFaq,
};
