const colors = {
    basic: {
        black: '#000000',
        blackMedium: '#303030',
        white: '#ffffff',
        blue: '#BCC5E3',
        darkblue: '#475C98',
        darkerblue: '#344577',
        lightGrey: '#b9b9b9',
        lightgray: '#6E6E6E',
        gray: '#777777',
        grayLight: '#A5A5A5',
        orange: '#FF9326',
        yellow: '#FFC40E',
        blueDefault: '#292FA2',
        redUltra: '#cc0000',
        purple: '#800080',
    },
    theme: {
        blackDiscount: '#222222',
        backgroundInputsPayment: '#f2f2f2',
        colorPlaceHolderInputsPayment: 'rgba(136, 140, 141, 0.5)',
        backgroundWarnningPayment: 'rgba(249, 228, 183, 0.5)',
    },
};

export default colors;
