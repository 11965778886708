// libraries
import styled from 'styled-components';

// Colors
import colors from '../../../styles/colors';

// styles
export const ModalContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
`;

export const Header = styled.div``;

export const Footer = styled.div`
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerHome = styled.div`
    display: flex;
    width: 100%;
    margin-left: 20%;
    margin-top: 4px;
`;

export const ButtonExit = styled.div`
    width: 150px;
    height: 40px;
    background-color: ${colors.basic.white};
    border: 1px solid ${colors.basic.darkblue};
    border-radius: 20px;
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
`;

export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    max-height: calc(110vh - 220px);
    width: 80%;
    max-width: 1000px;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const IconBlue = styled.img`
    align-items: center;
    justify-content: center;
    width: 30px;

`;

export const ContainerHeader = styled.div`
    width: 20%;
    padding: 18px;
`;

export const TextNewCollaborator = styled.h4`
    color: ${colors.basic.darkblue};
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

export const DivLine = styled.div`
    background-color: #74F4D6;
    width: 100%;
    height: 8px;
    margin-top: 10px;
`;

export const TextInput = styled.h4`
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 18px;
    color: ${colors.basic.darkblue};
    white-space: nowrap;
`;

export const InputText = styled.input`
    display: inline;
    width: 40%;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border: 1px solid ${colors.basic.blue};
    border-radius: 5px;
    color: ${colors.basic.darkblue};
    font-size: 14px;
    padding-left: 10px;

    /* styles placeholder */
    ::-webkit-input-placeholder {
        color: ${colors.basic.blue};
        font-size: 14px;
    }
`;

export const ButtonSave = styled.div`
    width: 150px;
    height: 40px;
    background-color: ${colors.basic.orange};
    border-radius: 20px;
    color: ${colors.basic.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContainerInput = styled.div`
    display: inline-block;
    justify-content: center;
`;
export const DivInputText = styled.div`
    display: flex;
`;

export const Body = styled.div``;

export const ContainerNewCollaborator = styled.div`
    display: flex;
    padding-left: 8px;
`;
