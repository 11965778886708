/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import {
    Button, Modal, TextField, Fade,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectOptions from 'react-select';
import makeAnimated from 'react-select/animated';

// js
import {
    Body,
    Header,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    ContainerManage,
    ContainerSelect,
    ContainerOption,
    IconChecked,
    ContainerMainCategories,
    ContainerCategoriesSelected,
    TxtCategoriesSelected,
    ContainerCicleChecked,
    IconArrowCategoriesGoBack,
    TextFinish,
    ContainerRadius,
    IconArrowCategories,
    ContainerTest,
    InputNullSelectedArchive,
    ContainerArchive,
    TextArchive,
    AdjustCombo,
    ContainerCombo,
    TitleCombo,
    TrashDelete,
    Margin,
} from './ModalManageStyles';
import filtersRequest from '../../../../services/filters';
import simulates from '../../../../services/simulates';
import LoadingComponent from '../../../../components/ModalLoading/LoadingComponent';

// images
import arrowTip from '../../../../assets/images/arrowTip.png';
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import imageDeleteUser from '../../../../assets/images/imageDeleteUser.png';
import checkCircleBlueSea from '../../../../assets/images/checkCircleBlueSea.png';
import uploads from '../../../../services/uploads';

interface AmountInterface {
    name: string,
    id: number,
}

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    title: string,
    setTitle: any,
    timer: string,
    setTimer: any,
    selectExamination: string,
    setSelectExamination: any,
    selectEngineering: string,
    setSelectEngineering: any,
    dateInitial: any,
    setDateInitial: any,
    dateFinal: any,
    setDateFinal: any,
    allCategories: any,
    allFilters: any,
    setAllFilters: any,
    amountSelected: AmountInterface[],
    setAmountSelected: any,
    subjectSelected: AmountInterface[],
    setSubjectSelected: any,
    allExamination: any,
    allEngineering: any,
    archive: any,
    setArchive: any,
    gabaritoArchive: any,
    setGabaritoArchive: any,
    selectPhase: any,
    setSelectPhase: any,
    allSelectExamination: any,
    setAllSelectExamination: any,
    allSelectEngineering: any,
    setAllSelectEngineering: any,
) => {
    // constants
    const GetToken = localStorage.getItem('@Engequest:token') || '';
    const adjustButtonArchive = { width: '100%' };
    const animatedComponents = makeAnimated();

    // useState
    const [amountSelectedFalse, setAmountSelectedFalse] = useState<AmountInterface[]>([]);
    const [subjectSelectedFalse, setSubjectSelectedFalse] = useState<AmountInterface[]>([]);
    const [indexCategories, setIndexCategories] = useState(1);
    const [idCategories, setIdCategories] = useState(0);
    const [loading, setLoading] = useState(false);

    // methods
    function RenderNumberSelected(idCategory: any) {
        const getOnlyIds = amountSelected?.map((v: any) => v.id);
        const [getOnlyAllFilters] = allCategories?.filter((v: any) => v.id === idCategory);
        const filtersOnlyIds = getOnlyAllFilters.allFilters.filter((v: any) => getOnlyIds?.includes(v));
        return filtersOnlyIds.length;
    }

    const handleClick = (v: any, valueSubject?: any) => {
        if (amountSelectedFalse?.filter((item) => item.id === v.id).length) {
            const removeItem = amountSelectedFalse?.filter((item) => item.id !== v.id);
            setAmountSelectedFalse(removeItem);
            if (valueSubject) {
                const allIdsSubject = valueSubject.map((item: any) => item.id);
                const removeItemSubject = subjectSelectedFalse.filter((item) => !allIdsSubject.includes(item.id));
                setSubjectSelectedFalse(removeItemSubject);
            }
        } else {
            setAmountSelectedFalse([...amountSelectedFalse || [], { name: v.name, id: v.id }]);
        }
    };

    const handleClickSubject = (v: any, valueFilter: any) => {
        if (subjectSelectedFalse.filter((item) => item.id === v.id).length) {
            const removeItem = subjectSelectedFalse.filter((item) => item.id !== v.id);
            setSubjectSelectedFalse(removeItem);
            if (!removeItem.length) {
                handleClick(valueFilter);
            }
        } else {
            setSubjectSelectedFalse([...subjectSelectedFalse || [], { name: v.name, id: v.id }]);
        }
    };

    function Select(subtext: string, value: string, setValue: any) {
        function RenderOptionsQuestion() {
            if (subtext === 'Selecione um concurso') {
                return allExamination.map((item: any) => (
                    <ContainerOption value={`${item.id}/${item.name}`}>{item.name}</ContainerOption>
                ));
            }

            if (subtext === 'Selecione uma formação') {
                return allEngineering.map((item: any) => (
                    <ContainerOption value={`${item.id}/${item.name}`}>{item.name}</ContainerOption>
                ));
            }

            if (subtext === 'Selecione uma fase') {
                return ['1', '2'].map((item: any) => (
                    <ContainerOption value={item}>{`${item}ª fase`}</ContainerOption>
                ));
            }

            return null;
        }

        function ChangeSelect(txt: any) {
            const valueSelected = txt.target.value;
            if (subtext === 'Selecione um concurso') {
                const splitValueSelected = valueSelected.split('/');
                const formatValueSelected = {
                    id: splitValueSelected[0],
                    name: splitValueSelected[1],
                };
                const filterAllSelectExamination = allSelectExamination.filter((e: any) => e.id === formatValueSelected.id);
                if (!filterAllSelectExamination.length) {
                    setAllSelectExamination([...allSelectExamination, formatValueSelected]);
                }
            }
            if (subtext === 'Selecione uma formação') {
                const splitValueSelected = valueSelected.split('/');
                const formatValueSelected = {
                    id: splitValueSelected[0],
                    name: splitValueSelected[1],
                };
                const filterAllSelectEngineering = allSelectEngineering.filter((e: any) => e.id === formatValueSelected.id);
                if (!filterAllSelectEngineering.length) {
                    setAllSelectEngineering([...allSelectEngineering, formatValueSelected]);
                }
            }
            if (subtext === 'Selecione uma fase') {
                if (valueSelected !== 'Selecione uma fase') {
                    setValue(valueSelected);
                } else {
                    setValue('');
                }
            }
        }

        return (
            <ContainerSelect onChange={ChangeSelect} defaultValue={value || subtext}>
                <ContainerOption>{subtext}</ContainerOption>
                {RenderOptionsQuestion()}
            </ContainerSelect>
        );
    }

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        if (text === 'EXAME' || text === 'FORMAÇÃO' || text === 'FASE') {
            return Select(subtext, value, setValue);
        }

        if (text === 'TEMPO DE PROVA') {
            return (
                <>
                    <TextField
                        placeholder="Hora"
                        value={value.split('/')[0]}
                        margin="dense"
                        type="text"
                        label="Hora"
                        variant="outlined"
                        style={{ marginRight: 5 }}
                        onChange={(txt) => {
                            if (Number(txt.target.value) < 24) {
                                setValue(`${txt.target.value}/${value.split('/')[1]}`);
                            }
                        }}
                    />
                    <TextField
                        value={value.split('/')[1]}
                        type="text"
                        margin="dense"
                        label="Minuto"
                        variant="outlined"
                        onChange={(txt) => {
                            if (Number(txt.target.value) < 60) {
                                setValue(`${value.split('/')[0]}/${txt.target.value}`);
                            }
                        }}
                    />
                </>
            );
        }

        if (text === 'CATEGORIAS') {
            return (
                <ContainerMainCategories>
                    {indexCategories !== 1 ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <IconArrowCategoriesGoBack
                                src={arrowTip}
                                onClick={() => {
                                    setIndexCategories(1);
                                    setAmountSelectedFalse([]);
                                    setSubjectSelectedFalse([]);
                                }}
                            />
                            <TextFinish
                                onClick={() => {
                                    setIndexCategories(1);
                                    setAmountSelected(amountSelectedFalse);
                                    setSubjectSelected(subjectSelectedFalse);
                                    setAmountSelectedFalse([]);
                                    setSubjectSelectedFalse([]);
                                }}
                            >
                                Concluído
                            </TextFinish>
                        </div>
                    ) : null}
                    {value.map((valueCategories: any) => (
                        <>
                            {indexCategories === 1 ? (
                                <Fade in={indexCategories === 1}>
                                    <ContainerCategoriesSelected
                                        onClick={async () => {
                                            const getAllFilters = await filtersRequest.GetSelectedFilters(valueCategories.id, localStorage.getItem('@Engequest:token') || '');
                                            setAllFilters(getAllFilters.result);
                                            setIndexCategories(2);
                                            setIdCategories(valueCategories.id);
                                            setAmountSelectedFalse(amountSelected);
                                            setSubjectSelectedFalse(subjectSelected);
                                        }}
                                    >
                                        <TxtCategoriesSelected>{valueCategories.name}</TxtCategoriesSelected>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ContainerRadius backgroundColor={RenderNumberSelected(valueCategories.id)}>
                                                <text style={{ color: 'white', fontSize: 12 }}>{RenderNumberSelected(valueCategories.id)}</text>
                                            </ContainerRadius>
                                            <IconArrowCategories src={arrowTip} />
                                        </div>
                                    </ContainerCategoriesSelected>
                                </Fade>
                            ) : null}
                            {indexCategories !== 1 && valueCategories.id === idCategories && allFilters.length ? (
                                <>
                                    {allFilters.map((valueFilters: any) => (
                                        <>
                                            <Fade in={indexCategories !== 1}>
                                                <ContainerCategoriesSelected
                                                    onClick={() => {
                                                        if (valueFilters.subjects.length) {
                                                            // setIndexCategories(3);
                                                            setSubjectSelectedFalse([...subjectSelectedFalse || [], ...valueFilters.subjects]);
                                                            handleClick(valueFilters, valueFilters.subjects);
                                                        } else {
                                                            handleClick(valueFilters);
                                                        }
                                                    }}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length ? (
                                                            <IconChecked src={checkCircleBlueSea} />
                                                        ) : (
                                                            <ContainerCicleChecked />
                                                        )}
                                                        <TxtCategoriesSelected>{valueFilters.name}</TxtCategoriesSelected>
                                                    </div>
                                                </ContainerCategoriesSelected>
                                            </Fade>
                                            {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length && valueFilters.subjects.length ? (
                                                <>
                                                    {valueFilters.subjects.map((valueSubjects: any) => (
                                                        <Fade in={indexCategories !== 1}>
                                                            <ContainerCategoriesSelected
                                                                onClick={() => {
                                                                    handleClickSubject(valueSubjects, valueFilters);
                                                                }}
                                                                style={{ marginLeft: 30 }}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    {subjectSelectedFalse.filter((item) => item.id === valueSubjects.id).length ? (
                                                                        <IconChecked src={checkCircleBlueSea} />
                                                                    ) : (
                                                                        <ContainerCicleChecked />
                                                                    )}
                                                                    <TxtCategoriesSelected>{valueSubjects.name}</TxtCategoriesSelected>
                                                                </div>
                                                            </ContainerCategoriesSelected>
                                                        </Fade>
                                                    ))}
                                                </>
                                            ) : null}
                                        </>
                                    ))}
                                </>
                            ) : null}
                        </>
                    ))}
                </ContainerMainCategories>
            );
        }

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type={text === 'DATA INICIAL' || text === 'DATA FINAL' ? 'date' : 'text'}
                onChange={(txt) => {
                    const onlyNumber = txt.target.value.replace(/[^0-9]/g, '');
                    if (text === 'ANO') {
                        if (txt.target.value.length <= 4) {
                            setValue(onlyNumber);
                        }
                    } else {
                        setValue(txt.target.value);
                    }
                }}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue?: any) {
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput adjustLabel={text === 'CATEGORIAS'}>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
            </ContainerHome>
        );
    }

    async function SaveSimulate() {
        if (selectPhase) {
            setLoading(true);
            const formatTimer = String(timer).split('/').map((v, i) => {
                if (!i) {
                    return Number(v) * 60 * 60000;
                }

                return Number(v) * 60000;
            });
            const formatDataInitial = new Date(dateInitial).getTime();
            // const formatDataFinal = new Date(dateFinal).getTime();
            if (!userEdit?.id) {
                await simulates.CreateNewSimulate(
                    title,
                    Number(formatDataInitial),
                    selectPhase,
                    // Number(formatDataFinal),
                    // amountSelected.map((v) => v.id),
                    // subjectSelected.map((v) => v.id),
                    Number(formatTimer[0]) + Number(formatTimer[1]),
                    allSelectExamination.map((v: any) => v.id).join(','),
                    allSelectEngineering.map((v: any) => v.id).join(','),
                    archive,
                    gabaritoArchive,
                    GetToken,
                )
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        toast.success('Simulado criado com sucesso!');
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    });
            } else {
                await simulates.EditOneSimulate(
                    title,
                    Number(formatDataInitial),
                    selectPhase,
                    // Number(formatDataFinal),
                    // amountSelected.map((v) => v.id),
                    // subjectSelected.map((v) => v.id),
                    Number(formatTimer[0]) + Number(formatTimer[1]),
                    allSelectExamination.map((v: any) => v.id).join(','),
                    allSelectEngineering.map((v: any) => v.id).join(','),
                    archive,
                    gabaritoArchive,
                    userEdit?.id,
                    GetToken,
                )
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        toast.success('Simulado editado com sucesso!');
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    });
            }
        } else {
            alert('Selecione uma fase!');
        }
    }

    async function SelectedArchive(event: any) {
        let newDataFiles = {
            eventFile: null,
            eventName: null,
            file: '',
        };
        if (event.target.files.length) {
            newDataFiles = {
                eventFile: event.target.files[0],
                eventName: event.target.files[0].name,
                file: URL.createObjectURL(event.target.files[0]),
            };
        }
        if (newDataFiles.eventFile) {
            const uploadPhoto = await uploads.UploadFile(newDataFiles.eventFile);
            setArchive(uploadPhoto.result);
        }
    }

    async function SelectedGabaritoArchive(event: any) {
        let newDataFiles = {
            eventFile: null,
            eventName: null,
            file: '',
        };
        if (event.target.files.length) {
            newDataFiles = {
                eventFile: event.target.files[0],
                eventName: event.target.files[0].name,
                file: URL.createObjectURL(event.target.files[0]),
            };
        }
        if (newDataFiles.eventFile) {
            const uploadPhoto = await uploads.UploadFile(newDataFiles.eventFile);
            setGabaritoArchive(uploadPhoto.result);
        }
    }

    const RenderEventName = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (archive?.eventName === 'Importar PDF') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return archive.eventName;
        }
        return `${String(archive)?.substring(0, 25)}...`;
    };

    const RenderGabaritoEventName = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (gabaritoArchive?.eventName === 'Importar PDF') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return gabaritoArchive.eventName;
        }
        return `${String(gabaritoArchive)?.substring(0, 25)}...`;
    };

    const renderQueries = (queries: Array<{ id: string, name: string }>, setQueries: (e: Array<{ id: string, name: string }>) => void) => {
        if (!queries?.length) {
            return null;
        }
        return queries.map((v) => {
            const DeleteCombo = () => {
                const newCombo = queries.filter((el) => el.id !== v.id);
                setQueries(newCombo);
            };
            return (
                <AdjustCombo key={v.id}>
                    <ContainerCombo>
                        <TitleCombo>{v.name}</TitleCombo>
                    </ContainerCombo>
                    <TrashDelete onClick={DeleteCombo} src={imageDeleteUser} />
                </AdjustCombo>
            );
        });
    };

    // main
    return (
        <>
            { loading ? <LoadingComponent open={loading} setOpen={setLoading} label="Carregando..." /> : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <ModalContainer>
                        <Container>
                            <Header>
                                <ContainerHeader>
                                    <ContainerNewCollaborator>
                                        <IconBlue src={iconAddQuestions} />
                                        <TextNewCollaborator>Adicionar Novo Simulado</TextNewCollaborator>
                                    </ContainerNewCollaborator>
                                    <DivLine />
                                </ContainerHeader>
                            </Header>
                            <Body>
                                {DivInput('TÍTULO', 'Título', title, setTitle)}
                                {DivInput('TEMPO DE PROVA', 'Tempo de Prova', timer, setTimer)}
                                {DivInput('DATA INICIAL', 'Data inicial do simulado', dateInitial, setDateInitial)}
                                {/* {DivInput('DATA FINAL', 'Data final do simulado', dateFinal, setDateFinal)} */}
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput adjustLabel={false}>MÍDIA</TextInput>
                                    </DivInputText>
                                    <ContainerTest>
                                        <Button style={adjustButtonArchive}>
                                            <InputNullSelectedArchive
                                                accept="application/pdf"
                                                onChange={SelectedArchive}
                                                type="file"
                                            />
                                            <ContainerArchive>
                                                <TextArchive>{RenderEventName()}</TextArchive>
                                            </ContainerArchive>
                                        </Button>
                                    </ContainerTest>
                                </ContainerHome>
                                <ContainerHome>
                                    <DivInputText>
                                        <TextInput adjustLabel={false}>GABARITO</TextInput>
                                    </DivInputText>
                                    <ContainerTest>
                                        <Button style={adjustButtonArchive}>
                                            <InputNullSelectedArchive
                                                accept="application/pdf"
                                                onChange={SelectedGabaritoArchive}
                                                type="file"
                                            />
                                            <ContainerArchive>
                                                <TextArchive>{RenderGabaritoEventName()}</TextArchive>
                                            </ContainerArchive>
                                        </Button>
                                    </ContainerTest>
                                </ContainerHome>
                            </Body>
                            <ContainerManage>
                                {/* {DivInput('EXAME', 'Selecione um concurso', selectExamination, setSelectExamination)} */}
                                {/* {renderQueries(allSelectExamination, setAllSelectExamination)} */}
                                {/* {DivInput('FORMAÇÃO', 'Selecione uma formação', selectEngineering, setSelectEngineering)}
                                {renderQueries(allSelectEngineering, setAllSelectEngineering)} */}
                                <SelectOptions
                                    closeMenuOnSelect={false}
                                    isMulti
                                    components={animatedComponents}
                                    options={allExamination.map((e: any) => ({ value: e.id, label: e.name }))}
                                    placeholder="Selecione um concurso"
                                    onChange={(e) => setAllSelectExamination(e.map((i: any) => ({ id: i.value, name: i.label })))}
                                    styles={{
                                        option: (styles) => ({
                                            ...styles,
                                            backgroundColor: '#ccc',
                                            color: '#000',
                                        }),
                                    }}
                                />
                                <Margin />
                                <SelectOptions
                                    closeMenuOnSelect={false}
                                    isMulti
                                    components={animatedComponents}
                                    options={allEngineering.map((e: any) => ({ value: e.id, label: e.name }))}
                                    placeholder="Selecione uma formação"
                                    onChange={(e) => setAllSelectEngineering(e.map((i: any) => ({ id: i.value, name: i.label })))}
                                    styles={{
                                        option: (styles) => ({
                                            ...styles,
                                            backgroundColor: '#ccc',
                                            color: '#000',
                                        }),
                                    }}
                                />
                                {DivInput('FASE', 'Selecione uma fase', selectPhase, setSelectPhase)}
                                {/* {DivInput('CATEGORIAS', 'Categorias', allCategories)} */}
                            </ContainerManage>
                            <Footer>
                                <ButtonExit onClick={handleClose}>Cancelar</ButtonExit>
                                <ButtonSave onClick={SaveSimulate}>{!userEdit?.id ? 'Criar Simulado' : 'Salvar Simulado'}</ButtonSave>
                            </Footer>
                        </Container>
                    </ModalContainer>
                </Modal>
            )}
        </>

    );
};

export default modalManagePage;
