// js
import constants from '../configs/constants';

// constants
const baseURL = constants.baseUrl;

// api
async function GetAllBookSuggested(
    searchTxt?: string,
    page?: number,
    limit?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'GET';
        const configs = {
            headers,
            method,
        };
        const RenderBaseURL = page ? `${baseURL}/booksSuggested?searchTxt=${searchTxt}&page=${page}&limit=${limit || ''}` : `${baseURL}/booksSuggested?searchTxt=${searchTxt}&limit=${limit || ''}`;
        const response = await fetch(RenderBaseURL, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function CreateNewBookSuggested(
    title?: string,
    queries?: any,
    idExamination?: number,
    idEngineering?: number,
    token?: string,
) {
    try {
        const data = {
            title,
            queries,
            idExamination,
            idEngineering,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/booksSuggested`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function EditOneBookSuggested(
    title?: string,
    queries?: any,
    idExamination?: number,
    idEngineering?: number,
    idBookSuggested?: number,
    token?: string,
) {
    try {
        const data = {
            title,
            queries,
            idExamination,
            idEngineering,
        };
        const headers = {
            'x-access-token': token || '',
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            headers,
            method,
            body,
        };
        const response = await fetch(`${baseURL}/booksSuggested?idBookSuggested=${idBookSuggested}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

async function DeleteOneBookSuggested(
    idBookSuggested?: number,
    token?: string,
) {
    try {
        const headers = {
            'x-access-token': token || '',
        };
        const method = 'DELETE';
        const configs = {
            headers,
            method,
        };
        const response = await fetch(`${baseURL}/booksSuggested?idBookSuggested=${idBookSuggested}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetAllBookSuggested,
    CreateNewBookSuggested,
    EditOneBookSuggested,
    DeleteOneBookSuggested,
};
